import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import update from 'immutability-helper';
import each from 'lodash/each';
import styled from 'styled-components';
//dev component
import {
	AUTH_GOOGLE_OAUTH2,
	AUTH_MICROSOFT_OAUTH2,
	PC_RECEIPT_GREETING,
	TXT_AUTHORIZATION,
	ewsAuthorizationOption,
	imapAuthorizationOption,
	testMoreThanOneIMAP,
	msGraphAuthorizationOption,
	AUTH_MICROSOFT_OAUTH2_API
} from '../../../common/constants';
import { isNumberOrNumberStringEqual } from  '../../../common/helpers';
import { inputDoubleSpace } from '../../../styles/_variables';
import { Hideable } from '../../../reactcomponents/CFlag';
import CHelper from '../../../components/CHelper';
import DropDown from '../../../components/DropDown';
import CentionPopup from '../../../components/CentionPopup';
import TaggedPicker from '../../../components/TaggedPicker';
import ErrorBox from '../../../components/ErrorBox';
import Ckeditor from '../../../components/Ckeditor';
import Calendar from '../../../components/Calender';
//feature
import Feature from '../../../components/feature';
import {
	composeWithDisplayName,
	createWithMountCondition,
	withUnmountWhenHidden
} from '../../../reactcomponents/hocs';
import {
	AuthorizedToken,
	ControlAuthorizationSelect,
	justAuthorizedTokenProvider
} from '../../../reactcomponents/Authorization';
import { IMAPGmailOAuth2 } from '../../../reactcomponents/Google';
import MicrosoftEWS, {
	MicrosoftGraph,
	withAdminConsent
} from '../../../reactcomponents/Microsoft';
import CFlag from  '../../../reactcomponents/CFlag';

const Input = ({ name, onChange, value }) => (
	<input
		id={name}
		type="input"
		name={name}
		value={value}
		className="form-control input-sm"
		onChange={onChange}
	/>
);

const MicrosoftEwsWithAdminConsent = withAdminConsent(MicrosoftEWS)
const MicrosoftGraphWithAdminConsent = withAdminConsent(MicrosoftGraph)

const oAuth2ProtocolMap = {
	ews: true,
	imap: true,
	msgraphapi: true
}

const authOptions = {
	ews: ewsAuthorizationOption,
	imap: imapAuthorizationOption,
	msgraphapi: msGraphAuthorizationOption,
}

const authComponents = {
	ews: {
		[AUTH_MICROSOFT_OAUTH2]: MicrosoftEwsWithAdminConsent
	},
	imap: {
		[AUTH_GOOGLE_OAUTH2]: IMAPGmailOAuth2
	},
	msgraphapi: {
		[AUTH_MICROSOFT_OAUTH2_API]: MicrosoftGraphWithAdminConsent
	}
}

if (testMoreThanOneIMAP) {
	// just for testing EWS token definitely not usable for IMAP.
	authComponents.imap[AUTH_MICROSOFT_OAUTH2] = MicrosoftEwsWithAdminConsent
}

const useAuthState = protocol => {
	const [_protocol, setProtocol] = useState(protocol)
	const [auth, setAuth] = useState(authOptions[protocol].allIds[0])
	useEffect(() => {
		const { allIds } = authOptions[protocol]
		setAuth(auth => {
			let found
			each(allIds, _auth => {
				if (auth === _auth) {
					found = true
					return false
				}
			})
			if (found) {
				return auth
			}
			return allIds[0]
		})
		setProtocol(protocol)
	}, [protocol])
	return [auth, _protocol, setAuth]
}

const AuthorizationBase = ({
	onChangeName
	, onChangeSecure
	, onChangeServer
	, onChangeToken
	, onChangeUsername
	, protocol
	, provider
	, constants
}) => {
	const [auth, _protocol, setAuth] = useAuthState(protocol)
	return (
		<div className='form-group'>
			<label className='col-lg-3 col-sm-3 col-xs-3'>{TXT_AUTHORIZATION}</label>
			<div className='col-lg-8 col-sm-4 col-xs-7'>
				<ControlAuthorizationSelect
					components={authComponents[_protocol]}
					constants={constants ? constants : c3oauth}
					oldVersion
					onChangeName={onChangeName}
					onChangeSecure={onChangeSecure}
					onChangeServer={onChangeServer}
					onChangeToken={onChangeToken}
					onChangeUsername={onChangeUsername}
					onPopAlert={useCallback(err => { console.log(err) }, [])}
					onSelect={setAuth}
					option={authOptions[_protocol]}
					provider={provider}
					row
					selected={auth}
				/>
			</div>
			<div className='col-lg-1 col-sm-1 col-xs-2' />
			<div className='clear' />
		</div>
	)
}

const protocolSupportsOAuth2 = protocol => oAuth2ProtocolMap[protocol]

export const Authorization = composeWithDisplayName(
	'Authorization',
	memo,
	createWithMountCondition(({ protocol }) => protocolSupportsOAuth2(protocol))
)(AuthorizationBase)

const nameUpdater = name => ({name, errNameEmpty: ""});

// const hasAccessToken = (token, provider) => token || typeof provider === 'number'

const getProtocolTypeDatabaseId = ({ dataSrc }) => {
	if (dataSrc) {
		const { accountType, typeId } = dataSrc
		if (typeof typeId !== 'undefined') {
			return typeId
		} else if (accountType && accountType.length) {
			return accountType[0].id
		}
	}
	console.log('this is considered error because we can not tell confidently if databse-id 2 is always IMAP!')
	return 2
}

const StyledDiv = styled.div`
	display: flex;
	> * + * {
		margin-left: ${inputDoubleSpace};
	}
`
class EmailForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			autoReply: 0,
			autoReplyActiveFrom: "",
			autoReplyActiveTo: "",
			autoReplyAnswerNote: "",
			autoReplyForwardAddress: "",
			autoReplyMaxAnswersPerReply: 0,
			autoReplyUnanswered: 0,
			contentTransformation: "",
			contentTransformationSubject: "",
			emailAddressSearchTerm: "",
			enabled: false,
			host: "",
			id: 0,
			name: "",
			// null = no active token
			// empty string = remove previous token
			// non-empty-string = fresh authorized token
			oauthAccessToken: null,
			oauthExpiry: "",
			oauthProtocolType: null,
			oauthProvider: null,
			oauthRefreshToken: "",
			oauthTokenType: "",
			receipt: 0,
			receiptActiveFrom: "00:00",
			receiptActiveTo: "23:59",
			receiptBody: "",
			receiptEmailAddress: "",
			receiptSubject: "",
			receiptOutOfActiveHourSelected: 0,
			receiptSubjectOutOfActiveHour: "",
			receiptBodyOutOfActiveHour: "",
			reoccuringReceipt: 0,
			reoccuringReceiptsBody: "",
			reoccurringReceiptSubject: "",
			reoccurringReceiptInterval: 0,
			typeId: getProtocolTypeDatabaseId(props),
			useSecureMessage: false,
			username: "",
			wantSSL: false,
			retainFetchedEmail: false,
			allowImapDelete: false,
			fetchSeenMessages: false,
			allowDelEmptyEmail: false,
			centionDelete: false,
			readTimeout: 0,
			connectedArea: "",
			oldAreaId: 0,
			receiptShowHide: false,
			receiptTime: false,
			receiptOutShowHide: false,
			reocShowHide: false,
			haveReceipt: false,
			saving: false,
			errAreaEmpty: "",
			errNameEmpty: "",
			errServerEmpty: "",
			errUserEmpty: ""
		};
		this.protocolHasAccessToken = this.protocolHasAccessToken.bind(this);
		this.handleChangeName = this.handleChangeName.bind(this);
		this.handleChangeServer = this.handleChangeServer.bind(this);
		this.handleChangeUsername = this.handleChangeUsername.bind(this);
		this.handleChkEnable = this.handleChkEnable.bind(this);
		this.handleChkSSL = this.handleChkSSL.bind(this);
		this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
		this.handleHost = this.handleHost.bind(this);
		this.handleSecure = this.handleSecure.bind(this);
		this.handleSecureMsg = this.handleSecureMsg.bind(this);
		this.handleSearchTerm = this.handleSearchTerm.bind(this);
		this.handleRecCkeditorChange = this.handleRecCkeditorChange.bind(this);
		this.handleReocCkeditorChange = this.handleReocCkeditorChange.bind(this);
		this.handleRecOutCkeditorChange = this.handleRecOutCkeditorChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleReceipt = this.handleReceipt.bind(this);
		this.handleReoccReceipt = this.handleReoccReceipt.bind(this);
		this.handleReceiptOutOfActiveHour = this.handleReceiptOutOfActiveHour.bind(this);
		this.handleRemoveToken = this.handleRemoveToken.bind(this);
		this.updateName = this.updateName.bind(this);
		this.updateNameIfEmpty = this.updateNameIfEmpty.bind(this);
		this.updateToken = this.updateToken.bind(this);
		this.updateUsername = this.updateUsername.bind(this);
		this.updateHost = this.updateHost.bind(this);
	}
	componentDidMount() {
		const ds = this.props.dataSrc
		const { oauthProvider } = ds
		if (typeof ds.id !== 'undefined' && ds.id > 0) {
			this.setState({
				autoReply: ds.autoReply,
				autoReplyActiveFrom: ds.autoReplyActiveFrom,
				autoReplyActiveTo: ds.autoReplyActiveTo,
				autoReplyAnswerNote: ds.autoReplyAnswerNote,
				autoReplyForwardAddress: ds.autoReplyForwardAddress,
				autoReplyMaxAnswersPerReply: ds.autoReplyMaxAnswersPerReply,
				autoReplyUnanswered: ds.autoReplyUnanswered,
				contentTransformation: ds.contentTransformation,
				contentTransformationSubject: ds.contentTransformationSubject,
				emailAddressSearchTerm: this.serializeSearchTerm(ds.emailAddressSearchTerm),
				enabled: ds.enabled,
				host: ds.host,
				id: ds.id,
				name: ds.name,
				oauthProvider: typeof oauthProvider === 'number' ? oauthProvider : null,
				receipt: ds.receipt,
				receiptActiveFrom: ds.receiptActiveFrom,
				receiptActiveTo: ds.receiptActiveTo,
				receiptBody: ds.receiptBody,
				receiptEmailAddress: ds.receiptEmailAddress,
				receiptSubject: ds.receiptSubject,
				receiptOutOfActiveHourSelected: ds.receiptOutOfActiveHourSelected,
				receiptSubjectOutOfActiveHour: ds.receiptSubjectOutOfActiveHour,
				receiptBodyOutOfActiveHour: ds.receiptBodyOutOfActiveHour,
				reoccuringReceipt: ds.reoccuringReceipt,
				reoccuringReceiptsBody: ds.reoccuringReceiptsBody,
				reoccurringReceiptSubject: ds.reoccurringReceiptSubject,
				reoccurringReceiptInterval: ds.reoccurringReceiptInterval,
				typeId: ds.typeId,
				useSecureMessage: ds.useSecureMessage,
				username: ds.username,
				wantSSL: ds.wantSSL,
				retainFetchedEmail: ds.retainFetchedEmail,
				allowImapDelete: ds.allowImapDelete,
				fetchSeenMessages: ds.fetchSeenMessages,
				allowDelEmptyEmail: ds.allowDelEmptyEmail,
				centionDelete: ds.centionDelete,
				readTimeout: ds.readTimeout,
				connectedArea: ds.connectedArea ? ds.connectedArea.toString() : "",
				oldAreaId: ds.connectedArea,
				receiptShowHide: ds.receipt > 0 ? true : false,
				receiptTime: ds.receipt > 1 ? true : false,
				receiptOutShowHide: ds.receiptOutOfActiveHourSelected > 0 ? true : false,
				reocShowHide: ds.reoccuringReceipt > 0 ? true : false,
				haveReceipt: ds.receipt > 0 ? true : false
			});
		}
	}
	serializeSearchTerm = ( st ) => {
		if(st != null && st.length > 0){
			return st.join(',');
		}
		return "";
	}
	handleSave() {
		if (this.state.connectedArea == "") {
			this.setState({errAreaEmpty: I("* You can not leave the area field empty.")});
			return;
		}
		if (this.state.name == "") {
			this.setState({errNameEmpty: I("* You can not leave the name field empty.")});
			return;
		}
		if (this.state.host == "") {
			this.setState({errServerEmpty: I("* You can not leave the server field empty.")});
			return;
		}
		if (this.state.username == "") {
			this.setState({errUserEmpty: I("* You can not leave the username field empty.")});
			return;
		}
		this.setState({saving: true});
		const saveData = {
			autoReply: this.state.autoReply,
			autoReplyActiveFrom: this.state.autoReplyActiveFrom,
			autoReplyActiveTo: this.state.autoReplyActiveTo,
			autoReplyAnswerNote: this.state.autoReplyAnswerNote,
			autoReplyForwardAddress: this.state.autoReplyForwardAddress,
			autoReplyMaxAnswersPerReply: this.state.autoReplyMaxAnswersPerReply,
			autoReplyUnanswered: this.state.autoReplyUnanswered,
			contentTransformation: this.state.contentTransformation,
			contentTransformationSubject: this.state.contentTransformationSubject,
			emailAddressSearchTerm: this.state.emailAddressSearchTerm,
			enabled: this.state.enabled,
			host: this.state.host,
			id: this.state.id,
			name: this.state.name,
			receipt: this.state.receipt,
			receiptActiveFrom: this.state.receiptActiveFrom,
			receiptActiveTo: this.state.receiptActiveTo,
			receiptBody: this.state.receiptBody,
			receiptEmailAddress: this.state.receiptEmailAddress,
			receiptSubject: this.state.receiptSubject,
			receiptOutOfActiveHourSelected: this.state.receiptOutOfActiveHourSelected,
			receiptSubjectOutOfActiveHour: this.state.receiptSubjectOutOfActiveHour,
			receiptBodyOutOfActiveHour: this.state.receiptBodyOutOfActiveHour,
			reoccuringReceipt: this.state.reoccuringReceipt,
			reoccuringReceiptsBody: this.state.reoccuringReceiptsBody,
			reoccurringReceiptSubject: this.state.reoccurringReceiptSubject,
			reoccurringReceiptInterval: this.state.reoccurringReceiptInterval,
			typeId: this.state.typeId,
			useSecureMessage: this.state.useSecureMessage,
			username: this.state.username,
			password: this.state.password,
			wantSSL: this.state.wantSSL,
			retainFetchedEmail: this.state.retainFetchedEmail,
			allowImapDelete: this.state.allowImapDelete,
			fetchSeenMessages: this.state.fetchSeenMessages,
			allowDelEmptyEmail: this.state.allowDelEmptyEmail,
			centionDelete: this.state.centionDelete,
			readTimeout: this.state.readTimeout,
			connectedArea: this.state.connectedArea,
			oldAreaId: this.state.oldAreaId
		};
		if (typeof this.state.oauthAccessToken === 'string') {
			saveData.oauthAccessToken = this.state.oauthAccessToken;
			if (this.state.oauthAccessToken) {
				saveData.oauthExpiry = this.state.oauthExpiry;
				saveData.oauthProvider = this.state.oauthProvider;
				saveData.oauthRefreshToken = this.state.oauthRefreshToken;
				saveData.oauthTokenType = this.state.oauthTokenType;
			}
		}
		this.props.onSave(saveData);
	}
	handleSecure(wantSSL) {
		if (typeof wantSSL === 'function') {
			this.setState(
				state => update(
					state,
					{ wantSSL: { $set: wantSSL(state.wantSSL) } }
				)
			)
		} else {
			this.setState({ wantSSL })
		}
	}
	updateName(name) {
		this.setState(nameUpdater(name));
	}
	handleChangeName(e) {
		this.updateName(e.target.value);
	}
	updateNameIfEmpty(nameFromToken) {
		this.setState(({ name }) => {
			if (!name) {
				return nameUpdater(nameFromToken);
			}
		})
	}
	handleHost(host) {
		if (typeof host === "function") {
			host = host(this.state.host);
			if (!host) {
				return;
			}
		}
		this.setState({host, errServerEmpty: ""});
	}
	updateHost(host) {
		this.handleHost(host);
	}
	handleChangeServer(e) {
		this.updateHost(e.target.value);
	}
	updateUsername(username) {
		this.setState({username, errUserEmpty: ""});
	}
	handleChangeUsername(e) {
		this.updateUsername(e.target.value);
	}
	updateToken({ access_token, refresh_token, expiry, provider, token_type }) {
		this.setState({
			oauthAccessToken: access_token,
			oauthExpiry: expiry,
			oauthProtocolType: this.state.typeId,
			oauthProvider: provider,
			oauthRefreshToken: refresh_token,
			oauthTokenType: token_type
		})
	}
	handleRemoveToken() {
		let emptyAccessToken
		if (typeof this.props.dataSrc.oauthProvider === 'number') {
			emptyAccessToken = ''
		} else {
			emptyAccessToken = null
		}
		this.setState({
			oauthAccessToken: emptyAccessToken,
			oauthExpiry: '',
			oauthProtocolType: null,
			oauthProvider: null,
			oauthRefreshToken: '',
			oauthTokenType: ''
		})
	}
	justAuthorizedTokenSameProtocol() {
		return isNumberOrNumberStringEqual(
			this.state.oauthProtocolType,
			this.state.typeId
		)
	}
	protocolHasAccessToken(token, provider) {
		if (token) {
			return this.justAuthorizedTokenSameProtocol()
		} else if (typeof provider === 'number') {
			return isNumberOrNumberStringEqual(
				this.props.dataSrc.typeId,
				this.state.typeId
			)
		}
		return false
	}
	justAuthorizedTokenProvider() {
		const provider = justAuthorizedTokenProvider(
			this.state.oauthAccessToken,
			this.state.oauthProvider
		)
		if (typeof provider === 'number' &&
			this.justAuthorizedTokenSameProtocol()) {
			return provider
		}
	}
	render() {
		const { normalizedAccountType } = this.props.dataSrc;
		let selectDuration = [{id: 0, value: I('No')},{id: 1, value:I('Yes')},{id: 2, value: I('Time Interval')}];
		let selectDurationR = [{id: 0, value: I('No')},{id: 1, value:I('Yes')}];
		let emailNotAutoReply = [ {id:1, value:I('Leave in inbox and ignore')},{id:2, value:I('Forward to another email account')},{id:3, value:I('Process as new errand')}];
		let cancelButton = <button className="btn btn-warning btn-sm" type="button" onClick={this.props.onCancel}>{I('Cancel')}</button>;
		let emailAddressSearchTerm = [];
		let stateAST = this.state.emailAddressSearchTerm;
		if( stateAST != ""){
			let ars = stateAST.split(",")
			if(ars.length > 0){
				ars.forEach((v,i) =>{
					if(v != "") emailAddressSearchTerm.push({id:i, value:v})
				});
			}
		}
		let receiptAddress =
			<div className="form-group">
				<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Receipt address")}</label>
				<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
					<input id="recAddress"
						type="input"
						name="recAddress"
						value={this.state.receiptEmailAddress}
						className="form-control input-sm"
						onChange={function(e){this.setState({receiptEmailAddress: e.target.value});}.bind(this)}
					/>
				</div>
				<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
				<div className="clear"></div>
			</div>;
		const { oauthAccessToken, oauthProvider, typeId } = this.state;
		const protocol = normalizedAccountType[typeId];

		return(
			<div id="wrapper" className="admin-profile">
				<div id="page-wrapper">
					<div className="row page-header-row">
						<div className="col-lg-12 page-header-container">
							<h4 className="page-header">{I("Administration")} | <span className="page-header-small-title">{I("Email")}</span></h4>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="alert alert-success" style={{display: 'none'}} role="alert" id="alert"></div>
							<div className="panel panel-default">
								<div className="panel-heading">
									{this.props.pageTitle}
								</div>
								<div className="panel-body">
									<div className="form-group">
										<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Area")}</label>
										<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
											<DropDown
												id="areas"
												name={I("Area")}
												textNoItem={I("No Area")}
												textChooseItem={I("Choose Area")}
												textNoItemSelected={I("No Area selected")}
												textSelectedItem={I("Selected Area")}
												selectedItems={this.state.connectedArea}
												items={this.props.dataSrc.areas}
												fields={{id: "Id", name: "Name"}}
												activeWhen="Inctive"
												nestedGroup="Areas"
												defaultSelectNone={false}
												multiSelect={false}
												wantSelectAll={false}
												wantSelectNone={true}
												wantShowDone={false}
												onChange={function(v){
													this.setState({connectedArea: v, errAreaEmpty: ""});
												}.bind(this)}
											/>
											<div style={{color: 'red'}} className="help-block with-errors-area">{this.state.errAreaEmpty}</div>
										</div>
										<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
										<div className="clear"></div>
									</div>
									<div className="form-group">
										<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Name")}</label>
										<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
											<Input
												name="name"
												onChange={this.handleChangeName}
												value={this.state.name}
											/>
											<div style={{color: 'red'}} className="help-block with-errors-name">{this.state.errNameEmpty}</div>
										</div>
										<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
										<div className="clear"></div>
									</div>

									<div className="form-group">
										<label className="col-lg-3 col-sm-3 col-xs-3">{I("Enabled")}</label>
										<div className="col-lg-8 col-sm-4 col-xs-7">
											<input type="checkbox" checked={this.state.enabled} onChange={this.handleChkEnable} />
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-2"></div>
										<div className="clear"></div>
									</div>

									<div className="form-group">
										<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Server")}</label>
										<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
											<Input
												name="server"
												onChange={this.handleChangeServer}
												value={this.state.host}
											/>
											<div style={{color: 'red'}} className="help-block with-errors-server">{this.state.errServerEmpty}</div>
										</div>
										<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
										<div className="clear"></div>
									</div>

									<div className="form-group">
										<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Username")}</label>
										<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
											<Input
												name="username"
												onChange={this.handleChangeUsername}
												value={this.state.username}
											/>
											<div style={{color: 'red'}} className="help-block with-errors-username">{this.state.errUserEmpty}</div>
										</div>
										<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
										<div className="clear"></div>
									</div>

									<div className="form-group">
										<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Password")}</label>
										<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
											<input id="password"
												type="password"
												name="password"
												className="form-control input-sm"
												onChange={function(e){this.setState({password: e.target.value});}.bind(this)}
											/>
											<div style={{color: 'red'}} className="help-block with-errors-password"></div>
										</div>
										<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
										<div className="clear"></div>
									</div>
									<div className="form-group">
										<label className="col-lg-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Account type")}</label>
										<StyledDiv className='col-lg-8 col-sm-4 col-xs-7'>
											<CentionPopup
												id="accountType"
												multiple={false}
												selectedItems={[this.props.dataSrc.typeId]}
												src={this.props.dataSrc.accountType}
												pageSrc="admin"
												onChange={function( els ){
													this.setState({typeId: els[0]});
												}.bind(this)}
											/>
											<AuthorizedToken
												hasAccessToken={this.protocolHasAccessToken(
													oauthAccessToken,
													oauthProvider
												)}
												hidden={!protocolSupportsOAuth2(protocol)}
												onClear={this.handleRemoveToken}
												provider={oauthProvider}
											/>
										</StyledDiv>
										<div className="clear"></div>
									</div>
									<Authorization
										onChangeName={this.updateNameIfEmpty}
										onChangeSecure={this.handleSecure}
										onChangeServer={this.handleHost}
										onChangeToken={this.updateToken}
										onChangeUsername={this.updateUsername}
										protocol={protocol}
										provider={this.justAuthorizedTokenProvider()}
									/>
									<div className="form-group">
										<label className="col-lg-3 col-sm-3 col-xs-3">{I("Use SSL")}</label>
										<div className="col-lg-8 col-sm-4 col-xs-7">
											<input type="checkbox" checked={this.state.wantSSL} onChange={this.handleChkSSL} />
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-2"></div>
										<div className="clear"></div>
									</div>
									<div className="form-group">
										<label className="col-lg-3 col-sm-3 col-xs-3">{I("Retain fetched email")}</label>
										<div className="col-lg-8 col-sm-4 col-xs-7">
											<input type="checkbox" name="retainFetchedEmail" checked={this.state.retainFetchedEmail} onChange={this.handleCheckBoxChange} />
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-2">
											<CHelper previewMessage={I("Retain fetched emails")} pos="left"/>
										</div>
										<div className="clear"></div>
									</div>
									<div className="form-group">
										<label className="col-lg-3 col-sm-3 col-xs-3">{I("Allow IMAP delete")}</label>
										<div className="col-lg-8 col-sm-4 col-xs-7">
											<input type="checkbox" name="allowImapDelete" checked={this.state.allowImapDelete} onChange={this.handleCheckBoxChange} />
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-2">
											<CHelper previewMessage={I("Allow IMAP protocol to delete fetched emails")} pos="left"/>
										</div>
										<div className="clear"></div>
									</div>
									<div className="form-group">
										<label className="col-lg-3 col-sm-3 col-xs-3">{I("Fetch seen messages")}</label>
										<div className="col-lg-8 col-sm-4 col-xs-7">
											<input type="checkbox" name="fetchSeenMessages"  checked={this.state.fetchSeenMessages} onChange={this.handleCheckBoxChange} />
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-2">
											<CHelper previewMessage={I("Fetch all emails include those unseen")} pos="left"/>
										</div>
										<div className="clear"></div>
									</div>
									<div className="form-group">
										<label className="col-lg-3 col-sm-3 col-xs-3">{I("Delete empty body email")}</label>
										<div className="col-lg-8 col-sm-4 col-xs-7">
											<input type="checkbox" name="allowDelEmptyEmail" checked={this.state.allowDelEmptyEmail} onChange={this.handleCheckBoxChange} />
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-2">
											<CHelper previewMessage={I("Delete all fetched emails that are empty")} pos="left"/>
										</div>
										<div className="clear"></div>
									</div>
									<div className="form-group">
										<label className="col-lg-3 col-sm-3 col-xs-3">{I("Cention delete")}</label>
										<div className="col-lg-8 col-sm-4 col-xs-7">
											<input type="checkbox" name="centionDelete" checked={this.state.centionDelete} onChange={this.handleCheckBoxChange} />
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-2">
											<CHelper previewMessage={I("Delete fetched emails on Cention mail servers")} pos="left"/>
										</div>
										<div className="clear"></div>
									</div>
									<div className="form-group">
										<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Read timeout")}</label>
										<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
											<textarea id="readTimeout"
												type="input"
												name="readTimeout"
												value={this.state.readTimeout}
												className="form-control input-sm"
												onChange={function(e){this.setState({readTimeout: e.target.value});}.bind(this)}
											></textarea>
											<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-2">
											<CHelper previewMessage={I("Low level read timeout (seconds) before unblock")} pos="left"/>
										</div>
										<div className="clear"></div>
									</div>
									<div className="form-group">
										<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Email address search term")}</label>
										<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
												<TaggedPicker id="serachTerm"
													key="serachTerm"
													tags={[]}
													allowAddCustomItem={true}
													selectedItems={emailAddressSearchTerm}
													fields={{id:"id",name:"value"}}
													optional={false}
													allowPasteInput={true}
													onChange={this.handleSearchTerm} />
											<ErrorBox id="serachTerm" message={""} key="errorBox_serachTerm" />
										</div>
										<div className="col-lg-1 col-md-1 col-sm-1 col-xs-2">
											<CHelper previewMessage={I("By entering a value here the system will automatically search for an email address in the text and make that the errands from address.")+
												"<p>" +
												I("Enter the string the system should search for an then put [email] at place were the email address will be.") +
												"<p />"+
												I("Example:") +
												"<br />" +
												I("<strong>From: [email]</strong>")} pos="left"/>
										</div>
										<div className="clear"></div>
									</div>

									<div className="form-group">
										<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Content transformation subject")}</label>
										<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
											<input id="transformSubject"
												type="input"
												name="transformSubject"
												value={this.state.contentTransformationSubject}
												className="form-control input-sm"
												onChange={function(e){this.setState({contentTransformationSubject: e.target.value});}.bind(this)}
											/>
											<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-2">
											<CHelper previewMessage={I("By entering a value here the system when transforming the content also replace the subject with what you put in this field. If you leave this field blank the original subject will be kept.")} pos="left"/>
										</div>
										<div className="clear"></div>
									</div>

									<div className="form-group">
										<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Content transformation")}</label>
										<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
											<textarea id="transformContent"
												type="input"
												name="transformContent"
												value={this.state.contentTransformation}
												className="form-control input-sm"
												onChange={function(e){this.setState({contentTransformation: e.target.value});}.bind(this)}
											></textarea>
											<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-2">
											<CHelper previewMessage={I("By entering a value here the system will automatically transform the content of an incoming email so it looks the way specified in this field.") +
												"<p>" +
												I("You can use the following templates to put information from the email in your transformed content.") +
												"<ul><li><b>{{EMAIL}}</b><br />" +
												I("Email's from address.") +
												"</li><li><b>{{CONTENT}}</b><br />" +
												I("Email's content.") +
												"</li></ul><p /><b>" +
												I("Example:")+ "</b><br />"+
												"[Email]<br />{{EMAIL}}<br /><br />[Content]<br />{{CONTENT}}"} pos="left"/>
										</div>
										<div className="clear"></div>
									</div>

									<Feature tag="cention-automaticanswers">
										<div id="autoAnswerShowHide">
											<div className="form-group">
												<label className="col-lg-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Auto reply")}</label>
												<div className="col-lg-2 col-sm-2 col-xs-4">
													<CentionPopup
														id="reoccureOnReceive"
														multiple={false}
														selectedItems={[this.state.autoReply]}
														src={selectDuration}
														pageSrc="admin"
														onChange={function( els ){this.setState({autoReply: els[0]});}.bind(this)}
													/>
												</div>
												<div className="col-lg-2 col-sm-1 col-xs-1"></div>
												<div className="col-lg-5 col-sm-5 col-xs-4"></div>
												<div className="clear"></div>
											</div>

											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Maximum allowed answer alternatives per reply")}</label>
												<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
													<input id="maxAllowedAnswer"
														type="input"
														name="maxAllowedAnswer"
														value={this.state.autoReplyMaxAnswersPerReply}
														className="form-control input-sm"
														onChange={function(e){this.setState({autoReplyMaxAnswersPerReply: e.target.value});}.bind(this)}
													/>
													<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
												</div>
												<div className="col-lg-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>

											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Email not auto replied to")}</label>
												<div className="col-lg-5 col-md-4 col-sm-8 col-xs-8">
														<CentionPopup
															id="emailNotAuto"
															multiple={false}
															selectedItems={[this.state.autoReplyUnanswered]}
															src={emailNotAutoReply}
															pageSrc="admin"
															onChange={function( els ){this.setState({autoReplyUnanswered: els[0]});}.bind(this)}
														/>
													<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
												</div>
												<div className="col-lg-3 col-sm-1 col-xs-1"></div>
												<div className="col-lg-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>

											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Forward address for email not auto replied to")}</label>
												<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
													<input id="forwardAddress"
														type="input"
														name="forwardAddress"
														value={this.state.autoReplyForwardAddress}
														className="form-control input-sm"
														onChange={function(e){this.setState({autoReplyForwardAddress: e.target.value});}.bind(this)}
													/>
													<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
												</div>
												<div className="col-lg-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>

											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Note in forwarded emails")}</label>
												<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
													<textarea id="noteForward"
														type="input"
														name="noteForward"
														value={this.state.autoReplyAnswerNote}
														className="form-control input-sm"
														onChange={function(e){this.setState({autoReplyAnswerNote: e.target.value});}.bind(this)}
													></textarea>
													<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
												</div>
												<div className="col-lg-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>

											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Active from (hhmm)")}</label>
												<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
													<input id="autoActiveFrom"
														type="input"
														name="autoActiveFrom"
														value={this.state.autoReplyActiveFrom}
														className="form-control input-sm"
														onChange={function(e){this.setState({autoReplyActiveFrom: e.target.value});}.bind(this)}
													/>
													<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
												</div>
												<div className="col-lg-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>

											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Active to (hhmm)")}</label>
												<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
													<input id="autoActiveTo"
														type="input"
														name="autoActiveTo"
														value={this.state.autoReplyActiveTo}
														className="form-control input-sm"
														onChange={function(e){this.setState({autoReplyActiveTo: e.target.value});}.bind(this)}
													/>
													<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
												</div>
												<div className="col-lg-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>

											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Note in auto replies")}</label>
												<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
													<textarea id="noteReply"
														type="input"
														name="noteReply"
														value={this.state.autoReplyAnswerNote}
														className="form-control input-sm"
														onChange={function(e){this.setState({noteReply: e.target.value});}.bind(this)}
													></textarea>
													<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
												</div>
												<div className="col-lg-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>
										</div>
									</Feature>

									<Hideable hidden={PC_RECEIPT_GREETING}>
									<div className="form-group">
										<label className="col-lg-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Receipt")}</label>
										<div className="col-lg-3 col-sm-2 col-xs-4">
											<CentionPopup
												id="receiptOnReceive"
												multiple={false}
												selectedItems={[this.props.dataSrc.receipt]}
												src={selectDuration}
												pageSrc="admin"
												onChange={this.handleReceipt}
											/>
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-1"></div>
										<div className="col-lg-5 col-sm-5 col-xs-4"></div>
										<div className="clear"></div>
									</div>

									<div id="receiptShowHide" style={{display: (this.state.receiptShowHide ? 'block' : 'none')}}>
										<div id="receiptTimeShowHide" style={{display: (this.state.receiptTime ? 'block' : 'none')}}>
											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Active from (hhmm)")}</label>
												<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
													<Calendar id="wsStartDate" timepicker={true} selectedDate={this.state.receiptActiveFrom} onChange={this.receiptActiveFromDate}/>
												</div>
												<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>

											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Active to (hhmm)")}</label>
												<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
													<Calendar id="wsTodate" timepicker={true} selectedDate={this.state.receiptActiveTo}
														onChange={this.receiptActiveToDate}/>
												</div>
												<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>
										</div>
										{receiptAddress}
										<div className="form-group">
											<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Receipt subject")}</label>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<input id="recSubject"
													type="input"
													name="recSubject"
													value={this.state.receiptSubject}
													className="form-control input-sm"
													onChange={function(e){this.setState({receiptSubject: e.target.value});}.bind(this)}
												/>
											</div>
											<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
											<div className="clear"></div>
										</div>

										<div className="form-group">
											<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Receipt body")}</label>
											<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
												<Ckeditor
													id={"recContent"}
													ref={"recContent"}
													defaultFontFamily={this.props.dataSrc.fontFamily}
													defaultFontSize={this.props.dataSrc.fontSize}
													defaultContent={this.state.receiptBody}
													spellLanguages={this.props.dataSrc.langList}
													showResize={false}
													simpleToolbar={false}
													fileArchiveImages={this.props.dataSrc.filearchive}
													onChange={this.handleRecCkeditorChange}
													onKeydown={function(){}}
												/>
												<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
											</div>
											<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
											<div className="clear"></div>
										</div>
										<div className="form-group">
											<label className="col-lg-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Receipt outside chosen time interval")}</label>
											<div className="col-lg-3 col-sm-2 col-xs-4">
												<CentionPopup
													id="rcptOutOnReceive"
													multiple={false}
													selectedItems={[this.props.dataSrc.receiptOutOfActiveHourSelected]}
													src={selectDurationR}
													pageSrc="admin"
													onChange={this.handleReceiptOutOfActiveHour}
												/>
											</div>
											<div className="col-lg-1 col-sm-1 col-xs-1"></div>
											<div className="col-lg-5 col-sm-5 col-xs-4"></div>
											<div className="clear"></div>
										</div>
										<div id="receiptOutOfActiveHourToggle" style={{display: (this.state.receiptOutShowHide ? 'block' : 'none')}}>
											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Receipt subject")}</label>
												<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
													<input id="recSubject"
														type="input"
														name="recSubject"
														value={this.state.receiptSubjectOutOfActiveHour}
														className="form-control input-sm"
														onChange={function(e){this.setState({receiptSubjectOutOfActiveHour: e.target.value});}.bind(this)}
													/>
												</div>
												<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>
											<div className="form-group">
												<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Receipt body")}</label>
												<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
													<Ckeditor
														id={"recOutContent"}
														ref={"recOutContent"}
														defaultFontFamily={this.props.dataSrc.fontFamily}
														defaultFontSize={this.props.dataSrc.fontSize}
														defaultContent={this.state.receiptBodyOutOfActiveHour}
														spellLanguages={this.props.dataSrc.langList}
														showResize={false}
														simpleToolbar={false}
														fileArchiveImages={this.props.dataSrc.filearchive}
														onChange={this.handleRecOutCkeditorChange}
														onKeydown={function(){}}
													/>
													<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
												</div>
												<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
												<div className="clear"></div>
											</div>
										</div>
									</div>
									<div className="form-group">
										<label className="col-lg-3 col-sm-3 col-xs-3 control-label" htmlFor="basic"><span className="mandatory">* </span>{I("Reoccurring receipts")}</label>
										<div className="col-lg-3 col-sm-2 col-xs-4">
											<CentionPopup
												id="reoccureOnReceive"
												multiple={false}
												selectedItems={[this.props.dataSrc.reoccuringReceipt]}
												src={selectDurationR}
												pageSrc="admin"
												onChange={this.handleReoccReceipt}
											/>
										</div>
										<div className="col-lg-1 col-sm-1 col-xs-1"></div>
										<div className="col-lg-5 col-sm-5 col-xs-4"></div>
										<div className="clear"></div>
									</div>

									<div id="reoccuringShowHide" style={{display: (this.state.reocShowHide ? 'block' : 'none')}}>
										<div id="receipt_address" style={{display: (!this.state.receiptShowHide ? 'block' : 'none')}}>
											{receiptAddress}
										</div>
										<div className="form-group">
											<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Reoccurring receipt interval (days)")}</label>
											<div className="col-lg-2 col-md-8 col-sm-8 col-xs-8">
												<input id="reocInterval"
													type="input"
													name="reocInterval"
													value={this.state.reoccurringReceiptInterval}
													className="form-control input-sm"
													onChange={function(e){this.setState({reoccurringReceiptInterval: e.target.value});}.bind(this)}
												/>
											</div>
											<div className="col-lg-7 col-md-1 col-sm-1 col-xs-1"></div>
											<div className="clear"></div>
										</div>

										<div className="form-group">
											<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Receipt subject")}</label>
											<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
												<input id="reocSubject"
													type="input"
													name="reocSubject"
													value={this.state.reoccurringReceiptSubject}
													className="form-control input-sm"
													onChange={function(e){this.setState({reoccurringReceiptSubject: e.target.value});}.bind(this)}
												/>
											</div>
											<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
											<div className="clear"></div>
										</div>

										<div className="form-group">
											<label className="col-lg-3 col-md-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">{I("Receipt body")}</label>
											<div className="col-lg-8 col-md-4 col-sm-8 col-xs-8">
												<Ckeditor
													id={"reocContent"}
													ref={"reocContent"}
													defaultFontFamily={this.props.dataSrc.fontFamily}
													defaultFontSize={this.props.dataSrc.fontSize}
													defaultContent={this.state.reoccuringReceiptsBody}
													spellLanguages={this.props.dataSrc.langList}
													showResize={false}
													simpleToolbar={false}
													fileArchiveImages={this.props.dataSrc.filearchive}
													onChange={this.handleReocCkeditorChange}
													onKeydown={function(){}}
												/>
												<div style={{color: 'red'}} className="help-block with-errors-lname"></div>
											</div>
											<div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
											<div className="clear"></div>
										</div>
									</div>
									</Hideable>
									<div className="form-group editPageActionButton" style={{marginTop:'20px'}}>
										<div className="col-lg-12 textAlignRight">
											{cancelButton}
											{this.renderSaveButton()}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
		
	}

	renderSaveButton = () => {
		if(this.state.saving){
			return (<button className="btn btn-primary btn-sm" type="button"><i id="searchIcon" style={{marginRight: '7px'}} className="fa fa-circle-o-notch fa-spin"></i>{I('Saving...')}</button>);
		} else {
			return (<button className="btn btn-primary btn-sm" type="button" onClick={this.handleSave}>{I('Save')}</button>);
		}
	}

	receiptActiveFromDate = (ndate) =>{
		this.setState({receiptActiveFrom: ndate});
	}

	receiptActiveToDate = (ndate) =>{
		this.setState({receiptActiveTo: ndate});
	}

	handleChkEnable = (e) => {
		let pv = this.state.enabled;
		this.setState({
			enabled: !pv
		});
	}

	handleChkSSL() {
		this.handleSecure(wantSSL => !wantSSL)
	}

	handleCheckBoxChange = (e) => {
		const name = e.target.name;
		this.setState(
			state => update(
				state,
				{ [name]: { $set: !state[name] } }
			)
		)
    };

	handleSecureMsg = () =>{
		let pv = this.state.useSecureMessage;
		this.setState({
			useSecureMessage: !pv
		});
	}

	handleSearchTerm = (sts) =>{
		let items = "";
		if(sts.length >0){
			sts.forEach( (v,i) =>{
				if(sts.length-1 > i)
					items += v.value + ",";
				else
					items += v.value;
			})
		}
		this.setState({emailAddressSearchTerm: items});
	}

	handleRecCkeditorChange = (e) =>{
		this.setState({receiptBody: e.editor.getData()});
	}

	handleRecOutCkeditorChange = (e) =>{
		this.setState({receiptBodyOutOfActiveHour: e.editor.getData()});
	}

	handleReocCkeditorChange = (e) =>{
		this.setState({reoccuringReceiptsBody: e.editor.getData()});
	}
	handleReceipt = ( els ) =>{
		if(els.length > 0){
			let r = els[0];
			let rsh = false;
			let rt = false;
			let rr  = false
			if(r == 1){rsh = true;rr = true}
			if(r > 1){rsh = true;rt = true; rr = true}

			this.setState({receipt: r, receiptShowHide: rsh, receiptTime: rt, haveReceipt: rr});
		}
	}
	handleReoccReceipt = ( els ) =>{
		if( els.length > 0){
			let r = els[0];
			let rsh = false;
			if(r == 1){ rsh = true;}
			this.setState({reoccuringReceipt: els[0], reocShowHide: rsh});
		}
	}
	handleReceiptOutOfActiveHour = (els) => {
		if( els.length > 0){
			let r = els[0];
			let rsh = false;
			if(r == 1){ rsh = true;}
			this.setState({receiptOutOfActiveHourSelected: els[0], receiptOutShowHide: rsh});
		}
	}
}

export default EmailForm;
