import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import addonUpdate from 'react-addons-update';
import io from 'socket.io-client';
import format from 'StrFormat';
import 'soundmanager2';
//import 'jquery-titlealert';
import 'lightbox2';
//dev component
import {I} from '../common/v5/config.js';
import Ckeditor from '../components/Ckeditor';
import socketMan from '../components/socketMan';
import TagItemList from '../components/TagItemList';
import ErrandNotes from '../components/ErrandNotes';
import BootstrapModal from '../components/BootstrapModal';
import DropDown from '../components/DropDown';
import ErrandTranslation from '../components/ErrandTranslation';
import FileUploader from '../components/FileUploader';
import LibraryTree from '../components/LibraryTree';
import ContactCard from './contactcards/edit';
import ErrandThread from '../components/ErrandThread';
import TemplateList from '../components/TemplateList';
import AttachmentsContainer from '../components/AttachmentsContainer';
import Feature from '../components/feature';
import VisitorPath from '../components/VisitorPath';
import ErrandHelper from '../components/ErrandHelper';
//FLUX actions and store
import {
	ChatActions,
	ChatStore
} from '../components/chatInterface';
import { filePathPrefix } from '../common/v5/helpers.js';

const Errand_SERVICE_CHAT = 3;
const Errand_SERVICE_FACEBOOK = 4;

	var urlParams;
	var JSON_ENCODED = "\x01JSON";
	(window.onpopstate = function () {
		var match
		, pl     = /\+/g  // Regex for replacing addition symbol with a space
		, search = /([^&=]+)=?([^&]*)/g
		, decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); }
		, query  = window.location.search.substring(1)
		;
		urlParams = {};
		while (match = search.exec(query)) {
			urlParams[decode(match[1])] = decode(match[2]);
		}
	})();
	function ToChannelName(channel,defaultName){
		switch(channel){
			case Errand_SERVICE_FACEBOOK: return "facebook";
			default: return defaultName;
		};
	}
	var subscribeCount = 0;
	var sessionMessageMap = {};
	var sessionsAttachmentMap = {};
	var ckePlaceholderText = I("Type your reply here...");
	// Linker linkifies text that looks like URL in text given by
	// ckeditor. An exact copy of this Linker code exist in external
	// chat.js so if there is a bug fix remember to fix it in both places.
	var Linker = {
		// Regex for capturing urls in ckeditor html.
		urlRegex: /(>|^)(https?:\/\/[^ \<]+)/i,

		// Stupid simple url linkification.  This is not perfect but it
		// covers most use cases. Handles http and https links only.
		linkifyUrls: function (html) {
			var res
			, i
			;

			res = Array.prototype.slice.call(html.split(/(\&nbsp;|\s)+/g))

			for(i=0; i<res.length; i++) {
				res[i] = res[i].replace(this.urlRegex, function(match, p1, p2) {
					var url = p2
						, lastChunk = ""
					;
					while (url && url.length > 1 && (
						url[url.length-1] == '.' ||
							url[url.length-1] == ',' ||
							url[url.length-1] == '"' ||
							url[url.length-1] == ']' ||
							url[url.length-1] == ')'
					)) {
						lastChunk += url[url.length-1];
						url = url.substring(0, url.length-1);
					}
					var removeExtraTagRegex = /(<([^>]+)>)/ig;
					var linkSrc = url.replace(/"/g, '\\x22');
					var sanitizeLinkSrc = linkSrc.replace(removeExtraTagRegex, "");
					return [ p1
						, '<a href="' + sanitizeLinkSrc + '" target="_blank">' + url + '</a>'
						, lastChunk
					].join('');
				});
			}
			return res.join(' ');
		}
	};

	function isInt(v) {
		return v === parseInt(v, 10);
	};

	function messageIsFromMe(msg) {
		return msg.aid === initialChatData.agentID;
	}

	function toFilterChunks(text) {
		if (!text) {
			return [];
		}
		return text.trim().toLowerCase().split(/\s+/)
	}

	function getAjaxError(e) {
		if (e) {
			switch (e.status) {
				case 401:
					return I("Please log in");
			}
		}
		return I("An error occurred");
	}

	function getClientEmailAddress(session) {
		// returns email address in the rfc2822 address specification (section 3.4)
		var clientName = session.client
		, clientEmail = session.clientEmail
		, address = ""
		;

		if (clientName && clientEmail) {
			address = clientName + " <" + clientEmail + ">";
		} else if (clientName) {
			address = clientName
		} else if (clientEmail) {
			address = "<" + clientEmail + ">";
		}
		return address;
	}

	function shortenText(name, limit) {
		var shortenTxt = name;
		if(name.length > limit)
			shortenTxt = name.substring(0, (limit-3))+"...";
			return shortenTxt;
		}
	function fetchTranslation( tc, fromLang, toLang ){
		var translatioServer = window.location.protocol + "//" +window.location.hostname + "/Cention/";
		var translateUrl = translatioServer + "translate";
		var ts = {
					texts: tc,
					from: fromLang,
					to: toLang
				};
		return $.ajax(translateUrl, {
				type: "POST",
				data: JSON.stringify(ts),
				contentType: "application/json",
			});
	}
	var SystemMessage = createReactClass({
		displayName: "SystemMessage",
		getInitialState: function() {
			return {
				expandAction: false,
			}
		},
		renderSetAgentsEvent: function(m) {
			var actions = []
			, title
			, messages = []
			, actionsHtml
			, style
			, byInvitation = m.byInvitation
			, agentName =function (id) {
				var agent = getAgent(id)
				, name
				;
				if (!agent) {
					if (m.names) {
						name = m.names[id];
						if (name) {
							// This is what should happen 100% of the time
							return name;
						} else {
							// this should not happen
							socketMan.log("error: m.names[id] not available? id:", id);
						}
					} else {
						// this should not happen
						socketMan.log("error: m.names not available?");
					}
					return "N/A ("+id+")";
				}
				return agentChatName(agent);
			}
			, agentNamesCsv = function(ids) {
				var names = [];
				ids.map(function(id) {
					names.push(agentName(id));
				});
				names.sort(function(a, b) {
					if (a < b) { return -1; }
					if (a > b) { return 1; }
					return 0;
				});
				return names.join(", ");
			}
			, removedAgents = m.removed.concat(m.uninvited)
			, whoName = agentName(m.who)
			, pushAddedActions = function() {
				actions.push((byInvitation?I("Invited: {AGENT_NAMES}"):I("Added: {AGENT_NAMES}"))
					.replace("{AGENT_NAMES}", agentNamesCsv(m.added)));
			}
			, pushRemovedActions = function() {
				actions.push(I("Removed: {AGENT_NAMES}").replace("{AGENT_NAMES}", agentNamesCsv(removedAgents)));
			}
			;

			if        (m.added.length == 0 && removedAgents.length == 0) {
				// We should not reach here
			} else if (m.added.length == 0 && removedAgents.length >  0) {
				if (removedAgents.length == 1) {
					title = I("{WHO} removed {WHOM}")
						.replace("{WHO}", whoName)
						.replace("{WHOM}", agentName(removedAgents[0]));
				} else {
					title = I("{WHO} removed {NUMBER} agents")
						.replace("{WHO}", whoName)
						.replace("{NUMBER}", removedAgents.length);
					pushRemovedActions();
				}
			} else if (m.added.length >  0 && removedAgents.length == 0) {
				if (m.added.length == 1) {
					title = (byInvitation?I("{WHO} invited {WHOM}"):I("{WHO} added {WHOM}"))
						.replace("{WHO}", whoName)
						.replace("{WHOM}", agentName(m.added[0]));
				} else {
					title = (byInvitation?I("{WHO} invited {NUM_ADDED} agents"):I("{WHO} added {NUM_ADDED} agents"))
						.replace("{WHO}", whoName)
						.replace("{NUM_ADDED}", m.added.length);
					pushAddedActions();
				}
			} else if (m.added.length >  0 && removedAgents.length >  0) {
				if        (m.added.length == 1 && removedAgents.length == 1) {
					title = I("{WHO} removed {REMOVED}, added {ADDED}").replace("{WHO}", whoName)
						.replace("{REMOVED}", agentName(removedAgents[0]))
						.replace("{ADDED}", agentName(m.added[0]));
				} else if (m.added.length == 1 && removedAgents.length >  1) {
					title = (byInvitation?I("{WHO} invited {ADDED}, removed {NUM_REMOVED} agents"):I("{WHO} added {ADDED}, removed {NUM_REMOVED} agents"))
						.replace("{WHO}", whoName)
						.replace("{ADDED}", agentName(m.added[0]))
						.replace("{NUM_REMOVED}", removedAgents.length);
					pushRemovedActions();
				} else if (m.added.length >  1 && removedAgents.length == 1) {
					text = (byInvitation?I("{WHO} removed {REMOVED}, invited {NUM_ADDED} agents"):I("{WHO} removed {REMOVED}, added {NUM_ADDED} agents"))
						.replace("{WHO}", whoName)
						.replace("{REMOVED}", agentName(removedAgents[0]))
						.replace("{NUM_ADDED}", m.added.length);
					title = text;
					pushAddedActions();
				} else if (m.added.length >  1 && removedAgents.length >  1) {
					text = (byInvitation?I("{WHO} removed {NUM_ADDED} agents:, added {NUM_REMOVED} agents"):I("{WHO} removed {NUM_ADDED} agents:, added {NUM_REMOVED} agents"))
						.replace("{WHO}", whoName)
						.replace("{NUM_REMOVED}", removedAgents.length)
						.replace("{NUM_ADDED}", m.added.length);
					title = text;
					pushRemovedActions();
					pushAddedActions();
				}
			} else {
				socketMan.log("FIXME", new Error("should not happen"));
				return null;
			}

			if (actions.length > 0) {
				var key = -1;
				actionsHtml = <div className={this.state.expandAction?"":"hide"}>
					<ul style={bareListStyle}>
						{actions.map(function(action) {
							key++;
							return <li key={"action_"+key}> {action} </li>
						})}
					</ul>
				</div>
			}
			return <div>
						<span>{title} {actions.length > 0?<a href="#" onClick={this.expandAction}>...</a>:null}</span>
						{actionsHtml}
			</div>
		},
		renderAgentLeftEvent: function(m) {
			return <div>
				{I("{AGENT_NAME} has left the chat.").replace("{AGENT_NAME}", m.name)}
			</div>
		},
		renderClientEndsChat: function(m) {
			var text;
			if (m.name == "UNKNOWN") {
				text = I("Client ended the chat.");
			} else {
				text = I("{NAME} ended the chat.").replace("{NAME}", m.name);
			}
			return <div>
				{text}
			</div>
		},
		renderForwardToAgent: function(m) {
			var text;

			text = I("{FROM_AGENT} forwarded the chat to {TO_AGENT}")
				.replace("{FROM_AGENT}", m.from)
				.replace("{TO_AGENT}", m.to)
				;
			return <div>
				{text}
			</div>
		},
		renderForwardToArea: function(m) {
			var text;

			text = I("{FROM_AGENT} forwarded the chat to area {AREA_NAME}")
				.replace("{FROM_AGENT}", m.from)
				.replace("{AREA_NAME}", m.areaName)
				;
			return <div>
				{text}
			</div>
		},
		expandAction: function() {
			this.setState({expandAction: !this.state.expandAction});
		},
		renderSendChatHistory: function(m) {
			var text = I("A copy of the chat will be sent to {CLIENT_EMAIL}")
				.replace("{CLIENT_EMAIL}", m.to)
				;
			return <div>
				{text}
			</div>;
		},
		renderAcceptChat: function(m) {
			var text = I("{AGENT_NAME} joins the chat.")
				.replace("{AGENT_NAME}", m.who)
				;
			return <div>
				{text}
			</div>;
		},
		renderRejectChat: function(m) {
			var text = I("{AGENT_NAME} declined the chat.")
				.replace("{AGENT_NAME}", m.who)
				;
			return <div>
				{text}
			</div>;
		},
		renderChatExpired: function(m) {
			var text = I("This chat has expired.");
			return <div>
				{text}
			</div>;
		},
		renderOwnerEndsChat: function(m) {
			var text = I("{AGENT_NAME} ended the chat.").
				replace("{AGENT_NAME}", m.who);
			return <div>
				{text}
			</div>;
		},
		render: function() {
			var m = JSON.parse(this.props.json);
			if (!m) {
				socketMan.log("error: could not parse system message", this.props.json);
				return null;
			}
			switch (m.event) {
				case "SET_AGENTS":
					return this.renderSetAgentsEvent(m);
					break;
				case "AGENT_LEFT":
					return this.renderAgentLeftEvent(m);
					break;
				case "CLIENT_ENDS_CHAT":
					return this.renderClientEndsChat(m);
					break;
				case "FORWARD_TO_AGENT":
					return this.renderForwardToAgent(m);
				case "FORWARD_TO_AREA":
					return this.renderForwardToArea(m);
				case "SEND_CHAT_HISTORY":
					return this.renderSendChatHistory(m);
				case "ACCEPT_CHAT":
					return this.renderAcceptChat(m);
				case "REJECT_CHAT":
					return this.renderRejectChat(m);
				case "CHAT_EXPIRED":
					return this.renderChatExpired(m);
				case "OWNER_ENDS_CHAT":
					return this.renderOwnerEndsChat(m);
			}
			return <p>{this.props.json}</p>
		},
	});

	var StatusBox = createReactClass({
		render: function(){
			return(
				<button key={'StatusBox'+this.props.id}  className="trigger" onClick={this.props.clickHandler}>
					{I("New message available")}
				</button>
			)
		}
	});
	var Message = createReactClass({
		displayName: "Message",
		getInitialState: function() {
			return {translatedMsg: ""};
		},
		componentWillMount: function(){
			if (this.props.active) {
				this.translateClientMessage(this.props.translation);
			}
		},
		componentWillUpdate: function(nextProps, nextState){
			if(nextProps.active && (this.props.translation.shouldTranslate !== nextProps.translation.shouldTranslate ||
				this.props.translation.fromLang !== nextProps.translation.fromLang ||
				this.props.translation.toLang !== nextProps.translation.toLang)) {
				this.translateClientMessage(nextProps.translation);
			}
		},
		isSystemMessage: function() {
			if (this.props.msg.fromClient) {
				return false;
			}

			return this.props.msg.aid === 0;
		},
		getClassName: function() {
			if (this.props.msg.fromClient) {
				return "other";
			} else if (this.props.msg.aid === 0) {
				return "system";
			}

			if (isInt(this.props.msg.aid) && this.props.msg.aid > 0) {
				if (this.props.msg.aid === initialChatData.agentID) {
					return "self";
				}
				// Sent by another agent
				return "other otherAgent";
			}

			// Unsent message
			return "self";
		},
		getUserName: function() {
			if (this.props.msg.fromClient) {
				return this.props.client;
			}
			if (this.props.msg.aid) {
				return this.props.msg.agent;
			}
			return I("System");
		},
		shouldShowMessage: function(){
			if(this.props.msg.preview && this.props.msg.text.length > 0 && initialChatData.chatMsgPreviewType == 1 && this.props.msg.fromClient){
				return false;
			}
			return true;
		},
		getHtmlMsg: function(){
			if (this.props.msg.linkified) {
				return this.props.msg.linkified;
			} else {
				return Linker.linkifyUrls(this.props.msg.text);
			}
		},
		useTranslation: function(translation){
			return this.shouldShowMessage() &&
					translation.shouldTranslate &&
					this.props.msg.fromClient &&
					translation.fromLang !== translation.toLang;
		},
		translateClientMessage: function(translation){
			var originalMsg = this.getHtmlMsg();
			if(this.useTranslation(translation)){
				fetchTranslation([originalMsg],translation.fromLang,translation.toLang)
				.fail(function(){
					alert(I("We're sorry, the translation service is not responding."));
				})
				.done(function(result){
					var rs = JSON.parse(result);
					if (rs.error) {
						alert(rs.error);
					} else {
						var msg = "<strong>Translated from " + translation.fromLangName + " to " + translation.toLangName + ":</strong><br>" +
									"<strong>"+translation.fromLangName+":</strong>" + originalMsg + "<br>" +
									"<strong>"+translation.toLangName+":</strong>" + rs.texts.join('<br>');
						this.setState({translatedMsg:msg});
					}
				}.bind(this));
			}
		},
		isJSONPayload: function(text){
			return text.lastIndexOf(JSON_ENCODED, 0) === 0;
		},
		renderFileUploadMessage: function(j){
			var html = '<a href="'+filePathPrefix()+'chat/client/download' + escape(j.fileDownload) + '">' + j.fileName +
			' <span>(' + j.sizeHuman + ')</span></a>';
			if (j.error) {
				html += '<span class="error">Error: ' + j.error + '</span>';
			}
			return html;
		},
		maybeFormat: function(text) {
			try {
				var j = JSON.parse(this.props.msg.text.replace(JSON_ENCODED, ''));
				switch(j.event) {
					case "FILE_UPLOAD":
						return this.renderFileUploadMessage(j);
				}
			} catch (e) {
				return text;
			}
		},
		render: function() {
			var hideDisplay={}
			, html = ""
			;
			if(this.props.msg.text.length == 0){
				hideDisplay={display: 'none',};
			}
			var msgId = "msg_" + this.props.msg.id;
			// If msg contains attachment, convert to plain text with url.
			if (this.isJSONPayload(this.props.msg.text)) {
				html = this.maybeFormat(this.props.msg.text);
			} else if(this.useTranslation(this.props.translation)){
				html = this.state.translatedMsg;
			} else {
				html = this.getHtmlMsg();
			}
			var shouldShowMessage = this.shouldShowMessage();
			var userName = this.getUserName();
			var className = this.getClassName();
			var isSystemMessage = this.isSystemMessage();

			return (
			<li className={className} style={hideDisplay}>
		      	<div className="msg" style={this.props.msg.preview?{background:'rgb(230, 231, 231)'}:{}}>
				<user>{userName}</user>
					{isSystemMessage?
					<SystemMessage json={this.props.msg.text}/>
					: shouldShowMessage ? <div className="content" dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(html)}}></div> : <p></p>
					}
					<time>{this.props.msg.sentHuman}</time>
					<status>{this.renderStatus()}</status>
		      	</div>
		    </li>
			);
		},
		renderStatus: function(){
			var title, color;
			if(this.props.msg.preview && (this.props.msg.text.length > 0)){
				return (<span style={{color:'rgb(255, 0, 19)',fontWeight:'bold',fontSize:'1.8em'}}><i className="fa fa-circle-o-notch fa-spin fa-fw"></i> {this.props.msg.fromClient?"user is typing...":"sending..."}</span>);
			}
			if(this.props.msg.error && this.props.msg.error != ""){
				return (<span style={{color:'rgb(255, 0, 19)',fontWeight:'bold',fontSize:'1.8em'}}><i className="fa fa-exclamation-triangle fa-2x" style={{color:'green'}}></i><Error msg={this.props.msg.error}/></span>);
			}
			if (messageIsFromMe(this.props.msg)) {
				if (this.props.client != "") {
					if (this.props.read) {
						title = I('Client has received this message.');
						color = 'green'
					} else {
						title = I('Client has not received this message.');
						color = 'lightgray';
					}
				} else {
					// Message is sent to the server. This
					// does not necessarily mean that the
					// other agents have seen the message.
					title = I('Message is sent.');
					color = 'green'
				}
				return (<i title={title} className="fa fa-check fa-2x" style={{color:color}}></i>);
			}
		},
		renderTime: function(){
			if(this.props.msg.preview){
				return
			}
		}
	});
	var MessageBoard = createReactClass({
		displayName: "MessageBoard",
		getInitialState: function() {
			return {
				chatStore: ChatStore.getState(),
				isScrollToTop: false
			};
		},
		componentDidMount: function(){
			var el = document.getElementById("MessageBoard_" + this.props.id);
			el.addEventListener('scroll', this.handleScroll);
			this.scrollMessageListToBottom(el,true);
		},
		componentDidUpdate: function(){
			if(!this.state.isScrollToTop){
				this.scrollMessageListToBottom(document.getElementById('MessageBoard_'+this.props.id),true);
			}
		},
		componentWillMount: function(){
			ChatStore.listen(this.onChange);
		},
		componentWillUnmount: function() {
			var el = document.getElementById("MessageBoard_" + this.props.id);
			el.removeEventListener('scroll', this.handleScroll);
			ChatStore.unlisten(this.onChange);
		},
		handleScroll: function(){
			var timer;
			clearTimeout(timer);
			var elem = document.getElementById("MessageBoard_" + this.props.id);
			timer = setTimeout( function(){
				if( elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
					if(typeof this.state.chatStore.chatSessions[this.props.id] !== 'undefined') {
						var dataSrc = this.state.chatStore.chatSessions[this.props.id].session;
						dataSrc.newMessage = false;
					}
					this.setState({isScrollToTop : false});
				}else{
					this.setState({isScrollToTop : true});
				}
			}.bind(this), 500 );
		},
		onChange: function(state) {
			this.setState({chatStore:state});
		},
		scrollMessageListToBottom: function( targetElement, flag ) {
			if( flag ) {
				targetElement.scrollTop = targetElement.scrollHeight;
			}
		},
		hideNewMessageIndicator: function(){
			this.scrollMessageListToBottom(document.getElementById('MessageBoard_'+this.props.id),true);
			if(typeof this.state.chatStore.chatSessions[this.props.id] !== 'undefined') {
				var dataSrc = this.state.chatStore.chatSessions[this.props.id].session;
				dataSrc.newMessage = false;
			}
			this.setState({isScrollToTop: false});
		},
		render: function() {
			var newMessage;
			if(typeof this.state.chatStore.chatSessions[this.props.id] !== 'undefined' &&
				this.state.chatStore.chatSessions[this.props.id].session) {
				newMessage = this.state.chatStore.chatSessions[this.props.id].session.newMessage;
			}
			var newMessageIndicator =	newMessage &&
										this.state.isScrollToTop ?
										this.renderStatusBox(this.props.id) : '';
			return (
				<div id={"MessageBoard_"+this.props.id} className="chatListingArea">
					<ol className="chat-board">
						{ this.renderMessages() }
					</ol>
					<div className="newMessage">
						{newMessageIndicator}
					</div>
				</div>

			);
		},
		renderStatusBox: function(chatSessionId){
			return(<StatusBox key={"tab"+chatSessionId} clickHandler={this.hideNewMessageIndicator} />);
		},
		renderMessages: function(){
			var msgs = [];
			$.each(this.props.messages, function(index, msg) {
			    msgs.push(<Message msg={msg} key={"msg"+msg.id} client={this.props.client} agent={this.props.agent} translation={this.props.translation} read={msg.read} active={this.props.active}></Message>);
			    msgs.push(<span key={msg.id} className="clearfix"></span>);
			}.bind(this));
			return msgs;
		}
	});
	var Tab = createReactClass({
		displayName: "Tab",
		getInitialState: function() {
			return {};
        },
        componentDidMount: function(){
			//when the chat popup is clicked to show from the parent page
			window.parent.$('#chatModal').on('shown.bs.modal', function() {
				this.fetchSessionData();
			}.bind(this));
			this.fetchSessionData();
		},
		fetchSessionData: function(){
			ChatActions.onSessionInit({
				args: {
					sessionId: this.props.chatSession.sessionId,
				},
				onDone: function(){
				},
				onFail: function(){
				}
			});
		},
		componentWillReceiveProps: function(nextProps){
			if(this.props.active && this.props.chatSession.isDirty){
				var self = this;
				setTimeout(function(){
					ChatActions.onActivate(self.props.chatSession.sessionId);
				});
			}
        },
		handleActivate: function(){
			if( !this.props.active ){
				this.fetchSessionData();
			}
			this.props.onActivate();
		},
		tabStatus: function(){
			var status = "";
			if(this.props.active){
				status = "active";
			}
			if(this.props.chatSession.dead) {
				status += " dead";
			} else if (this.props.chatSession.isDirty) {
				status += " dirty";
			}
			return status;
		},
		getTabTitle: function(session) {
			var withClient = this.props.chatSession.errandId > 0
			, isOwner = this.props.isOwner
			, isInvited = !isOwner && this.props.isInvited
			, text = "N/A"
			, hoverText = "N/A"
			;

			// There are four possible states, plus two for chat with pending invitation (when not owner)
			if        ( withClient &&  isOwner) {
				text = this.props.chatSession.errandId;
				hoverText = getClientEmailAddress(this.props.chatSession);
			} else if ( withClient && !isOwner) {
				if (isInvited) {
					text = I("{ERRAND_ID} (invitation)")
						.replace("{ERRAND_ID}", this.props.chatSession.errandId)
						;
					hoverText = I("You are invited to chat {ERRAND_ID}")
						.replace("{ERRAND_ID}", this.props.chatSession.errandId)
						;
				} else {
					text = "(" + this.props.chatSession.errandId + ")";
					hoverText = I("Invited chat with {CLIENT}")
						.replace("{CLIENT}", getClientEmailAddress(this.props.chatSession))
						;
				}
			} else if (!withClient &&  isOwner) {
				text = this.props.chatSession.sessionId;
				hoverText = I("You started this chat");
			} else if (!withClient && !isOwner) {
				if (isInvited) {
					text = I("{SESSION_ID} (invitation)")
						.replace("{SESSION_ID}", this.props.chatSession.sessionId)
						;
					hoverText = I("You are invited to chat {SESSION_ID}")
						.replace("{SESSION_ID}", this.props.chatSession.sessionId)
						;
				} else {
					text = "(" + this.props.chatSession.sessionId + ")";
					hoverText = I("You were invited to this chat");
				}
			} else {
				socketMan.log("FIXME we should not reach here");
			}

			return {
				hoverText: hoverText,
				text: text,
			};
		},
		getTabIcon: function(){
			switch(this.props.chatSession.channel){
				case Errand_SERVICE_CHAT:
					return this.props.chatSession.errandId?"fa fa-comments-o":"fa fa-comments";
				case Errand_SERVICE_FACEBOOK:
					return "fa fa-facebook-official";
				default:
					return "fa fa-comments-o";
			}
		},
		render: function() {
			var tabTitle = this.getTabTitle(this.props.chatSession)
			;

			return (
				<li role="presentation" className={this.tabStatus()} onClick={this.handleActivate} title={tabTitle.hoverText}>
					<a className="btn btn-default" href={"#tab_"+this.props.chatSession.sessionId} aria-controls="home" role="tab" data-toggle="tab">
						<i onClick={this.handlePinTab} className={this.getTabIcon()}></i>
						<span id={"tabLabel"+this.props.chatSession.sessionId} style={{paddingLeft:'3px',paddingRight:'5px'}}>
							{tabTitle.text}
						</span>
					</a>
				</li>
			);
		},
	});
	var ChatTab = createReactClass({
		displayName: "ChatTab",
		flagReloadParentOnClose: false,
		previewTimeout:null,
		defaultTranslation: {
			showTranslation: false,
			fromLang: 'en',
			fromLangName: 'English',
			toLang: '',
			toLangName: ''
		},
		getInitialState: function() {
			return {
				activeTab: {},
				forwardAgentList: [],
				sendingFile:false,
				chatStore: ChatStore.getState(),
				sendingMsg:false,
				tempImageAttachments: [],
				chatNotesCounter:{},
				customerNotesCounter: {},
				libraryKey: 0,
				translation: this.defaultTranslation
			};
        },
		componentWillMount: function(){
			ChatStore.listen(this.onChange);
			this.activateFirstTab();
        },
        componentDidMount: function(){
			window.parent.$('#chatModal').on('hidden.bs.modal', function() {
				if(this.flagReloadParentOnClose){
					window.parent.location.reload();
				}
			}.bind(this));
		},
        componentDidUpdate: function(prevProps,prevState){
			var sessionId = this.state.activeTab.sessionId;
			if(sessionId == NEW_AGENT_CHAT) return;
			if(typeof sessionId !== 'undefined'){
				if(!this.props.chatSessions[sessionId]){
					this.activateFirstTab();
				}
			} else {
				this.activateFirstTab();
			}
        },
		componentWillUnmount: function() {
			ChatStore.unlisten(this.onChange);
		},
		onChange: function(state) {
			this.setState({chatStore:state});
		},
		activateFirstTab: function(){
			if(typeof this.props.chatSessions && Object.keys(this.props.chatSessions).length > 0){
				var firstId = 0;
				$.each(this.props.chatSessions, function(index, chatSession) {
					firstId = chatSession.session;
					return;
				}.bind(this));
				if(firstId && firstId.sessionId > 0){
					var translation = localStorage.getItem('translation-'+firstId.sessionId);
					translation = JSON.parse(translation);
					if(!translation){
						translation = this.defaultTranslation;
					}
					this.setState({activeTab:firstId, translation:translation});
				}
			}
		},
        componentWillReceiveProps: function(nextProps){
        },
        handleActivate: function(tab){
			if((this.state.activeTab.dead == false) &&
				(this.refs.ckeditor)){
				sessionMessageMap[this.state.activeTab.sessionId] =
					this.refs.ckeditor.getData();
			}
			var translation = localStorage.getItem('translation-'+tab.sessionId);
			translation = JSON.parse(translation);
			if(!translation){
				translation = this.defaultTranslation;
			}
			this.setState({activeTab:tab,translation:translation});
        },
		stripLeadingAndTrailingBlock: function(msg){
			var emptyLine = '<div>\xa0</div>';
			while(msg.indexOf(emptyLine) == 0){
				msg = msg.replace(emptyLine,'');
				msg = msg.trim();
			}
			msg = msg.replace(/\n/g,'');
			while(msg.lastIndexOf(emptyLine) != -1){
				if((msg.lastIndexOf(emptyLine)+ emptyLine.length) ==
					msg.length){
					msg = msg.slice(0, -emptyLine.length);
				} else break
			}
			msg = msg.replace(/<div[^>]*>/g,'');
			msg = msg.replace(/<\/div>/g,'<br>');
			var lastSubString = msg.substring(msg.length-'<br>'.length,
				msg.length);
			if(lastSubString == '<br>'){
				msg = msg.substring(0, msg.length-'<br>'.length);
			}
			return msg;
		},
		getTemplatesForArea: function(areaId){
			for (var i = 0; i < initialChatData.chatAreas.length; i++){
				if(initialChatData.chatAreas[i].Id == areaId){
					return initialChatData.chatAreas[i].Templates;
				}
			}
			return [];
		},
		processTemplates: function(originalMessage,session){
			var newMsg = originalMessage;
			if(session && session.area && session.area.hasTemplate){
				var areaTemplates = this.getTemplatesForArea(session.area.id);
				if(typeof areaTemplates !== 'undefined'){
					for(var i=0;i<areaTemplates.length;i++){
						newMsg = newMsg.replace( new RegExp('(\\['+areaTemplates[i].Name+'\\])','g'),areaTemplates[i].Content)
					}
				}
			}
			return newMsg;
		},
		processImages: function(originalMessage,session){
			var that = this;
			var fileIds = that.state.tempImageAttachments.map(function(o){
				return o.id;
			});
			var fileNames = that.state.tempImageAttachments.map(function(o){
				return o.value;
			});
			this.setState({tempImageAttachments:[]});
			var fd = new FormData();
			fd.append( 'fileIds', fileIds.join(','));
			fd.append( 'fileNames', fileNames.join(','));
			fd.append( 'session', session.sessionId);
			return $.ajax({
				url: webserverRoot + "chat/moveTempAttachment",
				data: fd,
				processData: false,
				contentType: false,
				type: 'POST',
			});
		},
		removeImageFromCKE: function(imgId){
			this.refs.ckeditor.removeImage('#imgPreviewLink-'+imgId);
		},
		fetchDetect: function( tc ){
			var translatioServer = window.location.protocol + "//" +window.location.hostname + "/Cention/";
			var translateUrl = translatioServer + "translate/detect";
			var ts = {
						text: tc
					};
			return $.ajax(translateUrl, {
					type: "POST",
					data: JSON.stringify(ts),
					contentType: "application/json",
				});
		},
		getErrandSubject: function(msg){
			if (msg.length > 0) {
				return msg[0].text;
			}
			return I("No Subject");
		},
		getClientSampleMessages: function(){
			//get last 3 sample message text from client for detecting its langguage
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				var chat = this.props.chatSessions[sessionId];
				if (chat.session && chat.session.chat.length > 0) {
					var sampleMsg = "";
					var sampleCount = 0;
					for(var i=chat.session.chat.length-1;i>=0;i--){
						if(sampleCount >= 3 ){break;}
						if(chat.session.chat[i].fromClient){
							sampleMsg += "<br>" + chat.session.chat[i].text;
							sampleCount++;
						}
					}
					if(sampleCount > 0){
						return sampleMsg;
					}
				}
			}
			return "";
		},
		showHideTranslation: function(){
			var sessionId = this.state.activeTab.sessionId;
			if(this.state.translation.showTranslation){
				var newTr = addonUpdate(this.state.translation,{
					showTranslation:{$set:false}
				});
				localStorage.setItem('translation-'+sessionId,JSON.stringify(newTr));
				this.setState({translation:newTr});
				return;
			}
			var strTranslationContent = this.getClientSampleMessages();
			if(strTranslationContent == ""){
				return;
			}
			/*** fetect toLang from babeld ***/
			var detectedLang = '';
			var rs = {};
			this.fetchDetect(strTranslationContent)
			.fail(function(result){
				alert(I("We're sorry, the translation service is not responding."));
			}.bind(this))
			.done(function(result){
				rs = JSON.parse(result);
				if (rs.error) {
					alert(rs.error);
					return;
				}
				detectedLang = rs.text;
				var newTr = addonUpdate(this.state.translation,{
					showTranslation:{$set:!this.state.translation.showTranslation}
				});
				newTr = addonUpdate(newTr,{
					fromLang:{$set:detectedLang}
				});
				localStorage.setItem('translation-'+sessionId,JSON.stringify(newTr));
				this.setState({translation:newTr});
			}.bind(this));
		},
		hasValidImgSrcTag: function(htmlText){
			var tagStart = htmlText.indexOf("<img ");
			var tagEnd = htmlText.indexOf(">");
			if(tagStart<0){
				return false;
			}
			var subStr = htmlText.substring(tagStart);
			var tagEnd = subStr.indexOf(">");
			if((tagStart < 0) ||(tagEnd < tagStart)){
				return false;
			}
			var srcTag = subStr.indexOf(" src=");
			var ckeSrcTag = subStr.indexOf(" data-cke-saved-src=");
			if((srcTag > tagStart && srcTag < tagEnd) ||
				(ckeSrcTag > tagStart && srcTag < tagEnd)){
				return true;
			}
			return false;
		},
		canSend: function(){
			if(this.refs.ckeditor){
				var a = this.refs.ckeditor.getPlainText();
				a = a.replace(ckePlaceholderText,'');
				a = a.replace(/\s/g,'');
				a = a.replace(/(\r\n|\n|\r)/gm,'');
				a = a.replace(/[\u21B5|\u000A]/g, ""); //remove this guy '↵'
				a = a.replace(new RegExp('<div></div>','g'),'');
				if(a == ""){
					var b = this.refs.ckeditor.getData();
					b = b.replace(/\s/g,'');
					b = b.replace(new RegExp('&nbsp;', 'g'),'');
					b = b.replace(new RegExp('<div></div>','g'),'');
					return b != ""
				}
				return a != "";
			}
			return false;
		},
		shouldTranslate: function(){
			return this.props.feature['chat.translation'] && this.state.translation.showTranslation && this.state.translation.fromLang != "" && this.state.translation.toLang != "";
		},
		getTranslation: function(){
			return {
				shouldTranslate: this.shouldTranslate(),
				toLang: this.state.translation.toLang,
				toLangName: this.state.translation.toLangName,
				fromLang: this.state.translation.fromLang,
				fromLangName: this.state.translation.fromLangName,
			};
		},
      	handleSend: function(){
			if(this.state.sendingMsg){
				return;
			}
			var ckedata = "";
			if(this.refs.ckeditor) {
				ckedata = this.refs.ckeditor.getData();
			}
			if(this.canSend()){
            	var sessionId = this.state.activeTab.sessionId;
            	if(typeof sessionId !== 'undefined'){
            	    if(this.props.chatSessions[sessionId] != null){
						if(this.props.chatSessions[sessionId].session.dead ==
							true){
							sessionMessageMap[this.state.activeTab.sessionId]
								= '';
							this.refs.ckeditor.setData('');
							return;
						}
            	    }
            	}
				this.setState({sendingMsg:true});
				var msgToSend = this.processTemplates(ckedata, this.props.chatSessions[sessionId].session);
				msgToSend = this.stripLeadingAndTrailingBlock(msgToSend);
				var doSend = function(msg){
					var sendMsg = function(m){
						socketMan.sendMessage(
								this.state.activeTab.agent,
								this.state.activeTab.sessionId,
								this.state.activeTab.errandId,
								m
							);
						sessionMessageMap[this.state.activeTab.sessionId] = '';
						var libAttachment = sessionsAttachmentMap[this.state.activeTab.sessionId];
						if(typeof libAttachment !== 'undefined' &&
							libAttachment.length > 0){
							libAttachment.forEach(function(attachment){
								socketMan.emitActionWithCallback('add attachment',{sessionId:this.state.activeTab.sessionId,file:attachment},function(m){
									if(m.error) {
										console.log("failed:",m.error);
									}else {
										var msg = '<div class="attachment">' +
										'<a href=' + filePathPrefix() + m.file.download + '>' + m.file.value + '<br /><span class="badge">' + m.file.sizeHuman + '</span></a>' +
										'</div>';

										socketMan.sendMessage(
											this.state.activeTab.agent,
											this.state.activeTab.sessionId,
											this.state.activeTab.errandId,
											msg
										);
									}
								}.bind(this));
							}.bind(this));
							sessionsAttachmentMap[this.state.activeTab.sessionId] = [];
							this.setState({libraryKey: Math.random()});
						}
						this.setState({sendingMsg:false});
					}.bind(this);

					if(this.state.tempImageAttachments.length <= 0){
						sendMsg(msg);
						return;
					}

					var sessionSecret = this.props.chatSessions[sessionId].sessionSecret;
					this.processImages(msg,this.props.chatSessions[sessionId].session)
					.fail(function(result){
						console.log("failed",result);
						this.setState({sendingMsg:false});
						return;
					}.bind(this))
					.done(function(result){
						var jqhtml = $.parseHTML('<div>'+msg+'</div>');
						var html = $(jqhtml);
						result.data.forEach(function(tempImages){
							if(tempImages.status){
								html.find('#imgPreviewLink-'+tempImages.id).attr('href', filePathPrefix()+'view' + escape(tempImages.download)+'?t='+sessionSecret);
								html.find('img#'+tempImages.id).attr('src', filePathPrefix()+'view' + escape(tempImages.download)+'?t='+sessionSecret);
								html.find('img#'+tempImages.id).css('max-width',"200px");
								html.find('img#'+tempImages.id).css('max-height',"200px");
							} else {
								html.find('#imgPreviewContainer-'+tempImages.id).remove();
							}
						});
						sendMsg(html.html());
					}.bind(this));
				}.bind(this);

				if(this.shouldTranslate() ) {
					fetchTranslation([msgToSend],this.state.translation.toLang,this.state.translation.fromLang)
					.fail(function(){
						alert(I("We're sorry, the translation service is not responding."));
						doSend(msgToSend);
					})
					.done(function(result){
						var rs = JSON.parse(result);
						if (rs.error) {
							alert(rs.error);
							doSend(msgToSend);
						} else {
							var msg = "<strong>Translated from " + this.state.translation.toLangName + " to " + this.state.translation.fromLangName + ":</strong><br>" +
									  "<strong>"+this.state.translation.toLangName+":</strong>" + msgToSend + "<br>" +
									  "<strong>"+this.state.translation.fromLangName+":</strong>" + rs.texts.join('<br>');
							doSend(msg);
						}
					}.bind(this));
				} else {
					doSend(msgToSend);
				}
			}
		},
		generateSelectedTags: function(allTags, errandTags){
			var tagsShouldSelected = [];
			if (!Array.isArray(errandTags) || !Array.isArray(allTags)) {
				return tagsShouldSelected;
			}
			errandTags.forEach(function(tagIds) {
				var tags = [];
				var found = tagIds.every(function(tagId) {
					for (var i = 0; i < allTags.length; i++) {
						var areaTag = allTags[i];
						if(areaTag != null) {
							if (tagId.tagId == areaTag.tagId) {
								tags.push(areaTag);
								return true;
							}
							if (Array.isArray(areaTag.children)) {
								for (var j = 0; j < areaTag.children.length; j++) {
									var level2Tag = areaTag.children[j];
									if (tagId.tagId == level2Tag.tagId) {
										tags.push(level2Tag);
										return true;
									}
									if (Array.isArray(level2Tag.children)) {
										for (var k = 0; k < level2Tag.children.length; k++) {
											var level3Tag = level2Tag.children[k];
											if (tagId.tagId == level3Tag.tagId) {
												tags.push(level3Tag);
												return true;
											}
										}
									}
								}
							}
						}
					}
					return false;
				});
				if (found) {
					tagsShouldSelected.push(tags);
				}
			});
			return tagsShouldSelected;
		},
		handleTag: function(){
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				var chat = this.props.chatSessions[sessionId];
				if (chat.tags && chat.tags.length > 0) {
					var selectedTags = this.generateSelectedTags(chat.tags, chat.selectedTags);
					this.showTagModal(false,chat.tags,selectedTags,sessionId);
				}
			}
		},
		handleChatToggle: function(){
			$.post('/socket/agent.api/acceptchat',
				{acceptChat:!this.state.chatStore.acceptChat})
			.then(function(data) {

			}.bind(this));
		},
		loadModalComponent: function(BootstrapModal,modalContent,oContent){
			var defaultWidth = "620px";
			if(oContent.customDialogWidth){
				defaultWidth = oContent.customDialogWidth;
			}
			var isDraggable;
			if(oContent.isDraggable || oContent.isDraggable == undefined){
				isDraggable = true;
			}else{
				isDraggable = false;
			}
			var modalId = "chat_action_modal";
			if(oContent.modalId){
				modalId = oContent.modalId;
			}
			return createReactClass({
				render: function() {
					return(
						<BootstrapModal
						key={(new Date()).getTime()}
						id= {modalId}
						ref= {modalId}
						width = {defaultWidth}
						height = "auto"
						actions = {oContent.actions}
						xPosition = {oContent.x+"px"}
						yPosition = {oContent.y+"px"}
						draggableHandle = ".modal-header, .modal-body, .modal-footer"
						isDraggable = {isDraggable}
						title={oContent.title}>
							<div id="chat_action_modal_content" className="wfDialogContents">
								{modalContent}
							</div>
						</BootstrapModal>
					);
				}
			});
		},
		renderContactCard: function(ErrandNotes,oContent){
			var sessionId = this.state.activeTab.sessionId;
			var chat = this.props.chatSessions[sessionId];
			return (
				<div>
					<Feature tag="agent.connect-address-to-clients">
						<div id="dialogConnectionList">
							<ContactCard
								errandInfo={{
									fromAddress: chat.session.clientEmail,
									service: 3,
									fromName: chat.session.client,
								}}
                popup={true}
							/>
						</div>
					</Feature>
					<Feature tag="notes.client">
						<div>
							<h4>{I("Customer notes")}</h4>
							<ErrandNotes
								id="CustomerNotesBoxContent"
								type="client"
								errandId={chat.session.errandId}
								fields={{id:"id",note:"note",writer:"username",writeTime:"writtenDate"}}
								uploadAttachmentRoute= "errand/notes/uploadAttachment"
								notesCounterUpdated={function(counter){
									console.log("notesCounterUpdated",counter);
									var sessionId = this.state.activeTab.sessionId;
									if(typeof sessionId !== 'undefined'){
										if(counter != this.state.customerNotesCounter[sessionId]){
											var newCustomerNotesCounter = addonUpdate(this.state.customerNotesCounter,{
												$apply: function(customerNotesCounter){
													customerNotesCounter[sessionId] = counter;
													return customerNotesCounter;
												}
											});

											this.setState({customerNotesCounter:newCustomerNotesCounter});
										}
									}
								}.bind(this)}
								onLoadNotes={function(){
									return $.get('/Cention/web/errand/clientnotes/' + chat.session.errandId);
								}}
								/>
						</div>
					</Feature>
				</div>
				)
		},
		handleContactCard: function(){
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				var oContent = {};
				oContent.title = I("Contact card");
				oContent.actions = [];
				ReactDOM.render(
					React.createElement(
								this.loadModalComponent(BootstrapModal,this.renderContactCard(ErrandNotes,oContent), oContent)),
					document.getElementById('ChatActionsTagging')
				);
				$('#chat_action_modal').modal();
			}
		},
		handleErrandThreads: function( selectedItems ){
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				var items = [];
				for(var i=0;i<selectedItems.length;i++){
					items.push(selectedItems[i].id);
				}
				setTimeout(function(){
					ChatActions.onAssociateErrand({sessionId:sessionId,associatedErrands:items});
				});
			}
		},
		handleNotes: function(){
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				var chat = this.props.chatSessions[sessionId];
				var oContent = {};
				oContent.customDialogWidth = "100%";
				oContent.isDraggable = true;
				oContent.title = I("Chat notes");
				oContent.actions = [];
				var ErrandTags = ReactDOM.render(
					React.createElement(
								this.loadModalComponent(
											BootstrapModal,
											<ErrandNotes
												id="ChatNotesBoxContent"
												type="errand"
												errandId={chat.session.errandId}
												fields={{id:"id",note:"note",writer:"username",writeTime:"writtenDate"}}
												uploadAttachmentRoute="errand/notes/uploadAttachment"
												notesCounterUpdated={function(counter){
													var sessionId = this.state.activeTab.sessionId;
													if(typeof sessionId !== 'undefined'){
														if(counter != this.state.chatNotesCounter[sessionId]){
															var newChatNotesCounter = addonUpdate(this.state.chatNotesCounter,{
																$apply: function(chatNotesCounter){
																	chatNotesCounter[sessionId] = counter;
																	return chatNotesCounter;
																}
															});

															this.setState({chatNotesCounter:newChatNotesCounter});
														}
													}
												}.bind(this)}
												onLoadNotes={function(){
													return $.get('/Cention/web/errand/notes/' + chat.session.errandId);
												}} />,
											oContent),
								null),
					document.getElementById('ChatActionsTagging')
				);
				$('#chat_action_modal').modal();
			}
		},
		handleChooseTemplates: function(session){
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				var chat = this.props.chatSessions[sessionId];
				var oContent = {};
				oContent.title = I("Show templates");
				oContent.actions = [];
				var Templates = ReactDOM.render(
					React.createElement(
								this.loadModalComponent(
											BootstrapModal,
											<TemplateList
												id="ChatChooseTemplates"
												templates={this.getTemplatesForArea(session.area.id)}
												area={session.area.id}
												onAdd={function(template){
													this.refs.ckeditor.setData(this.refs.ckeditor.getData() + '[' + template.Name + ']');
												}.bind(this)}
											/>,
											oContent),
								null),
					document.getElementById('ChatActionsTagging')
				);
				$('#chat_action_modal').modal();
			}
		},
		handleCloseChat: function(session){
			var sessionId
			, chat
			, hasErrand = false
			, isOwner = false
			;
			if(typeof session.sessionId === 'undefined'){
				sessionId = this.state.activeTab.sessionId;
			} else {
				sessionId = session.sessionId;
			}
			if(typeof sessionId !== 'undefined'){
				chat = this.props.chatSessions[sessionId]
				if (chat && chat.session) {
					if (chat.session.errandId > 0) {
						hasErrand = true;
					}
					if (chat.session.ownerId == initialChatData.agentID) {
						isOwner = true;
					}
				}
				if(isOwner && hasErrand){
					var selectedTags = [];
					if (chat.tags && chat.tags.length > 0) {
						selectedTags = this.generateSelectedTags(chat.tags, chat.errandTags);
					}
					if (chat.selectedTags) {
						selectedTags = chat.selectedTags;
					}
					if(selectedTags && selectedTags.length <= 0){
						if(this.props.feature['chat.forced-tag']) {
							if (chat.tags && chat.tags.length > 0) {
								this.showTagList(sessionId);
							} else {
								socketMan.finishSession(sessionId);
							}
						} else if (this.props.feature['chat.optional-tag']) {
							this.showConfirmTagList(sessionId);
						} else {
							socketMan.finishSession(sessionId);
							this.activateFirstTab();
						}
					} else {
						socketMan.finishSession(sessionId);
						this.activateFirstTab();
					}
				} else {
						socketMan.finishSession(sessionId);
						this.activateFirstTab();
				}
				if((Object.keys(this.props.chatSessions).length == 1) &&
					chat){
					if(initialChatData.externalChat.type == "solidus") {
						$.get(webserverRoot + 'chat/externalchatend',
							{acceptChat:false});
					}
				}
				delete sessionMessageMap[sessionId];
				delete sessionsAttachmentMap[sessionId];
				localStorage.removeItem('translation-'+sessionId);
			}
		},
		showTagList: function(sessionId){
			var chat = this.props.chatSessions[sessionId];
			if (!chat.tags || chat.tags.length == 0) {
				alert(I("Error: There are no tags associated with the area."));
				return;
			}
			var selectedTags = this.generateSelectedTags(chat.tags, chat.errandTags);
			this.showTagModal(true,chat.tags,selectedTags,sessionId);
		},
		showConfirmTagList: function(sessionId) {
			var chat = this.props.chatSessions[sessionId];
			if (chat.tags && chat.tags.length > 0) {
				var answer = window.confirm(I('Are you sure you want to finish the chat without choosing a tag?'));
				if(answer) {
					socketMan.finishSession(sessionId);
					this.activateFirstTab();
				} else {
					this.showTagList(sessionId);
				}
			} else {
				socketMan.finishSession(sessionId);
				this.activateFirstTab();
			}
		},
		showTagModal: function(forClose, tags, selectedTags, sessionId) {
			var confirmButtonText = I("Save");
			if(forClose) {
                confirmButtonText = I("Confirm");
            }
            ReactDOM.unmountComponentAtNode(document.getElementById("ChatActionsTagging"));
			this.chatTagging = ReactDOM.render(React.createElement(TagItemList, {
				id: "chatTagClose",
                selectedItems: selectedTags != null ? selectedTags : [],
				items : tags,
				mustSelectMinimum: this.props.feature['chat.forced-tag']? 1 : 0,
				confirmButtonText: confirmButtonText,
				cancelButtonText: I("Cancel"),
                classificationTitle: I("You have not chosen a classification for selected errands."),
				classificationMessage: I("You can navigate the list by pressing the up and down arrows and select a classification by pressing space."),
				onConfirm: function(selectedTag, selectedTagsGrouped) {
					socketMan.addTags(sessionId, selectedTag, selectedTagsGrouped, doCloseModal);
				}.bind(this)
			}), document.getElementById('ChatActionsTagging'));
			var doCloseModal = function(state){
				this.chatTagging.setState({error: state.error});
				if (state.error) {
					return;
				}

				if(state.canCloseChat && forClose) {
					socketMan.finishSession(sessionId);
					this.activateFirstTab();
				}
				this.chatTagging.refs.modal.close();
			}.bind(this);
			this.chatTagging.refs.modal.open();
		},
		handleNewMessage: function(e){
			if(this.refs.ckeditor){
				sessionMessageMap[this.state.activeTab.sessionId] = this.refs.ckeditor.getData();
			}
		},
		getImageSize: function(url){
			var img = new Image();
			img.src = url;
			return img;
		},
		getImageStyle: function(dataVal){
			var style = "style=\"max-width:200px;max-height:200px\" ";
			var valIndex = dataVal.indexOf(" src=\"http");
			if(valIndex != -1){
				var start = valIndex + " src=\"".length;
				var end = dataVal.indexOf("\"",start);
				var subStr = dataVal.substring(start,end);
				var img = this.getImageSize(subStr);
				style = "style=\"max-width:" + img.width + "px;" +
						"max-height:" + img.height + "px\" ";
			}
			return style;
		},
		handlePaste: function(e){
			if(e.data.type == "html"){
				var dataVal = e.data.dataValue;
				if(dataVal.indexOf('<img src="') == 0){
					var defStyle = "style=\"max-width:200px;"+
									"max-height:200px\" ";
					var style = this.getImageStyle(dataVal);
					if(style.length == 0){
						style = defStyle;
					}
					var srcPos = dataVal.indexOf("src=\"")
					dataVal = [dataVal.slice(0, srcPos), style,
						dataVal.slice(srcPos)].join('');
					e.data.dataValue = dataVal;
				}
			}
		},
		handleDragnDropFiles: function( event ){
			var sessionId = this.state.activeTab.sessionId;
			var chat = this.props.chatSessions[sessionId];
			if (chat && chat.session && chat.session.dead) {
				return;
			}
			if( typeof this.props.feature['chat.max-image-size'] !== 'undefined' &&
				this.props.feature['chat.max-image-size'] > 0 &&
				this.props.feature['chat.max-image-size'] < event.data.size){
				socketMan.log("image size " + event.data.size +
				" exceeded maximum configured " + this.props.feature['chat.max-image-size']);
				return;
			}
			var fd = new FormData();
			var boundary = Math.floor(Math.random() * 6)+ ''+ 1 +''+ Math.floor(''+new Date() / 1000);
			var fileNameOnly = event.data.name;
			fd.append( 'uploadfile', event.data );
			fd.append( 'random', boundary);
			fd.append( 'session', sessionId);
			fd.append( 'sessionSecret', chat.sessionSecret);
			$.ajax({
				url: webserverRoot + "chat/uploadTempAttachment",
				data: fd,
				processData: false,
				contentType: false,
				type: 'POST',
				success: function( rd ){
					var newTempImageAttachments = addonUpdate(this.state.tempImageAttachments,{
						$push: [rd]
					});

					var html = "<div class=\"imgPreviewContainer\">" +
						"<a id=\"imgPreviewLink-" + rd.id + "\" " +
							"class=\"imgPreviewLink\" " +
							"data-lightbox=\"imgPreviewLink" + rd.id + "\" "+
							"href=\""+ filePathPrefix()+'view' + escape(rd.download) +'?t='+chat.sessionSecret+"\" >" +
						"<img src=\"" + filePathPrefix()+'view' + escape(rd.download) +'?t='+chat.sessionSecret+ "\" " +
						"id=" + rd.id + " " +
						" alt='' style=\"max-width:200px;max-height:200px\" />" +
						"</a>" +
						"</div>";
					var that = this;
					setTimeout(function(){
						that.refs.ckeditor.appendImage(html);
					},1000);
					that.setState({tempImageAttachments:newTempImageAttachments});
				}.bind(this)
			});
		},
		getTagCount: function(){
			var selectedTags = [];
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				var chat = this.props.chatSessions[sessionId];
				if (!chat) {
					return 0;
				}
				if (chat.tags && chat.tags.length > 0) {
					selectedTags = this.generateSelectedTags(chat.tags, chat.errandTags);
				}
				if (chat.selectedTags) {
					selectedTags = chat.selectedTags;
				}
			}
			return selectedTags.length;
		},
		getCustomerNotesCount: function(){
			var counter = 0;
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				counter = this.state.customerNotesCounter[sessionId];
				if (!counter) {
					var chat = this.props.chatSessions[sessionId];
					if(chat && chat.session){
						return chat.customerNotesCounter;
					}
					return 0;
				}
			}
			return counter;
		},
		getChatNotesCount: function(){
			var counter = 0;
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				counter = this.state.chatNotesCounter[sessionId];
				if (!counter) {
					var chat = this.props.chatSessions[sessionId];
					if(chat && chat.session){
						return chat.chatNotesCounter;
					}
					return 0;
				}
			}
			return counter;
		},
		sendAgentPreview: function(){
			var s = this.refs.ckeditor.getData();
			if(s != ""){
				var msgToSend = this.stripLeadingAndTrailingBlock(s);
				var sendIt = true;
				if((msgToSend.charAt(0) == '\xa0') &&
					(msgToSend.length <= 6)){
					sendIt = false;
				}
				if(sendIt){
					var msgLength = 0;
					if((msgToSend.length == 1) &&
						(msgToSend.charCodeAt(0) == 32)){
						msgLength = 0;
					} else{
						msgLength = msgToSend.length;
					}
				socketMan.emitAction('agent preview',
					{sessionId:this.state.activeTab.sessionId,
					 messageLen: msgLength
					});
				}
			}
		},
		currentKeyBindings: [],
		setupKeyBinding: function(answers) {
			var findShortcuts = function(list) {
				for (var i = 0; i < list.length; i++) {
					var item = list[i];
					switch (item.type) {
						case 'library':
						case 'category':
							if (item.list) {
								findShortcuts(item.list);
							}
							break;
						case 'question':
							if (item.keyboardShortcut) {
								var key = item.keyboardShortcut.charCodeAt(0);
								this.currentKeyBindings[key] = item;
							}
					}
				}
			}.bind(this);
			this.currentKeyBindings = {};
			findShortcuts(answers);
		},
		handleTextAreaKeyDown: function(e){
			var key = e.data.keyCode;
			clearTimeout(this.previewTimeout);
			if(this.state.sendingMsg){
				return;
			}
			if(key == 13) {
				window.setTimeout(function() {
					this.handleSend();
				}.bind(this),0);
				e.cancel(); // avoid newline to be sent out
				return;
			} else if(this.currentKeyBindings[e.data.domEvent.$.keyCode]) {
				var answer = this.currentKeyBindings[e.data.domEvent.$.keyCode];
				if(answer.keyboardShortcutUseCtrl == e.data.domEvent.$.ctrlKey &&
					answer.keyboardShortcutUseAlt == e.data.domEvent.$.altKey &&
					answer.keyboardShortcutUseShift == e.data.domEvent.$.shiftKey &&
					answer.keyboardShortcutUseMeta == e.data.domEvent.$.metaKey)
				{
					window.setTimeout(function() {
						this.handleAppendAnswer(answer);
					}.bind(this),0);
					e.cancel();
					return;
				}
			}
			this.previewTimeout = setTimeout(this.sendAgentPreview, 400);
		},
		handleTextAreaKeyUp: function(e) {},
		handleInsertAnswer:function(newAnswer){
			if(this.props.feature['chat.attachment']) {
				var att = this.mergeLibraryAttachments(newAnswer.attachments,[]);
				sessionsAttachmentMap[this.state.activeTab.sessionId] = att;
				this.setState({libraryKey: Math.random()});
			}
			this.refs.ckeditor.setData(newAnswer.answer);
		},
		handleAppendAnswer:function(newAnswer){
			if(this.props.feature['chat.attachment']) {
				var libAttachment  = sessionsAttachmentMap[this.state.activeTab.sessionId];
				if(typeof libAttachment === 'undefined'){
					libAttachment = [];
				}
				var att = this.mergeLibraryAttachments(newAnswer.attachments,addonUpdate(libAttachment,{}));
				if(att.length > libAttachment.length){
					sessionsAttachmentMap[this.state.activeTab.sessionId] = att;
					this.setState({libraryKey: Math.random()});
				}
			}
			this.refs.ckeditor.setData(this.refs.ckeditor.getData() + ' ' + newAnswer.answer);
		},
		mergeLibraryAttachments: function(attachments,initialArr){
			if(attachments && attachments.length > 0){
				attachments.map(function(attachment){
					if(!this.isDuplicateAttachment(attachment,initialArr)){
						initialArr.push({
							uniqueId: attachment.id,
							id: Math.floor(Math.random() * 6)+ ''+ 1 +''+ Math.floor(''+new Date() / 1000),
							download: attachment.url,
							value: attachment.name,
							estSize: attachment.size,
							fromLibrary: true,
						});
					}
				}.bind(this));
			}
			return initialArr;
		},
		isDuplicateAttachment: function(attachment,initialArr){
			var isDuplicate = false;
			initialArr.forEach(function(item){
				if(item.uniqueId == attachment.id){
					isDuplicate = true;
				}
			});
			return isDuplicate;
		},
		renderContactCardIcon: function(){
			var count = this.getCustomerNotesCount();
			var showCounter = function(){
				if(count > 0){
					return (<span className="badge-notify">{count}</span>);
				}
			}.bind(this);
			if(this.props.feature["agent.connect-address-to-clients"] || this.props.feature["notes.client"]){
				var style = {};
				if(this.props.feature["agent.connect-address-to-clients"]){
					style.backgroundColor = '#337ab7';
				}
				return (
						<button type="button" className="btn btn-default" style={style} onClick={this.handleContactCard}>
							<i className="fa fa-user"></i>
							{showCounter()}
						</button>
				);
			}
		},
		renderNotesIcon: function(){
			if(this.props.feature["notes.errand"]){
				var count = this.getChatNotesCount();
				var showCounter = function(){
					if(count > 0){
						return (<span className="badge-notify">{count}</span>);
					}
				};
				return (
					<div style={{'display':'inline-block'}}>
						<button type="button" className="btn btn-default" onClick={this.handleNotes}>
							<i className="fa fa-bars"></i>
							{showCounter()}
						</button>
					</div>
				);
			}
		},
		handleCKResize: function(event){
		},
		handleOpenExternal: function(session){
			var email = session.clientEmail;
			var url = session.area.externalSystemURL.replace('[EMAIL]', session.clientEmail);
			this.openExternal(url,session.user.openExternalSystem);
		},
		handleOpenExternalArray: function(session){
			var email = session.clientEmail;
			var howToOpen = session.user.openExternalSystem;
			for (var i = 0; i < session.area.externalSystemURLArray.length; i++){
				var url = session.area.externalSystemURLArray[i].replace('[EMAIL]', email);
				this.openExternal(url,session.user.openExternalSystem);
			}
		},
		openExternal: function(url,howToOpen){
			//FS4468
			//howToOpen = 2 is auto open in tab
			//howToOpen = 3 is auto open in window
            if(url.toLowerCase().indexOf("http://") == -1 && url.toLowerCase().indexOf("https://")  == -1) {
                url = 'http://' + url
            }
            var external = (howToOpen == 0 || howToOpen == 2
				? window.open(url, '_blank')
				: window.open(url, 'external-system', 'scrollbars=yes,menubar=yes,toolbar=yes,width=1024,height=768'));
			if( external )
				external.focus();
		},
		getAreaFileArchive: function(areaId){
			for (var i = 0; i < initialChatData.chatAreas.length; i++){
				if(initialChatData.chatAreas[i].Id == areaId){
					return initialChatData.chatAreas[i].FileArchive;
				}
			}
			return [];
		},
		renderCkeditor: function (){
			var sessionId = this.state.activeTab.sessionId
			, chat = this.props.chatSessions[sessionId]
			, isReadOnly
			, withClient = false;
			var langs = (this.state.chatStore.languageSrc ?
				this.state.chatStore.languageSrc : []);
			var defaultText = "";
			var areaFileArchives = [];
			if (chat && chat.session) {
				withClient = !!chat.session.area;
				isReadOnly = chat.session.dead;
				if(isReadOnly == false){
					if (sessionMessageMap[sessionId]){
						defaultText =
							sessionMessageMap[sessionId];
					}
				}
				if(chat.session.area && typeof chat.session.area !== 'undefined') {
					areaFileArchives = this.getAreaFileArchive(chat.session.area.id);
				}
			}
			return(
				<Ckeditor
					id ={"ckeditor"}
					ref ={"ckeditor"}
					defaultFontFamily={this.props.feature['rich-text.default-font-family']}
					defaultFontSize={this.props.feature['rich-text.default-font-size']}
					defaultContent={defaultText}
					spellLanguages={langs}
					suggestBoxScrollBar={{overflowY:true, maxHeight:"100px"}}
					placeHolderText={ckePlaceholderText}
					simpleChatToobar={withClient}
					agentGroupChatToolbar={!withClient}
					resize_dir='horizontal'
					imageMaxSize={this.props.feature['chat.max-image-size']}
					imageResize={{maxWidth:200,maxHeight:200}}
					onKeydown={this.handleTextAreaKeyDown}
					onChange={this.handleNewMessage}
					onResize={this.handleCKResize}
					onDragnDropFiles={this.handleDragnDropFiles}
					onPaste={this.handlePaste}
					onKeyUp={this.handleTextAreaKeyUp}
					isReadOnly={isReadOnly}
					readOnlyOnUnmount={false}
					setHeight='80'
					fileArchiveImages={areaFileArchives}
					wrapImageInContainer={true}
					callbackParent={this.onChildChanged} />
			);
		},
		onChildChanged: function(newState){
			this.setState({ showActionButton: newState });
		},
		sendChatHistory: function(sessionId) {
			socketMan.sendChatHistory(sessionId, function(ack) {
				if (ack.error) {
					alert("Error: " + ack.error);
					return;
				}
				switch (ack.status) {
					case 'OK':
						// do nothing
						break;
					case 'ALREADY_SET':
						alert(I("The chat history will be emailed to the client."));
						break;
				}
			});
		},
		answerInvite: function(sessionId, accept) {
			$.ajax({
				url: "/socket/agent.api/answerinvite",
				data: {
					"session": sessionId,
					"accept": accept,
				},
				type: "GET"
			}).done(function(msg) {
				if (msg.error) {
					alert("Accept invitation error: " + msg.error);
					return;
				}
				if (msg.isStale) {
					ChatActions.onFinishSession(msg);
				}
			}).fail(function(e) {
				alert(I("An error occurred while answering the invitation, please try again"));
			});
		},
		getAttachments: function(){
			var all = addonUpdate(this.state.tempImageAttachments,{});
			if(!all || all.length <= 0 ){
				all = [];
			}
			if(sessionsAttachmentMap[this.state.activeTab.sessionId] &&
				sessionsAttachmentMap[this.state.activeTab.sessionId].length > 0){
				all = all.concat(sessionsAttachmentMap[this.state.activeTab.sessionId]);
			}
			return all;
		},
		render: function(){
			var sessionId = this.state.activeTab.sessionId
			, withClient = false
			, bootstrapCols = 12
			, inputCols = bootstrapCols
			, content = []
			, isChatTab = sessionId != NEW_AGENT_CHAT
			, rightSideWidgets
			, chat
			, translateButton
			, sendChatHistoryButton
			, isOwner = false
			, isInvited = false
			, acceptInvite = function() { this.answerInvite(sessionId, true); }.bind(this)
			, rejectInvite = function() { this.answerInvite(sessionId, false); }.bind(this)
			, dead = false
			, buttons
			;
			if(typeof sessionId !== 'undefined'){
				chat = this.props.chatSessions[sessionId];
				if (chat) {
					withClient = !!chat.session.area;
					inputCols = (withClient?8:bootstrapCols);
					isOwner = chat.session.ownerId == initialChatData.agentID;
					isInvited = !isOwner && chat.session.isInvited;
					dead = chat.session.dead;
				}
			}

			if (isChatTab) {
				rightSideWidgets= <li className="pull-right chatComponentsButton">
					{this.renderChatToggle() }
					{ withClient && this.renderContactCardIcon() }
					{ withClient && this.renderNotesIcon() }
					{ withClient && isOwner && this.renderTagButton() }
					{ withClient && this.renderClientPaths() }
					{ withClient && this.renderErrandThread() }
				</li>
			}

			content.push(
				<div key="body" className="panel-body">
					<ul className="nav nav-tabs chatTabs" role="tablist">
						{this.renderTabs()}
						{rightSideWidgets}
					</ul>
					<div className="tab-content">
					{ this.renderTabContent() }
					</div>
				</div>
			);

			if (isChatTab) {
				if (withClient) {
					if (this.props.feature['chat.translation']){
						translateButton = <i
							className={"fa fa-globe fa-2x er-translate " +(this.state.translation.showTranslation?"active":"")}
							title={I("Translate")}
							data-toggle="tooltip"
							onClick={this.showHideTranslation}
							style={{cursor:'pointer'}}>
						</i>
					}

					if (isOwner && this.props.feature['chat.optional-history-send-to-client'] && !this.props.feature['chat.history-send-to-client']) {
						sendChatHistoryButton = <button
							className={"btn btn-primary btn-sm"}
							type="button"
							onClick={function() {
								this.sendChatHistory(sessionId)
							}.bind(this)}>
								{I("Send history")}
						</button>
					}
				}

				if (isInvited) {
					if (dead) {
						buttons = <button className="btn btn-primary btn-sm" type="button" onClick={rejectInvite}>{I("Close")}</button>
					} else {
						buttons = <div className="row">
							<button className={"btn btn-primary btn-sm"} type="button" onClick={acceptInvite}>Join</button>
							<button className={"btn btn-primary btn-sm"} type="button" onClick={rejectInvite}>Reject</button>
						</div>
					}
					content.push(<div key="footer" className="panel-footer chatFooter">
						{buttons}
					</div>);
				} else {
					content.push(<div key="footer" className="panel-footer chatFooter">
						<div className="row">
							<div className={"col-sm-"+inputCols+" chatInputTextArea"}>
								<div className="chatListingArea">
									{this.renderCkeditor()}
									<span className="clearfix"></span>
								</div>
								<AttachmentsContainer
									key={this.state.libraryKey}
									attachments={this.getAttachments()}
									webRoot={webserverRoot + 'download'}
									onRemove={function(item){
										var pos = -1;
										for(var i=0;i<this.state.tempImageAttachments.length;i++){
											if(this.state.tempImageAttachments[i].id == item.id){
												pos = i;
											}
										}
										if(pos >= 0){
											//remove from server
											$.post( webserverRoot + "chat/removeTempAttachment", {fid: item.id});
											//remove the image from cke
											this.removeImageFromCKE(item.id);
											//remove from attachment list
											var newTempImageAttachments = addonUpdate(this.state.tempImageAttachments,{
												$splice: [[pos,1]]
											});
											this.setState({tempImageAttachments:newTempImageAttachments});
											return;
										}
										var libAttachments = sessionsAttachmentMap[this.state.activeTab.sessionId];
										if(libAttachments){
											for(var i=0;i<libAttachments.length;i++){
												if(libAttachments[i].id == item.id){
													pos = i;
												}
											}
											if(pos >= 0){
												var newTempLibraryAttachments = addonUpdate(libAttachments,{
													$splice: [[pos,1]]
												});
												sessionsAttachmentMap[this.state.activeTab.sessionId] = newTempLibraryAttachments;
												this.setState({libraryKey: Math.random()});
											}
										}
									}.bind(this)}
								/>
							</div>
							{withClient?
							<div className={"col-sm-"+(bootstrapCols-inputCols)+" chatSearchResultsArea"}>
								<SearchAnswer defaultLibrary={chat.session.area.libraryId} onAppendAnswer={this.handleAppendAnswer} onInsertAnswer={this.handleInsertAnswer} onLoadEnd={this.setupKeyBinding} activeSessionId={sessionId}/>
							</div>
							: null
							}
						</div>
						<div className="row">
							<div className={this.state.showActionButton ? "col-sm-8 chatActionButtons" : "hidden"}>
								<button className={"btn btn-primary btn-sm" + (this.state.sendingMsg || dead?" disabled":"")} type="button" onClick={this.handleSend}>{this.state.sendingMsg?I("Sending..."):I("Send")}</button>
								{this.renderSendFileButton(sessionId,dead)}
								{withClient?this.renderOpenExternalSystemButton(chat):null}
								{withClient?this.renderChooseTemplate(chat,dead):null}
								{sendChatHistoryButton}

								<button className="btn btn-primary btn-sm" type="button" onClick={this.handleCloseChat}>{I("Close")}</button>

								<span className="align-right">
									{translateButton}
									{withClient?this.renderTranslator():null}
								</span>
							</div>
							{withClient?this.renderForwarding():null}
						</div>
					</div>
					);
				}
			}

			return (<div className="panel panel-default">
						{content}
						<div id="dialogErrandHistoryPopup"></div>
					</div>
			);
		},
		renderClientPaths: function(){
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				var chat = this.props.chatSessions[sessionId];
				if(chat && chat.session){
					return <VisitorPath chatSession={chat.session} />
				}
			}
		},
		renderErrandThread: function(){
			var sessionId = this.state.activeTab.sessionId;
			if(typeof sessionId !== 'undefined'){
				var chat = this.props.chatSessions[sessionId];
				if(chat && chat.session){
					var areaname =""
					if(typeof chat.session.area !== 'undefined'){
						areaname = chat.session.area.name;
					}
				    return (
						<ErrandThread
							errand={
								{
									id:chat.session.errandId,
									data:{
										fromAddress:chat.session.clientEmail,
										attachments:"",
										subject:this.getErrandSubject(chat.session.chat),
										body:this.getClientSampleMessages(),
										displayId: chat.session.errandId,
										serviceName: "Chat",
										areaName: areaname,
										date: chat.session.startedHuman,
										from: chat.session.client
									}
								}
							}
							onSelectErrand={this.handleErrandThreads}
							visible={true}
							groupErrandsByThread={this.props.feature['errand.group-by-thread']}
							clientContactDetails={chat.acquiredRelatedErrands}
							relatedErrands={chat.acquiredRelatedErrands?chat.acquiredRelatedErrands.acquired:[]}
							openedByExternal={""}
							onAcquired={
								function(id){
									ChatActions.acquireErrand({sessionId:this.state.activeTab.sessionId,errand:id});
									this.flagReloadParentOnClose = true;
								}.bind(this)
							}
							context= "chat"
						/>
					);
				}
			}
		},
		renderSendFileButton: function(sessionId,dead){
			if(this.props.feature['chat.attachment']) {
				return (
					<FileUploader
						title={this.state.sendingFile?I("Sending File"):I("Send File")}
						className={"btn btn-primary btn-sm btn-file btn-fileupload" + (dead?" disabled":"")}
						uploadTo={"chat/uploadAttachment"}
						formData={{session:sessionId}}
						maxFileAllowed={this.props.feature['chat.max-attachment-size']}
						onDeleteUpload={this.handleCancelAttachement}
						onProgress={function(value){
							this.setState({sendingFile:value});
						}.bind(this)}
						onFileupload={function(attachment){
							this.setState({sendingFile:false});
							socketMan.emitActionWithCallback('add attachment',{sessionId:this.state.activeTab.sessionId,file:attachment},function(m){
								var msg = JSON_ENCODED + JSON.stringify({
									"event": "FILE_UPLOAD",
									"fileDownload": m.file.download,
									"fileName": m.file.value,
									"sizeHuman": m.file.sizeHuman,
									"error": m.error
								});
								socketMan.sendMessage(
										this.state.activeTab.agent,
										this.state.activeTab.sessionId,
										this.state.activeTab.errandId,
										msg
									);
							}.bind(this));
						}.bind(this)}
					/>
				);
			}
		},
		renderOpenExternalSystemButton: function(chat){
			var session = chat.session;
			//NOTE: from old code in Chat.feh and Chat.js
			//FS4585 : for chat we disable auto open for URLS
			//FS4468 : allow to use an array of url for external
			if (session.area.externalSystemURLArray !== 'undefined' &&
				session.area.externalSystemURLArray &&
				session.area.externalSystemURLArray.length > 0) {
				return (<button className="btn btn-primary btn-sm" type="button" onClick={this.handleOpenExternalArray.bind(null,session)}>{I("Open External System")}</button>);
			}
		},
		renderChooseTemplate: function(chat,dead){
			if(chat && chat.session && typeof chat.session.area !== 'undefined'
				&& chat.session.area && chat.session.area.hasTemplate){
				return (
					<button
					className={"btn btn-primary btn-sm" + (dead?" disabled":"")}
					type="button"
					onClick={this.handleChooseTemplates.bind(null,chat.session)}>{I("Choose templates")}</button>
				);
			}
		},
		blockIP: function(session){
			var answer = window.confirm(I('Are you sure you want to block the IP ({IP})?').replace('{IP}',session.ipaddress));
			if(answer) {
				socketMan.emitAction('block ip',{
					ip: session.ipaddress,
					errand: session.errandId,
					email: session.clientEmail,
					sessionId: session.sessionId
				});
				this.handleCloseChat(session);
			}
		},
		forwardSession: function(sessionId, to, id) {
			$.ajax({
				url: "/socket/agent.api/forward",
				data: {
					"session": sessionId,
					"to": to,
					"id": id
				},
				type: "GET"
			}).done(function(msg) {
				if (msg.successful) {
					if (msg.isStale) {
						ChatActions.onFinishSession(msg);
					}
				} else {
					if (msg.error) {
						if (msg.error == 'ERR_NIL_AGENT') {
							alert(I('No agents available'));
						} else if (msg.error == 'ERR_INTEGRATION') {
							alert(I('Unsupported by integration'));
						} else {
							alert(I("Forward error: {ERROR}").replace('{ERROR}',  msg.error));
						}
					} else {
						alert(I('No agents available'));
					}
				}
			}).fail(function(e) {
				alert(I("An error occurred while forwarding, please try again"));
			});
		},
		renderForwarding: function(){
			var sessionId = this.state.activeTab.sessionId
			, that = this
			;
			var showForwardToArea = function(currentAreaId){
				if(this.props.feature['chat.forward-area']){
					return <DropDown
						id="forwardToArea"
						fixedTitle={I("Forward To Area")}
						class="dropup pull-right"
						selectedItems={""}
						items={this.state.forwardAreaList}
						fields={{id:"Id",name:"Name"}}
						multiSelect={false}
						onLoad={function(){
							$.ajax({
								url: "/socket/agent.api/getAreas",
								type: "GET",
								data: {
									exclude: currentAreaId,
									sid: sessionId
								}
							})
							.done(function(msg) {
								that.setState({forwardAreaList:msg});
							}).fail(function(e) {
								alert(I("Forward to area: An error occurred while retrieving the area list."));
								that.setState({forwardAreaList:[]});
							});
						}}
						onChange={function(selectedAreaId){
							var areaId = parseInt(selectedAreaId,10);
							that.forwardSession(sessionId, 'area', areaId);
						}}
					/>
				}
			}.bind(this);
			if(typeof sessionId !== 'undefined'){
				var chat = this.props.chatSessions[sessionId];
				if (!chat) {
					return null;
				}
				if(chat.session.ownerId == initialChatData.agentID){
					var noAgentsAvailable = {id:'0',value:I('No agents available')};
					return (
						<div className="col-sm-4">
							<DropDown
							id="forwardToAgent"
							fixedTitle={I("Forward To Agent")}
							class="dropup pull-right"
							selectedItems={""}
							items={this.state.forwardAgentList}
							fields={{id:"id",name:"value"}}
							multiSelect={false}
							onLoad={function(){
								$.ajax({
									url: "/socket/agent.api/getAgents?session="+sessionId,
									type: "GET"
								})
								.done(function(msg) {
									if(msg.length <= 0){
										this.setState({forwardAgentList:[noAgentsAvailable]});
									} else {
										this.setState({forwardAgentList:msg});
									}
								}.bind(this)).fail(function(e) {
									this.setState({forwardAgentList:[noAgentsAvailable]});
								}.bind(this));
							}.bind(this)}
							onChange={function(selectedAgentId){
								var agentId = parseInt(selectedAgentId,10);
								if(agentId > 0){
									that.forwardSession(sessionId, 'agent', agentId);
								}
							}}
							/>
							{ showForwardToArea(chat.session.area?chat.session.area.id:0) }
						</div>
					);
				}
			}
		},
		renderTranslator:function(){
			var sessionId = this.state.activeTab.sessionId;
			if(!this.state.translation.showTranslation){
				return (<div></div>);
			}
			return (
				<ErrandTranslation
					shouldVisible= {this.state.translation.showTranslation}
					targetContents= {[]}
					id= "ChatTranslation_"
					translationResult= "TranslationResultBox"
					contextType= "chat"
					fromLang= {this.state.translation.fromLang}
					toLang= {this.state.translation.toLang}
					onToLangChange= {function(newToLang,newToLangName){
						var newTr = addonUpdate(this.state.translation,{
							toLang:{$set:newToLang}
						});
						newTr = addonUpdate(newTr,{
							toLangName:{$set:newToLangName}
						});
						localStorage.setItem('translation-'+sessionId,JSON.stringify(newTr));
						this.setState({translation:newTr});
					}.bind(this)}
					onFromLangChange= {function(newFromLang,newFromLangName){
						var newTr = addonUpdate(this.state.translation,{
							fromLang:{$set:newFromLang}
						});
						newTr = addonUpdate(newTr,{
							fromLangName:{$set:newFromLangName}
						});
						localStorage.setItem('translation-'+sessionId,JSON.stringify(newTr));
						this.setState({translation:newTr});
					}.bind(this)} />
			);
		},
		renderTagButton: function(){
			var tagCount = this.getTagCount();
			var showBadge = function(){
				if(tagCount>0){
					return (<span className="badge-notify">{tagCount}</span>);
				}
			};
			return(
				<div style={{'display':'inline-block'}}>
					<button type="button" className="btn btn-default" onClick={this.handleTag}><i className="fa fa-tags"></i></button>
					{showBadge()}
				</div>
			);
		},
		renderChatToggle: function(){
			if((initialChatData.hideGlobalChat == true) &&
				(initialChatData.externalChat.type == "normal")){
				return;
			}
			return(
				<i>
					{I('Chat')}
					<label className="switch">
						<input type="checkbox" className="switch-input"
								onChange={this.handleChatToggle}
								checked={this.state.chatStore.acceptChat}  />
						<span className="switch-label" data-on="On" data-off="Off"></span>
						<span className="switch-handle"></span>
					</label>
				</i>
			);
		},
		renderTabs: function(){
			var that = this
			, tabs = []
			, sessionIds = []
			, firstTab
			;

			$.each(this.props.chatSessions, function(index, chatSession) {
				sessionIds.push(index);
			});

			sessionIds.sort(function(a, b) {
				var ca = that.props.chatSessions[a].session
				, cb = that.props.chatSessions[b].session
				;

				if (ca.errandId === 0 && cb.errandId == 0) {
					return ca.sessionId - cb.sessionId;
				} else if (ca.errandId === 0 && cb.errandId !== 0) {
					return -1;
				} else if (ca.errandId !== 0 && cb.errandId === 0) {
					return +1;
				}
				return ca.sessionId - cb.sessionId;
			});

			switch (this.props.connectStatus) {
				case "connecting":
					firstTab = <li
						key="connectStatus"
						role="presentation"
						title={I("Connecting...")}
					>
						<i className="fa fa-circle-o-notch fa-spin" style={{ color: 'red' }}></i>
					</li>
					break;
				case "connected":
					if (this.props.feature['chat.group-chat-with-agent']) {
						firstTab = <li
							key="startchat"
							role="presentation"
							onClick={this.handleActivate.bind(null,{sessionId: NEW_AGENT_CHAT})}
						>
							<a href="#"
								title={I("Start an agent-to-agent chat")}>
								<i className="fa fa-plus"></i>
							</a>
						</li>;
					}
					break;
			}

			if (firstTab) {
				tabs.push(firstTab);
			}

			$.each(sessionIds, function(index, sessionId) {
				var chatSession = this.props.chatSessions[sessionId]
				if(chatSession.session){
					tabs.push(
						<Tab
							chatSession={chatSession.session}
							key={"tab"+chatSession.session.sessionId}
							active={this.state.activeTab.sessionId == chatSession.session.sessionId}
							onActivate={this.handleActivate.bind(null,chatSession.session)}
							isOwner={chatSession.session.ownerId == initialChatData.agentID}
							isInvited={chatSession.session.isInvited}
						>
						</Tab>);
			    }
			}.bind(this));
			return tabs;
		},
		renderTabContent: function(){
			var tabs = []
			, isChatTab = this.state.activeTab.sessionId != NEW_AGENT_CHAT
			;
			tabs.push(<div role="tabpanel" className={isChatTab?"tab-pane":"tab-pane active"} key={"tab_0"}>
				<NewAgentToAgentChat
					agentPresence={this.props.agentPresence}
					context="newChat"
					noToggleLink={true}
					tabVisible={!isChatTab}
					connectStatus={this.props.connectStatus}
				/>
			</div>)
			$.each(this.props.chatSessions, function(index, chatSession) {
				if(chatSession.session){
					tabs.push(
						<TabContent
							agentPresence={this.props.agentPresence}
							chatSession={chatSession.session}
							chatNotification={chatSession.notification}
							key={"tabcontent"+chatSession.session.sessionId}
							active={this.state.activeTab.sessionId == chatSession.session.sessionId}
							feature={this.props.feature}
							connectStatus={this.props.connectStatus}
							onBlockIP={this.blockIP.bind(null,chatSession.session)}
							translation={this.getTranslation()}
						/>);
				}
			}.bind(this));
			return tabs;

		}
	});

	var SearchAnswer = createReactClass({
		getInitialState:function(){
			return {libraries:[],selectedLibrary:"",searching:false,searchValue:"",forceTreeReload:false}
		},
		componentWillMount: function(){
			$.get( webserverRoot + 'errand/library/list', {external:false})
				.done(function(data) {
					this.setState({libraries:data.list,selectedLibrary:''+(this.props.defaultLibrary ? this.props.defaultLibrary : '')});
				}.bind(this));
		},
		handleChangeLibrary: function(newLibrary){
			this.setState({selectedLibrary:newLibrary});
		},
		handleSearchChange: function(e){
			this.setState({searchValue:e.target.value});
		},
		handleSearch: function(e){
			this.setState({searching:true,'forceTreeReload':true});
		},
		handleSearchKeyDown: function(e){
			if(e.keyCode == 13){
				this.handleSearch(e)
			}
		},
		handleTreeLoadEnd: function(answers) {
			this.setState({searching:false,'forceTreeReload':false});
			if (this.props.onLoadEnd) {
				this.props.onLoadEnd(answers);
			}
		},
		render: function() {
			return(
				<div className="panel panel-default">
					<div className="panel-heading" style={{padding: '0px',paddingRight: '25px'}}>
						<div className="input-group input-group-xs">
							<input
								type="text"
								className="form-control"
								placeholder={I("Search for...")}
								value={this.state.searchValue}
								style={{lineHeight: '20px',height: '26px'}}
								onKeyDown={this.handleSearchKeyDown}
								onChange={this.handleSearchChange}
							/>
							<div className="input-group-btn">
								<DropDown
									id="library"
									class="dropup"
									selectedItems={this.state.selectedLibrary}
									items={this.state.libraries}
									fields={{id:"id",name:"name"}}
									multiSelect={false}
									wantSelectAll={false}
									wantSelectNone={false}
									wantShowDone={false}
									wantDeselect={false}
									onChange={this.handleChangeLibrary}
								/>
								{this.renderSearchButton()}
							</div>
						</div>
					</div>
					<div className="panel-body" style={{height: '90px',overflowY: 'auto',padding: '0',backgroundColor:'#fff'}}>
						<LibraryTree
							library={this.state.selectedLibrary}
							search={this.state.searchValue}
							forceReload={this.state.forceTreeReload}
							showAppend={true}
							showInsert={true}
							showChatShortcut={true}
							previewPosition="left"
							previewMaxHeight="350"
							handleInsert={this.props.onInsertAnswer}
							handleAppend={this.props.onAppendAnswer}
							onLoadEnd={this.handleTreeLoadEnd}
							activeSessionId={this.props.activeSessionId}
						/>
					</div>
				</div>
			);
		},
		renderSearchButton: function(){
			if(this.state.searching){
				return (
					<a className="btn btn-warning btn-sm" onClick={this.handleSearch}>
						<i id="searchIcon" className="fa fa-circle-o-notch fa-spin" style={{transform: 'none'}}></i> {I('Searching...')}
					</a>
				);
			} else {
				return (
					<a className="btn btn-warning btn-sm" onClick={this.handleSearch}>
						<i id="searchIcon" className="fa fa-search" style={{transform: 'none'}}></i> {I('Search')}
					</a>
				);
			}
		}
	});

	function matchAny(patterns, text) {
		var chunks = text.trim().split(/\s+/)
		, i
		, j
		;
		if (!patterns || patterns.length == 0) {
			return true;
		}
		for(i=0; i<patterns.length;i++) {
			for(j=0; j<chunks.length; j++) {
				if (chunks[j].indexOf(patterns[i]) !== -1) {
					return true;
				}
			}
		}
		return false;
	}

	var Agent = createReactClass({
		displayName: "Agent",
		render: function() {
			var props = this.props
			, agent = getAgent(props.agentId)
			, displayName = agentNameAndChatName(agent)
			, newlyAdded
			, addRemoveText
			, addRemoveTitle
			, removeOrAdd
			, showAddRemoveLink = agent.id != initialChatData.agentID && agent.id != props.ownerId
			;

			if (!matchAny(props.filter, displayName.toLowerCase())) {
				return null;
			}

			switch (props.context) {
				case "participants":
					showAddRemoveLink = false;
					break;
				case "candidates":
					addRemoveText = I("Add");
					addRemoveTitle = "";
					removeOrAdd = function() {
						props.addAgent(agent);
					}
					break;
				case "added":
					// We're rendering agents that are already
					// added or about to be added to an existing
					// chat
					newlyAdded = !props.alreadyAdded[agent.id];
					if (newlyAdded) {
						addRemoveText = I("Joining");
						addRemoveTitle = I("new to this chat");
					} else {
						if (this.props.invited) {
							addRemoveText = I("Uninvite");
						} else {
							addRemoveText = I("Remove");
						}
						addRemoveTitle = I("already added");
					}
					removeOrAdd = function() {
						props.removeAgent(agent);
					}
					break;
				default:
					throw new Error("handle case "+props.context);
			}

			var addRemoveLink;
			if (showAddRemoveLink) {
				addRemoveLink = <button
					href="#"
					title={addRemoveTitle}
					onClick={removeOrAdd}
					className="pull-right btn btn-primary btn-xs"
				>
					{addRemoveText}
				</button>
			}

			var attr = this.getAttribute(agent);
			return <span
				className="agentPresence"
				title={attr.title}
				style={{width:'100%', 'display':'inline-block', 'marginBottom':'4px'}}
			>
				<span className={attr.klass}>
				</span> <label style={{"fontWeight":"initial"}}>{displayName}</label>
				{addRemoveLink}
			</span>
		},
		getAttribute: function(agent) {
			var acceptChat = agent.acceptChat;
			if (this.props.agentPresence) {
				agent = this.props.agentPresence
			}

			var attr = {};
			if (agent.online && agent.acceptChat) {
				attr.title = I("Accepts chat");
				attr.klass = "acceptChat fa fa-check-circle-o";
			} else if (agent.online && !agent.acceptChat) {
				attr.title = I("Does not accept chat");
				attr.klass = "onlineNoChat fa fa-circle-o";
			} else {
				attr.title = I("Offline");
				attr.klass = "fa fa-circle-o";
			}

			return attr;
		},
	});

	var AgentList = createReactClass({
		getInitialState: function() {
			var i
			, agentIds = this.props.agentIds
			, alreadyAdded = {}
			, agent
			;

			for (var i=0; i<agentIds.length; i++) {
				agent = getAgent(agentIds[i]);
				if (!agent) {
					continue;
				}
				alreadyAdded[agentIds[i]] = agent;
			}

			return {
				selectedAreaId: {},
				alreadyAdded: alreadyAdded,
				mutate: {
					toRemoveMap: {},
					toAddMap: {},
				},
				filterArea: [],
				filterAgent: [],
				filterAddedAgent: [],
			};
		},
		addAgent: function(agent) {
			delete this.state.mutate.toRemoveMap[agent.id];
			this.state.mutate.toAddMap[agent.id] = agent;
			this.setState({
				mutate: {
					toRemoveMap: this.state.mutate.toRemoveMap,
					toAddMap: this.state.mutate.toAddMap,
				}
			});
		},
		addAgents: function(area) {
			var state = this.state;
			$.each(area.agents, function(i, agent) {
				delete state.mutate.toRemoveMap[agent.id];
				state.mutate.toAddMap[agent.id] = agent;
			});
			this.setState({
				mutate: {
					toRemoveMap: state.mutate.toRemoveMap,
					toAddMap: this.state.mutate.toAddMap,
				}
			});
		},
		removeAgent: function(agent) {
			this.state.mutate.toRemoveMap[agent.id] = agent;
			delete this.state.mutate.toAddMap[agent.id];
			this.setState({
				mutate: {
					toRemoveMap: this.state.mutate.toRemoveMap,
					toAddMap: this.state.mutate.toAddMap,
				}
			});
		},
		agentIsAdded: function (agent) {
			if (this.state.alreadyAdded[agent.id]) {
				if (!this.state.mutate.toRemoveMap[agent.id]) {
					return true;
				}
			}
			if (this.state.mutate.toAddMap[agent.id]) {
				return true;
			}
			return false;
		},
		agentSorter: function(a, b) {
			var aname = agentNameAndChatName(a)
			, bname = agentNameAndChatName(b)
			;

			if (aname < bname) {
				return -1;
			}
			if (aname > bname) {
				return 1;
			}
			return 0;
		},
		collectCandidates: function (areas, filter) {
			var seen = {}
			, ret = []
			, state = this.state
			;

			if (!filter) {
				return ret;
			}

			$.each(areas, function(i, area) {
				$.each(area.agents, function(i, agent) {
					if (state.alreadyAdded[agent.id]) {
						if (!state.mutate.toRemoveMap[agent.id]) {
							return;
						}
					}
					if (state.mutate.toAddMap[agent.id]) {
						return;
					}
					if (filter(area, agent)) {
						if (seen[agent.id]) {
							return;
						}
						seen[agent.id] = true;

						ret.push(agent);
					}
				});
			});
			return ret;
		},
		matchChosen: function (area) {
			return this.state.selectedAreaId[area.id];
		},
		matchArea: function (area) {
			return matchAny(this.state.filterArea, area.name.toLowerCase());
		},
		matchAgent: function (agent) {
			return matchAny(this.state.filterAgent, agentNameAndChatName(agent).toLowerCase());
		},
		matchAddedAgentFilter: function(agent) {
			return matchAny(this.state.filterAddedAgent, agentNameAndChatName(agent).toLowerCase());
		},
		isMutated: function() {
			var pristine = {}
			, toRemove = this.state.mutate.toRemoveMap
			, toAdd = this.state.mutate.toAddMap
			, k
			;

			$.each(this.props.agentIds, function(i, id) {
				pristine[id] = true;
			});

			for (var k in toRemove) {
				if (!toRemove.hasOwnProperty(k)) { continue; }
				if (pristine[toRemove[k].id]) {
					return true;
				}
			}

			for (var k in toAdd) {
				if (!toAdd.hasOwnProperty(k)) { continue; }
				if (!pristine[toAdd[k].id]) {
					return true;
				}
			}

			return false;
		},
		countOtherAgents: function(area) {
			var n = 0;
			if (!area.agents) {
				return 0;
			}
			area.agents.map(function(agent) {
				if (agent.id == initialChatData.agentID) {
					return;
				}
				n++;
			});
			return n;
		},
		render: function() {
			var props = this.props
			, state = this.state
			, areas = props.areas
			, agentIds = props.agentIds
			, that = this
			, isSelected = function(id) { return state.selectedAreaId[id]; }
			, i
			, agent
			, hasAreaSelected = false
			, hasAreaFilter = state.filterArea.length > 0
			, hasAgentFilter = state.filterAgent.length > 0
			, areaItems = []
			, testScroll = urlParams['chat.testscroll'] // If true add phony areas/agents for testing scrolling of areas/agents list
			;

			areas.map(function(area) {
				var areaName = shortenText(area.name,30);
				var style = {}
				, changeArea = function(e) {
					state.selectedAreaId[area.id] = $(e.target).is(':checked')
					that.setState({selectedAreaId: state.selectedAreaId})
				}
				, addAll = function() {
					that.addAgents(area);
				}
				;

				if (that.countOtherAgents(area) == 0) {
					return null;;
				}

				if (state.selectedAreaId[area.id]) {
					hasAreaSelected = true;
				}

				if (!matchAny(state.filterArea, area.name.toLowerCase())) {
					return null;
				}

				if (isSelected(area.id)) {
					style.fontWeight = 'bold';
				} else {
					style.fontWeight = 'initial';
				}

				var cid=props.id+"_area_ckbox_"+area.id;
				areaItems.push(<li key={props.id+"_area_select_"+area.id}>
					<input
						type="checkbox"
						id={cid}
						onChange={changeArea}
						style={{marginRight: 5}}
						checked={state.selectedAreaId[area.id]}
					/>
					<label htmlFor={cid} style={style} title={area.name}>{areaName}</label>
					<button onClick={addAll} className="btn btn-primary btn-xs pull-right">{I("Add")}</button>
				</li>);
			});

			if (testScroll) for (var i=1; i<=30; i++) {
				areaItems.push(<li key={props.id+"_area_select_phony_"+i}>
					{"Phony Area-" + i}
				</li>);
			}

			var areaList = <ul style={bareListStyle}>
				{areaItems}
			</ul>

			var matcher;
			/*
			 * We have three parameters that control how the agent list candidates are shown:
			 *
			 * 1. Chosen areas (checkbox checked)
			 * 2. Filtered areas
			 * 3. Filtered agents
			 *
			 * These are all binary states (chosen or not, filtered
			 * or not) hence we have 8 possible states which are
			 * handled below:
			 */
			if (hasAreaSelected) {
				if       (!hasAreaFilter && !hasAgentFilter) {
					// Show all agents from chosen areas
					matcher = function(area, agent) {
						return that.matchChosen(area);
					};
				} else if( hasAreaFilter && !hasAgentFilter) {
					// Show agents from filtered chosen areas
					matcher = function(area, agent) {
						return that.matchChosen(area) && that.matchArea(area);
					};
				} else if(!hasAreaFilter &&  hasAgentFilter) {
					// Show filtered agents from chosen areas
					matcher = function(area, agent) {
						return that.matchChosen(area) && that.matchAgent(agent);
					};
				} else if( hasAreaFilter &&  hasAgentFilter) {
					// Show filtered agents from filtered chosen area
					matcher = function(area, agent) {
						return that.matchChosen(area) && that.matchArea(area) && that.matchAgent(agent);
					};
				} else {
					throw new Error("FIXME we should not reach here");
				}
			} else {
				// No areas chosen
				if       (!hasAreaFilter && !hasAgentFilter) {
					// Do nothing
				} else if( hasAreaFilter && !hasAgentFilter) {
					// Do nothing - area already filtered in areaList above
				} else if(!hasAreaFilter &&  hasAgentFilter) {
					// Show filtered agents from all areas
					matcher = function(area, agent) {
						return that.matchAgent(agent);
					};
				} else if( hasAreaFilter &&  hasAgentFilter) {
					// Show agents from filtered areas
					matcher = function(area, agent) {
						return that.matchArea(area) && that.matchAgent(agent);
					};
				} else {
					throw new Error("FIXME we should not reach here");
				}
			}

			var candidates = this.collectCandidates(areas, matcher);

			// Render candidates
			var agentItems = [];
			var agentList;
			if (candidates.length > 0) {
				candidates.sort(this.agentSorter);
				candidates.map(function(agent){
					if (agent.id == initialChatData.agentID) {
						return null;
					}
					agentItems.push(<li key={props.id+"_candidate_agent_"+agent.id}>
						<Agent
							removeAgent={that.removeAgent}
							addAgent={that.addAgent}
							context='candidates'
							agentId={agent.id}
							ownerId={props.ownerId}
							agentPresence={props.agentPresence[agent.id]}
							filter={state.filterAgent}
						/>
					</li>);
				})

				if (testScroll) for(i=1;i<=30; i++) {
					agentItems.push(<li key={props.id+"_candidate_agent_phony_"+i}>
						{"Phony Agent-" + i}
					</li>);
				}
				agentList = <ul style={bareListStyle}>
					{agentItems}
				</ul>
			}

			// Render agents that are already added or to be invited
			var agents = {};
			$.each(agentIds, function(i, agentId) {
				if (state.mutate.toRemoveMap[agentId]) {
					// This is an agent that is about to be removed.
					return;
				}
				agent = getAgent(agentId);
				if (!agent) {
					// this should not happen
					return;
				}
				agents[agent.id] = agent;
			});
			$.each(state.mutate.toAddMap, function(id, agent) {
				agents[agent.id] = agent;
			});

			var addedAgents = [];
			$.each(agents, function(id, agent) {
				addedAgents.push(agent);
			});
			addedAgents.sort(this.agentSorter);

			var addedAgentItems = [];
			addedAgents.map(function(agent) {
				if (state.filterAddedAgent.length > 0) {
					if (!matchAny(state.filterAddedAgent, agentNameAndChatName(agent).toLowerCase())) {
						return null;
					}
				}

				addedAgentItems.push(<li key={props.id+"_added_agent_"+agent.id}>
					<Agent
						removeAgent={that.removeAgent}
						addAgent={that.addAgent}
						context='added'
						agentId={agent.id}
						ownerId={props.ownerId}
						agentPresence={props.agentPresence[agent.id]}
						filter={[]}
						alreadyAdded={state.alreadyAdded}
					/>
				</li>);
			})

			if (testScroll) for (var i=1; i<=30; i++) {
				addedAgentItems.push(<li key={props.id+"_added_agent_foo_"+i}>
					{"Added agent "+i}
				</li>);
			}
			var addedAgentList = <ul style={bareListStyle}>
				{addedAgentItems}
			</ul>

			var buttonStyle = { margin: 5 };


			var cancelButton;
			if (this.props.context == "existingChat" || this.props.context == "frontPage") {
				cancelButton = <button type="button" style={buttonStyle} onClick={props.toggleShowAddAgent} className="pull-right">{I('Cancel')}</button>
			}

			var createdChatSession;
			if (this.state.createdChatSessionId) {
				if (!this.props.tabVisible) {
					// Stop showing createdChatSession when user switches to other tab
					this.state.createdChatSessionId = 0;
				} else {
					createdChatSession = <span className="pull-right">{I('Chat %$SESSION_ID$ created').replace('$SESSION_ID$', this.state.createdChatSessionId)}</span>;
				}
			}

			var maxHeight = 200;
			switch (this.props.context) {
				case "frontPage":
					maxHeight = 400;
					break;
				case "newChat":
					maxHeight = 400;
					break;
				case "existingChat":
					maxHeight = 200;
					break;
				default:
					socketMan.log("unhandled context", this.props.context);

			}
			var scrollableStyle = {
				maxHeight: maxHeight,
				overflow: 'auto',
			};

			return <div className="addAgentsTab" style={{'border':'1 px solid '}}>

					<div className="row">
						<div className="col-sm-6 col-md-4" style={{'paddingTop':'15px', 'borderRight':'1px solid #EFEFEF'}}>
							<div className="thumbnail" style={{'border':0}}>
								<div className="caption" style={{'background':'#AEAEAF'}}>
									<p>{I("Filter Area")}</p>
									<input type="text" placeholder={I("area name...")} onKeyUp={this.filterArea}/>
								</div>
								<div className="caption" style={scrollableStyle}>
									{areaList}
								</div>
							</div>
						</div>

						<div className="col-sm-6 col-md-4" style={{'paddingTop':'15px'}}>
							<div className="thumbnail" style={{'border':0}}>
								<div className="caption" style={{'background':'#AEAEAF'}}>
									<p>{I("Filter Agent")}</p>
									<input type="text" placeholder={I("type agent name...")} onKeyUp={this.filterAgent}/>
								</div>
								<div className="caption" style={scrollableStyle}>
									{agentList}
								</div>
							</div>
						</div>

						<div className="col-sm-6 col-md-4" style={{'paddingTop':'15px', 'borderLeft':'1px solid #EFEFEF'}}>
							<div className="thumbnail" style={{'border':0}}>
								<div className="caption" style={{'background':'#AEAEAF'}}>
									<p>{I("Selected agents")}</p>
									<input type="text" placeholder={I("find agent...")} onKeyUp={this.filterAddedAgent}/>
								</div>
								<div className="caption" style={scrollableStyle}>
									{addedAgentList}
								</div>
                <div className="group-chat-action">
                  {cancelButton}
                  <button type="button" style={buttonStyle} onClick={this.doAddAgent} className="pull-right" disabled={this.props.connectStatus === "connecting" || !this.isMutated()}>
                    {this.props.context == "existingChat"? I('OK'): I('Start chat')}
                  </button>
                </div>
							</div>
            </div>
						<div className="col-md-12">
							{ this.props.connectStatus === "connecting" ?
							<Error className="pull-right" msg={I("Connecting, please wait ...")}/>
							: this.props.dead?
							<Error className="pull-right" msg={I("This chat session has ended.")}/>
							: this.props.error?
							<Error className="pull-right" msg={this.props.error}/>
							:""
							}
							{createdChatSession}
              </div>
					</div>
			</div>
		},
		filterAgent: function(e) {
			// TODO DRY-out these trimming businesses
			var v = e.target.value;
			if (v.length > 0) {
				v = v.trim()
				if (v.length == 0) return;
			}
			this.setState({filterAgent: toFilterChunks(v)});
		},
		filterAddedAgent: function(e) {
			var v = e.target.value;
			if (v.length > 0) {
				v = v.trim()
				if (v.length == 0) return;
			}
			this.setState({filterAddedAgent: toFilterChunks(v)});
		},
		filterArea: function(e) {
			var v = e.target.value;
			if (v.length > 0) {
				v = v.trim()
				if (v.length == 0) return;
			}
			this.setState({filterArea: toFilterChunks(v)});
		},
		clearMutation: function(ack) {
			this.setState({
				mutate: {
					toRemoveMap: {},
					toAddMap: {},
				},
				createdChatSessionId: ack.sessionId,
			});
		},
		doAddAgent: function() {
			this.props.doAddAgent(this.state.alreadyAdded, this.state.mutate, this.clearMutation);
		},
	});

	var bareListStyle = {
		listStyleType:'none',
		padding: 0,
		marginLeft: 10,
		marginRight: 10,
		textAlign: 'left',
	};
	function getAgent(agentId) {
		return agentStore[agentId];
	}
	// agetNameAndChatName is used only for when it is to be viewed by an
	// agent. Never use it in the context where it will be sent to the
	// client. For that case use agentChatName instead.
	function agentNameAndChatName(agent) {
		if (agent.chatName && agent.chatName != agent.userName) {
			return agent.userName + ' (' +agent.chatName + ')';
		}
		return agent.userName;
	}
	// We use agentChatName when adding agent names in text that a client
	// may see.
	function agentChatName(agent) {
		if (agent.chatName) {
			return agent.chatName;
		}
		return agent.userName;
	}

	var AddAgent = createReactClass({
		getInitialState: function() {
			return {
				error: null,
				list:[],
			};
		},
		render: function() {
			if (!this.props.show) {
				return null;
			}
			if (this.state.error) {
				var msg;
				switch (this.state.error) {
					case 'ADD_AGENT_ERROR':
						msg = 'An error occurred while adding the agents to the session '
							+ this.props.sessionId;
					default:
						msg = error;
				}
				return <span style={{color:'red'}}>{msg}</span>
			}

			return <AgentList
				id={"agentlist_"+this.props.id}
				areas={this.props.list}
				agentIds={this.props.agentIds}
				ownerId={this.props.ownerId}
				agentPresence={this.props.agentPresence}
				doAddAgent={this.props.doAddAgent}
				toggleShowAddAgent={this.props.toggleShowAddAgent}
				context={this.props.context}
				error={this.props.error}
				show={this.props.tabVisible}
				tabVisible={this.props.tabVisible}
				dead={this.props.dead}
				connectStatus={this.props.connectStatus}
			/>
		}
	});

	var Spinner = createReactClass({
		render: function() {
			return <i className="fa fa-refresh fa-spin"></i>
		}
	});

	var Error = createReactClass({
		render: function() {
			if (!this.props.msg) {
				return null;
			}
			//return <span className={this.props.className+" error"}>{this.props.msg}</span>
			return <p className={this.props.className+" error"}>{this.props.msg}</p>
		}
	});
	var agentStore = {};
	var Participants = createReactClass({
		getInitialState: function() {
			return {
				agentsFetched: false,
				filterParticipants: [],
				notFound: {},
			};
		},
		isFetching: {},
		getAgents: function(missing) {
			var that = this
			, idlist = missing.join(',')
			;
			if (that.isFetching[idlist]) {
				return;
			}
			that.isFetching[idlist] = true;

			fetchAgentsAndStore({
				args: {
					sessionId: this.props.sessionId,
					agentIds: missing.join(",")
				},
				done: function(msg) {
					var notFound = {};
					$.each(missing, function(i, agentId) {
						if (!getAgent(agentId)) {
							socketMan.log("could not fetch agent ",agentId);
							notFound[agentId] = true;
						}
					});
					that.setState({
						notFound: notFound,
						agentsFetched: true
					});
					that.forceUpdate();
					that.isFetching[idlist] = false;
				},
				fail: function(e) {
					that.setState({error: getAjaxError(e)});
					that.isFetching[idlist] = false;
				}
			});
		},
		componentWillUpdate: function(nextProps, nextState) {
			var curr = this.props.agentIds
			, next = nextProps.agentIds
			, agentsChanged = false
			, i
			, that = this
			, nextD = {}
			, newMissing = []
			;

			// First we update the missing list - remove the stale
			// ones, otherwise we'd end up in an infinite loop
			// trying to fetch a non-existent participant.
			if (this.missing.length > 0) {
				next.map(function(id) { nextD[id] = true; });
				this.missing.map(function(id) {
					if (nextD[id]) {
						// okay
						newMissing.push(id);
					} else {
						// Don't fetch this agent - no longer in participants list.
					}
				});
				this.missing = newMissing;
			}

			// Next we see if the participants list has changed
			if (curr.length != next.length) {
				agentsChanged = true;
			} else {
				curr.sort();
				next.sort();
				for (var i=0;i<curr.length;i++) {
					if (curr[i] != next[i]) {
						agentsChanged = true;
						break;
					}
				}
			}



			// Update the missing list if participants list has changed
			if (agentsChanged) {
				this.missing = [];
				next.map(function(agentId) {
					if (!getAgent(agentId)) {
						if (that.state.notFound[agentId]) {
							// skip this agent - avoid infinite fetch attempt.
							// We tried fetching it earlier but failed so don't retry.
							socketMan.log("will not re-fetch agent", agentId);
						} else {
							that.missing.push(agentId);
						}
					}
				});
			}

			// Now fetch the missing participants info
			if (this.missing.length > 0) {
				this.getAgents(this.missing);
			}
		},
		agentSorter: function(a, b) {
			var aname
			, bname
			, aInvited = this.props.invited[a.id]
			, bInvited = this.props.invited[b.id]
			;

			if (aInvited && !bInvited) {
				return 1;
			}
			if (!aInvited && bInvited) {
				return -1;
			}

			aname = agentNameAndChatName(a);
			bname = agentNameAndChatName(b);

			if (aname < bname) {
				return -1;
			}
			if (aname > bname) {
				return 1;
			}
			return 0;
		},
		missing: [],
		render: function() {
			var props = this.props
			, that = this
			, agent
			, missing = []
			, isMissing = {}
			, agents = []
			// someMissing is true if we have tried fetching the
			// agents list but failed to retrieve some of them
			, someMissing
			, testScroll = urlParams['chat.testscroll']
			;

			if (this.state.error) {
				return <div>
					<Error msg={I("An error occurred while getting the agent list.")}/>
				</div>
			}

			if (props.agentIds.length <= 1) {
				//return null;
				return <div>
					<Error msg={I("No other agent is connected to this chat.")}/>
				</div>
			}

			$.each(props.agentIds, function(i, agentId) {
				agent = getAgent(agentId);
				if (!agent) {
					isMissing[agentId] = true;
				} else {
					agents.push(agent)
				}
			});

			someMissing = !$.isEmptyObject(this.state.notFound);
			for (var k in isMissing) {
				missing.push(parseInt(k, 10));
			}
			if (missing.length > 0 && !someMissing) {
				this.missing = missing;

				return <Spinner/>;
			} else {
				this.missing = [];
			}

			agents.sort(this.agentSorter);

			var agentList = [];
			var separatorShown = false;
			agents.map(function(agent) {
				if (!that.matchParticipants(agent)) {
					return null;
				}
				if (!separatorShown && props.invited[agent.id]) {
					agentList.push(<div key={props.sessionId+"_invite_separator"}>
						{I("Invited:")}
					</div>);
				}
				agentList.push(<div key={props.sessionId+"_participants_"+agent.id} className="list-group-item">
					<Agent
						context='participants'
						agentId={agent.id}
						ownerId={props.ownerId}
						invited={props.invited[agent.id]}
						agentPresence={props.agentPresence[agent.id]}
					/>
				</div>);
			})

			if (testScroll) for (var i=1; i<=30; i++) {
				agentList.push(<div key={props.sessionId+"_participants_phony_"+i} className="list-group-item">
					{"PhonyAgent-"+i}
				</div>)
			}

			var scrollableStyle = {
				// FIXME need to set this to 100%, not 200px, but relative to what?
				maxHeight: 200,
				overflow: 'auto',
			};

			return <div>
				<div className="list-group-item">
					{I("Agents")} <input type="text" onChange={this.filterParticipants} placeholder={I('agent name...')}/>
				</div>
				<div style={scrollableStyle}>
				{agentList}
				</div>
				<div>
					<Error msg={someMissing?I("Not all agents are shown (problem during fetching)"):""}/>
				</div>
			</div>
		},
		matchParticipants: function (agent) {
			return matchAny(this.state.filterParticipants, agentNameAndChatName(agent).toLowerCase());
		},
		filterParticipants: function(e) {
			var v = e.target.value;
			if (v.length > 0) {
				v = v.trim()
				if (v.length == 0) return;
			}
			this.setState({filterParticipants: toFilterChunks(v)});
		},
	});

	function fetchAgentsAndStore(opt) {
		var args = opt.args;
		$.ajax({
			url: "/socket/agent.api/agentlist",
			type: "POST",
			cache: false,
			data: args
		}).done(function(resp) {
			if (resp.list) {
				if (args.groupByArea || (!args.area && !args.sessionId)) {
					resp.list.map(function(area) {
						area.agents.map(function(agent) {
							agentStore[agent.id] = agent;
						});
					});
				} else {
					resp.list.map(function(agent) {
						agentStore[agent.id] = agent;
					});
				}
			}
			opt.done && opt.done(resp);
		}).fail(function(e) {
			socketMan.log("ajax error", e);
			opt.fail && opt.fail(e);
		});
	}

	function updateSubscription() {
		if (subscribeCount == 0 || subscribeCount == 1) {
			// console.debug("*** EMIT event subscribe", subscribeCount == 1);
			socketMan.subscribeAgentPresence({
				// sessionId is not really required for subscribing to agent
				// presence, give one anyway to prevent backend from
				// complaining that it's missing.
				sessionId: -99,
				subscribe: subscribeCount == 1
			});
		} else {
			// we have already subscribed, do nothing
		}
	}

	function subscribeAgentPresence() {
		subscribeCount++;
		updateSubscription();
	}

	function unsubscribeAgentPresence() {
		if (subscribeCount == 0) {
			return;
		}
		subscribeCount--;
		if (subscribeCount <= 0) {
			subscribeCount = 0;
			updateSubscription();
		}
	}

	function toggleAgentPresenceSubscription(subscribe, bywhom) {
		if (subscribe) {
			subscribeAgentPresence();
		} else {
			unsubscribeAgentPresence();
		}
	}

	function doSetAgent(sessionId, alreadyAdded, mutate, callback) {
		var finalist = {}
		, ids = []
		;

		$.each(alreadyAdded, function(id, agent) {
			finalist[id] = agent;
		});
		$.each(mutate.toRemoveMap, function(id, agent) {
			delete finalist[id];
		});
		$.each(mutate.toAddMap, function(id, agent) {
			finalist[id] = agent;
		});

		$.each(finalist, function(id, agent) {
			ids.push(finalist[id].id);
		});
		ids.sort();

		socketMan.addAgent({
			sessionId: sessionId,
			agentIds: ids
		}, callback);
	}

	var NEW_AGENT_CHAT = -42;

	var NewAgentToAgentChat = createReactClass({
		displayName: "NewAgentToAgentChat",
		getInitialState: function() {
			return {
				list: [], // Result of /agentlist's .list
				showAddAgent: false || this.props.noToggleLink,
				agentsFetched: false // TODO get rid of agentsFetched?
			};
		},
		doAddAgent: function(alreadyAdded, mutate, onsuccess) {
			var that = this
			, owner = getAgent(initialChatData.agentID)
			;

			if (Object.keys(mutate.toAddMap).length < 1) {
				that.setState({error:'ERR_NEED_AGENTS'});
				return;
			}

			if (!owner) {
				that.setState({error:'ADD_AGENT_ERROR'});
				socketMan.log("this should not happen: no owner?");
				return;
			}

			mutate.toAddMap[owner.id] = owner;
			doSetAgent(NEW_AGENT_CHAT, alreadyAdded, mutate, function(ack) {
				if (ack.error) {
					that.setState({error: ack.error});
					return;
				}

				onsuccess && onsuccess(ack);
				//switch (that.props.context) {
				//	case "frontPage":
				//		// TODO check added agent list
				//		// when add agent link is
				//		// clicked a few time after
				//		// adding new agent - ensure
				//		// the list of added agents are
				//		// consistent
				//		that.toggleSelectAgent();
				//		break;
				//	case "newChat":
				//		// do nothing
				//		break;
				//}
			});
		},
		toggleSelectAgent: function() {
			if (this.state.showAddAgent) {
				this.setState({
					showAddAgent: false,
					agentsFetched: false
				});
				unsubscribeAgentPresence();
			} else {
				this.setState({
					showAddAgent: true,
					agentsFetched: false
				});
				// subscribeAgentPresence();
				this.getAgents();
			}
		},
		componentWillUnmount: function() {
			unsubscribeAgentPresence();
		},
		componentWillUpdate: function(nextProps, nextState) {
			switch (this.props.context) {
				case "frontPage":
					if (!this.state.showAddAgent && nextState.showAddAgent) {
						subscribeAgentPresence();
					}
					break;
				case "newChat":
					if (!this.props.tabVisible && nextProps.tabVisible) {
						subscribeAgentPresence();
						this.getAgents();
					} else if (this.props.tabVisible && !nextProps.tabVisible) {
						unsubscribeAgentPresence();
					}
					break;
				default:
					// do nothing
			}
		},
		getAgents: function() {
			var that = this
			, state = this.state
			;
			fetchAgentsAndStore({
				args: {
					area: 0,
					groupByArea: true,
					includeOffline: true,
					sessionId: 0
				},
				done: function(msg) {
					that.setState({
						list: msg.list,
						error: msg.error,
						agentsFetched: true
					});
				},
				fail: function(e) {
					that.setState({error:'AJAX_ERROR', e: e});
				}
			});
		},
		render: function() {
			var emsg
			, content = []
			, error
			;
			if (this.state.error) {
				switch (this.state.error) {
					case 'ERR_NEED_AGENTS':
						emsg = I("Please select agents to start a new chat");
						break;
					default:
						socketMan.log("NewAgentToAgentChat: " + this.state.error);
						emsg = I("An error occurred while getting the agent list.");
				}
				this.state.error = null;
				error = emsg
			}
			if (this.props.context == "frontPage" && this.props.connectStatus == "connected") {
				content.push(<button key="clickHandler" className="btn btn-primary btn-sm" type="button" onClick={this.toggleSelectAgent}>{I("Chat with agent")}</button>);
			}
			if (this.state.showAddAgent) {
				content.push(<AddAgent
					key={"a2a-chat"}
					id={"a2a-chat"}
					list={this.state.list}
					show={true}
					sessionId={-1}
					agentIds={[initialChatData.agentID]}
					ownerId={initialChatData.agentID}
					agentPresence={this.props.agentPresence}
					doAddAgent={this.doAddAgent}
					toggleShowAddAgent={this.toggleSelectAgent}
					context={this.props.context}
					error={error}
					tabVisible={this.props.tabVisible}
					connectStatus={this.props.connectStatus}
				/>);
			}
			return <div>
				{content}
			</div>
		}
	});

	var TabContent = createReactClass({
		displayName: "TabContent",
		getInitialState: function() {
			return {
				list: [], // Result of /agentlist's .list
				showInfo:true,
				showAddAgent: false,
				showSideInfo: "show",
				showSideAgent: "hide"
			};
		},
		componentDidUpdate: function(){
		},
		showInformation: function(){
			this.setState({showInfo:!this.state.showInfo});
		},
		toggleShowAddAgent: function() {
			var sessionId = this.props.chatSession.sessionId;
			if (this.state.showAddAgent) {
				this.setState({showAddAgent: false});
				unsubscribeAgentPresence();
			} else {
				this.setState({showAddAgent: true});
				subscribeAgentPresence()
				this.getAgents();
			}
		},
		getAgents: function() {
			var that = this
			, state = this.state
			, areaId = 0
			;
			if (this.props.chatSession.area) {
				areaId = this.props.chatSession.area.id;
			}
			fetchAgentsAndStore({
				args: {
					area: areaId,
					groupByArea: true,
					includeOffline: true,
					sessionId: this.props.chatSession.sessionId
				},
				done: function(msg) {
					that.setState(msg);
				},
				fail: function(e) {
					that.setState({error: getAjaxError(e)});
				}
			});
		},
		doAddAgent: function(alreadyAdded, mutate) {
			var that = this;
			doSetAgent(this.props.chatSession.sessionId, alreadyAdded, mutate, function(ack) {
				if (ack.error) {
					that.setState({error:'ADD_AGENT_ERROR'});
				} else {
					that.toggleShowAddAgent();
				}
			});
		},
		render: function() {
			var chatTitle
			, title
			, addAgentButton
			, withClient
			;

			if (this.props.chatSession.errandId > 0) {
				withClient = true;
				title = I("Chat session with {CLIENT}")
					.replace("{CLIENT}", getClientEmailAddress(this.props.chatSession))
					;

				chatTitle = <span style={{'display':'inline-block', 'marginTop':'5px'}}>
					<i className="fa fa-user"></i> {title}
				</span>
			} else {
				withClient = false;
				title = I("Chat with agents");
				chatTitle = <span>
					{title}
				</span>
			}

			if (!withClient && this.props.feature['chat.group-chat-with-agent'] ||
				withClient && this.props.feature['chat.group-chat-with-client']) {
				if(this.props.chatSession.channel == Errand_SERVICE_CHAT) {
					addAgentButton = <button className="btn btn-primary btn-sm" type="button" onClick={this.toggleShowAddAgent} style={{'marginRight':'2px'}} disabled={this.props.chatSession.dead}>
						<i className="fa fa-user-plus" style={{'fontSize':'14px'}}></i>
					</button>
				}
			}

			return (
				<div role="tabpanel" className={this.props.active?"tab-pane active":"tab-pane"} id={"tab_"+this.props.chatSession.sessionId}>
					<div key="chatsession" className={(this.state.showInfo?"col-md-10":"col-md-12")+" chatBoardContainer"} style={{paddingLeft:'0px',paddingRight:'0px'}}>
						<div className="chatBoardIntroduction">
							{chatTitle}
							<div className="pull-right">
								{addAgentButton}
								<button className="btn btn-primary btn-sm" type="button" onClick={this.showInformation}>
									<i className={this.state.showInfo?"fa fa-chevron-right fa-lg":"fa fa-chevron-left fa-lg"} style={{cursor:'pointer'}}>
									</i>
								</button>
							</div>
							<div className="clear"></div>
						</div>
						<hr style={{marginTop:'0px',marginBottom:'0px'}}/>
						<AddAgent
							id={"addagent_"+this.props.chatSession.sessionId}
							error={this.state.error}
							list={this.state.list}
							show={this.state.showAddAgent}
							sessionId={this.props.chatSession.sessionId}
							agentIds={this.props.chatSession.agentIds ? this.props.chatSession.agentIds.concat(this.props.chatSession.invitedAgentIds) : []}
							ownerId={this.props.chatSession.ownerId}
							agentPresence={this.props.agentPresence}
							doAddAgent={this.doAddAgent}
							toggleShowAddAgent={this.toggleShowAddAgent}
							context={"existingChat"}
							dead={this.props.chatSession.dead}
							connectStatus={this.props.connectStatus}
						/>
						<MessageBoard
							key={"MessageBoard"+this.props.chatSession.sessionId}
							id={this.props.chatSession.sessionId}
							messages={this.props.chatSession.chat}
							client={this.props.chatSession.client}
							agent={this.props.chatSession.agent}
							translation={this.props.translation}
							active={this.props.active}
							/>
						{this.renderNotification()}
					</div>
					{this.renderInformationBox()}
				</div>
			);
		},
		toggleInfoContainer: function(){
			this.setState({showSideInfo: "show"});
			this.setState({showSideAgent: "hide"});
		},
		toggleParticipantsContainer: function(){
			this.setState({showSideInfo: "hide"});
			this.setState({showSideAgent: "show"});
		},
		shortenExternalData: function(data){
			var temp_div = document.createElement('div');
			temp_div.innerHTML = data;
			var links = temp_div.getElementsByTagName('a');
			for (var i=0;i<links.length;i++) {
				var link_text = links[i].innerHTML;
				if (link_text.length > 70) {
					links[i].innerHTML = link_text.substr(0,70) + '...';
				}
			}
			return temp_div.innerHTML;
		},
		renderInformationBox: function(){
			var clientInfo = []
			, withClient = !!this.props.chatSession.client
			, agentsButton
			, invited = {}
			, orgName = withClient ? shortenText(this.props.chatSession.area.organisation, 25) : ""
			, areaName = withClient ? shortenText(this.props.chatSession.area.name, 25) : ""
			, isChannelChat = this.props.chatSession.channel == Errand_SERVICE_CHAT
			;

			if(this.state.showInfo) {
				if (withClient) {
					clientInfo.push(<div key="i-errand-id" className="list-group-item"><i className="fa fa-file-text-o" title={I("Errand ID")}></i> {this.props.chatSession.errandId}</div>)
					clientInfo.push(<div key="i-name" className="list-group-item" href="#"><i className="fa fa-user fa-fw" title={I("Name")}></i>	{this.props.chatSession.client}</div>)
					clientInfo.push(<div key="i-email" className="list-group-item" href="#"><i className={"fa fa-"+(ToChannelName(this.props.chatSession.channel,"envelope"))+"-square fa-fw"} title={ToChannelName(this.props.chatSession.channel,I("Email address"))}></i>	{this.props.chatSession.clientEmail}</div>)
					clientInfo.push(<div key="i-org" className="list-group-item" title={this.props.chatSession.area.organisation} href="#"><i className="fa fa-group" title={I("Organization")}></i> {orgName}</div>)
					clientInfo.push(<div key="i-area" className="list-group-item" title={this.props.chatSession.area.name} href="#"><i className="fa fa-stack-overflow fa-fw"></i> {areaName}</div>)
					if(isChannelChat){
						clientInfo.push(<div key="i-ipaddr" className="list-group-item" href="#"><i className="fa fa-hdd-o fa-fw"></i>	{this.props.chatSession.ipaddress}
							<span className="pull-right text-muted small"><a className="underlink" onClick={this.props.onBlockIP}>{I("Block this IP")}</a></span>
						</div>)
					}
					clientInfo.push(<div key="i-status" className="list-group-item" href="#"><i className="fa fa-flag-o fa-fw" title={I("Status")}></i>	{this.props.chatSession.clientStatus}</div>)
				}

				if (!withClient && this.props.feature['chat.group-chat-with-agent'] ||
					withClient && this.props.feature['chat.group-chat-with-client']) {
					if(isChannelChat){
						agentsButton = <button className="btn btn-primary btn-sm" onClick={this.toggleParticipantsContainer}>
							<i className="fa fa-user"><span style={{'fontWeight':'bold', 'marginLeft':'2px'}}>
								{I('Agents ({NUM_AGENTS})').replace('{NUM_AGENTS}', this.props.chatSession.agentIds.length)}
							</span></i>
						</button>
					}
				}

				$.each(this.props.chatSession.invitedAgentIds, function(index, agentId) {
					invited[agentId] = true;
				});
				return(
					<div key="chatinfo" className="col-md-2 chatBoardContainer" style={{paddingLeft:'0px',paddingRight:'0px',overflowY:'scroll'}}>
						<div className="chatBoardIntroduction text-center" style={{'background':'#337AB7', 'color':'#FFF'}}>
							<button className="btn btn-primary btn-sm" onClick={this.toggleInfoContainer}>
								<i className="fa fa-user"><span style={{'fontWeight':'bold', 'marginLeft':'2px'}}>{I("Information")}</span></i>
							</button>
							{agentsButton}
						</div>
						<div className={"infoContainer " + this.state.showSideInfo}>
							{clientInfo}
							<div className="list-group-item" href="#"><i className="fa fa-calendar fa-fw" title={I("Date")}></i> {this.props.chatSession.startedHuman}</div>
							{this.renderExternalData(this.props.chatSession.externalData)}
						</div>
						<div className={"participantsContainer " + this.state.showSideAgent}>
							<Participants
								sessionId={this.props.chatSession.sessionId}
								ownerId={this.props.chatSession.ownerId}
								agentIds={this.props.chatSession.agentIds.concat(this.props.chatSession.invitedAgentIds)}
								invited={invited}
								agentPresence={this.props.agentPresence} />
						</div>
					</div>
				);
			}
		},
		renderExternalData: function(externalData){
			//externalData = "<a>Short extended information.</a> <a>This is a very long extended information that has to be trimmed for display.</a>";
			var externalDataHTML;
			if(externalData && externalData != ""){
				externalDataHTML = this.shortenExternalData(externalData);
			}
			if(externalDataHTML){
				return (
					<div className="list-group-item">
						<h5 className="list-group-item-heading">{I("Extended Information")}</h5>
						<p dangerouslySetInnerHTML={{__html: ErrandHelper.sanitizeHtml(externalDataHTML)}}></p>
					</div>
				);
			}
		},
		renderNotification: function(){
			if(this.props.chatNotification.text != ""){
				return (
					<div className="alert alert-danger chatNotification">
						<strong>{this.props.chatNotification.header}</strong> {this.props.chatNotification.text}
					</div>
				);
			}
		}
	});
	var ChatBox = createReactClass({
		displayName: "ChatBox",
		getInitialState: function() {
			return {chatStore:ChatStore.getState()};
		},
		componentWillMount: function(){
			ChatStore.listen(this.onChange);
			if(typeof initialChatData.hideGlobalChat !== 'undefined' &&
				typeof initialChatData.externalChat !== 'undefined'){
				if(initialChatData.hideGlobalChat == true){
					if(initialChatData.externalChat.type != "normal"){
						socketMan.startUpdate();
					}
				} else {
					socketMan.startUpdate();
				}
			} else {
				socketMan.startUpdate();
			}
		},
		componentDidMount: function() {
			soundManager.setup({debugMode: false});
		},
		componentWillUnmount: function() {
			ChatStore.unlisten(this.onChange);
		},
		onChange: function(state) {
			this.setState({chatStore:state});
		},
		render: function(){
			return (
				<div className="chatWrapper">
					<div id="chat_action_modal_container"></div>
					{this.renderChatTab()}
					<div id="ChatActionsTagging"></div>
				</div>
			);
		},
		checkCanClose: function() {
			if((typeof urlParams.type !== 'undefined') &&
				((urlParams.type == "puzzel") ||
				(urlParams.type == "solidus") ||
				(urlParams.type == "clearinteract"))){
				if(this.state.chatStore  &&
					Object.keys(this.state.chatStore.chatSessions).length > 0){
					return;
				}
				var that = this;
				$.ajax({
					url: "/socket/agent.api/canClose",
					data: {
						"type": urlParams.type,
					},
					type: "GET"
				}).done(function(msg) {
					console.log(msg);
					if (msg.canClose == true){
						window.open('', '_self', '');
						top.window.close();
					} else {
						setTimeout(that.checkCanClose, 2000);
					}
				}).fail(function(e) {
					setTimeout(that.checkCanClose,2000);
				});
			}
		},
		renderChatTab: function(){
			if(Object.keys(this.state.chatStore.chatSessions).length > 0){
				var okToLoad = true;
				$.each(this.state.chatStore.chatSessions, function(index, chatSession) {
					if(!chatSession.session) {
						okToLoad = false;
					}
				});
				if(okToLoad){
					return (<ChatTab
						agentPresence={this.state.chatStore.agentPresence}
						chatSessions={this.state.chatStore.chatSessions}
						connectStatus={this.state.chatStore.connectStatus}
						acceptChat={this.state.chatStore.acceptChat}
						feature={this.state.chatStore.feature}
					/>);
				}
			} else {
				if (this.state.chatStore.connectStatus == "connected") {
					setTimeout(this.checkCanClose, 5000);
				}
            }
			return (
				<div className="col-lg-12" style={{textAlign:'center', 'marginTop':'20px'}}>
					<p>
						<i className="fa fa-refresh fa-spin fa-3x fa-fw"></i>
					</p>
					<p>
						<span>{this.state.chatStore.connectStatus == "connected" ? I("waiting for chat in queue") : I("connecting...")}</span>
					</p>
					{this.state.chatStore.feature['chat.group-chat-with-agent']
					?  <NewAgentToAgentChat
						context="frontPage"
						connectStatus={this.state.chatStore.connectStatus}
						agentPresence={this.state.chatStore.agentPresence}
					/>
					: null
					}
				</div>
			);
		}
	});
export default ChatBox;
