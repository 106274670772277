import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import update from 'react-addons-update';
//dev component
import Textfield from '../../../components/CentionTextfield';
import TableComponent from '../../../components/TableComponent';
import ColorPicker from '../../../reactcomponents/ColorPicker';
import DropDown from '../../../components/DropDown';
import adminHelper from '../../../components/adminHelper';
//FLUX actions and store
import AdminAction from '../../../flux/actions/adminActions';
import AdminStore from '../../../flux/stores/adminStore';
import { SaveButton, CancelButton } from '../../../reactcomponents/Admin';
import { TableIconicButton, TextInputRow } from '../../../reactcomponents/Form';
import { I, webRoot, pathPrefix } from '../../../common/v5/config';
import SwitchCheckbox from '../../../reactcomponents/SwitchCheckbox';

global.defaultPaginationSize = 13, global.AREAROUTING = 9, global.AREAREPLACEMENT = 10, global.AREAAUTOFORWARD = 11, global.AREASMS = 12,
    global.AREAFORWARDEXTERNAL = 13;
var AreaAutoFwdExtAddr = createReactClass({
    getInitialState: function () {
        return {
            selectedId: 0,
            name: "",
            fwdTo: "",
            toFwd: "",
            errorMsg: "",
            addrList: (AdminStore.getState().areaAutoFwdExtAddrList.list != undefined ? AdminStore.getState().areaAutoFwdExtAddrList.list : [])
        }
    },
    componentDidMount: function () {
        AdminAction.getAreaAutoFwdExtAddr(this.props.areaId);
        AdminStore.listen(this.onFluxStoreChange);
    },
    componentWillUnmount: function () {
        AdminStore.unlisten(this.onFluxStoreChange);
    },
    onFluxStoreChange: function (state) {
        if (state.areaAutoFwdExtAddrList != null) {
            this.state.addrList = state.areaAutoFwdExtAddrList.list;
            this.state.name = state.areaAutoFwdExtAddrList.areaName;
            this.state.fwdTo = state.areaAutoFwdExtAddrList.fwdToEmailAddress;
            this.state.toFwd = ""
        }
        else
            this.state.addrList = [];
        this.setState(this.state);
    },
    handleItemDeleteAction: function (action) {
        $.ajax(action, {
            type: "DELETE"
        }).done(function (data) {
            AdminAction.getAreaAutoFwdExtAddr(this.props.areaId);
        }.bind(this));
    },
    cancelList: function () {
        location.href = window.location.protocol + "//" +
            window.location.hostname + pathPrefix + "admin#organisations";
    },
    handleToFwdChange: function (v) {
        this.setState({ toFwd: v });
        $(".with-errors-content").html("");
    },
    addAreaAutoFwdExtAddr: function () {
        if (this.state.toFwd == "") {
            this.setState({
                errorMsg:
                    I("* You can not leave this field empty.")
            });
            return;
        }
        AdminAction.saveAreaAutoFwdExtAddr(this.props.areaId, {
            fwdTo: this.state.fwdTo,
            matchingEmail: this.state.toFwd
        })
            .then(function (data) {
                this.setState({ toFwd: "" });
                AdminAction.getAreaAutoFwdExtAddr(this.props.areaId);
            }.bind(this));
    },
    changeAreaFwdToAddress: function (e) {
		this.setState({fwdTo: e.target.value});
    },
	handleAreaFwdToBlur: function (e) {
        AdminAction.saveAreaFwdToAddr(this.props.areaId, {
            email: this.state.fwdTo
        })
		.then(function (data) {
		}.bind(this));
	},
    render: function () {
        var RemoveButton = {
            action: webRoot + "areas/" + this.props.areaId +
                "/automaticforwardextaddress/{id}",
            name: I("Remove"),
            className: "fa fa-remove"
        };
        var forwardedField =
                <Textfield
                    id="toFwd"
                    value={this.state.toFwd}
                    name={I("toFwd")}
                    onChange={this.handleToFwdChange}
                />,
            removeField = {
                type: "orgEdit"
            };

        var column = [
            { header: I("Email address to be forwarded"), key: "email", subHeaderAction: forwardedField },
            { header: I("Action"), type: "actionCombineLink", data: RemoveButton, subHeaderAction: removeField, orgType: 'edit' }
        ];
        return (
            <div className="area-forward-address">
				<TextInputRow
					id="autofwdextaddr"
					name="autofwdextaddr"
					className="admin-text-input"
					label={I("Forward to address:")}
					value={this.state.fwdTo}
					onChange={this.changeAreaFwdToAddress}
					onBlur={this.handleAreaFwdToBlur}
				/>
                <div className="row">
                    <TableComponent
                        dataSource={this.state.addrList}
                        columns={column}
                        id={'AreaAutoFwdExtAddr'}
                        itemDeleteAction={this.handleItemDeleteAction}
                        paginate={true}
                        pageSize={defaultPaginationSize}
                        enablePageSizeChange={false}
                        enableSubHeaders={true}
                        organisationV5={true}
                        areaEditV5={true}
                        itemAddAction={this.addAreaAutoFwdExtAddr}
                    />
                </div>
            </div>
        );
    }

});

var AreaExtFwdAddr = createReactClass({
    getInitialState: function () {
        return {
            selectedId: 0,
            areaName: "",
            name: "",
            email: "",
            errorMsg: "",
            addrList: (AdminStore.getState().areaExtFwdAddrList.list != undefined ? AdminStore.getState().areaExtFwdAddrList.list : [])
        }
    },
    componentDidMount: function () {
        AdminAction.getAreaExtFwdAddr(this.props.areaId);
        AdminStore.listen(this.onFluxStoreChange);
    },
    componentWillUnmount: function () {
        AdminStore.unlisten(this.onFluxStoreChange);
    },
    onFluxStoreChange: function (state) {
        if (state.areaExtFwdAddrList != null) {
            this.state.addrList = state.areaExtFwdAddrList.list;
            this.state.areaName = state.areaExtFwdAddrList.areaName;
            this.state.name = ""
            this.state.email = ""
        }
        else
            this.state.addrList = [];
        this.setState(this.state);
    },
    handleItemDeleteAction: function (action) {
        $.ajax(action, {
            type: "DELETE"
        }).done(function (data) {
            AdminAction.getAreaExtFwdAddr(this.props.areaId);
        }.bind(this));
    },
    cancelList: function () {
        location.href = window.location.protocol + "//" +
            window.location.hostname + pathPrefix + "admin#organisations";
    },
    addAreaExtFwdAddr: function () {
        if (this.state.name == "") {
            this.setState({
                errorMsg:
                    I("* You cannot leave the Name field empty.")
            });
            return;
        }
        if (this.state.email == "") {
            this.setState({
                errorMsg:
                    I("* You cannot leave the Email field empty.")
            });
            return;
        } else if (!adminHelper.validateEmail(this.state.email)) {
            this.setState({
                errorMsg:
                    I("* Invalid email address.")
            });
            return;
        }
        AdminAction.saveAreaExtFwdAddr(this.props.areaId, {
            name: this.state.name,
            email: this.state.email
        })
            .then(function (data) {
                AdminAction.getAreaExtFwdAddr(this.props.areaId);
            }.bind(this));
    },
    handleNameChange: function (v) {
        this.setState({ name: v, errorMsg: "" });
    },
    handleEmailChange: function (v) {
        this.setState({ email: v, errorMsg: "" });
    },
    render: function () {
        var RemoveButton = {
            action: webRoot + "areas/" + this.props.areaId +
                "/extfwdaddr/{id}",
            name: I("Remove"),
            className: "fa fa-remove"
        };
        var NameField =
            <Textfield
                id="nameAdd"
                value={this.state.name}
                name={I("Name")}
                onChange={this.handleNameChange}
            />,
            EmailField =
                <Textfield
                    id="emailAdd"
                    value={this.state.email}
                    name={I("email")}
                    onChange={this.handleEmailChange}
                />,
            RemoveField = {
                type: "orgEdit"
            };
        var column = [
            { header: I("Name"), key: "name", subHeaderAction: NameField },
            { header: I("Email"), key: "email", subHeaderAction: EmailField },
            { header: I("Action"), type: "actionCombineLink", data: RemoveButton, subHeaderAction: RemoveField, orgType: 'edit' }
        ];

        return (
            <div className="area-forward-external">
                <div className="row">
                    <TableComponent
                        dataSource={this.state.addrList}
                        columns={column}
                        id={'AreaExtFwdAddr'}
                        itemDeleteAction={this.handleItemDeleteAction}
                        paginate={true}
                        pageSize={defaultPaginationSize}
                        enablePageSizeChange={false}
                        enableSubHeaders={true}
                        organisationV5={true}
                        areaEditV5={true}
                        itemAddAction={this.addAreaExtFwdAddr}
                    />
                </div>
            </div>
        );
    }

});

var AreaKeywords = createReactClass({
    getInitialState: function () {
        return {
            selectedId: 0,
            name: "",
            type: "Subject or text",
            content: "",
            typeAuto: "Subject or text",
            autoTags: "",
            contentAuto: "",
            keywordsList: (AdminStore.getState().areaKeywordsList.list != undefined ? AdminStore.getState().areaKeywordsList.list : []),
            autoTagMap: (AdminStore.getState().autoTagsRulesMap != undefined ? AdminStore.getState().autoTagsRulesMap : {}),
            currentTagVIP: false,
            showColorPicker: false,
            selectedTagVIPColor: "#d0021b"
        }
    },
    componentDidMount: function () {
        AdminAction.getAreaKeywords(this.props.areaId);
        AdminAction.getAreaAutoTagRules(this.props.areaId);
        AdminStore.listen(this.onFluxStoreChange);
    },
    componentWillUnmount: function () {
        AdminStore.unlisten(this.onFluxStoreChange);
        sessionStorage.setItem('areaAutoTags', "");
    },
    onFluxStoreChange: function (state) {
        if (state.areaKeywordsList != null) {
            this.setState({
                keywordsList: state.areaKeywordsList.list,
                name: state.areaKeywordsList.areaName
            })
        }
        else
            this.setState({
                keywordsList: []
            })
        if (state.autoTagsRulesMap != null) {
            this.state.autoTagMap = state.autoTagsRulesMap;
        }
        else
            this.state.autoTagMap = {}
        this.setState(this.state);
    },
    handleItemDeleteAction: function (action) {
        //delete row and also remove from area->routing rules array
        $.ajax(action, {
            type: "DELETE"
        }).done(function (data) {
            AdminAction.getAreaKeywords(this.props.areaId);
        }.bind(this));
    },
    handleTagDeleteAction: function (action) {
        $.ajax(action, {
            type: "DELETE"
        }).done(function (data) {
            AdminAction.getAreaAutoTagRules(this.props.areaId);
        }.bind(this));
    },
    cancelList: function () {
        location.href = window.location.protocol + "//" +
            window.location.hostname + pathPrefix + "admin#organisations";
    },
    addAreaRoutingRule: function () {
        if (this.state.content.length == 0) {
            return;
        }
        AdminAction.saveAreaKeywords(this.props.areaId, {
            //NO need for selected Id we will always create new
            id: this.state.selectedId,
            type: this.state.type,
            content: this.state.content
        })
            .then(function (data) {
                this.setState({ content: "" });
                AdminAction.getAreaKeywords(this.props.areaId);
            }.bind(this));
    },
    handleUpdateType: function (v) {
        this.setState({ type: v });
    },
    handleUpdateAutoType: function (v) {
        this.setState({ typeAuto: v });
    },
    checkVIPTag(tag) {
        var autoTags = this.autoTagsMapToArray();
        if (autoTags && autoTags.length > 0) {
            for (var i = 0; i < autoTags.length; i++) {
                var item = autoTags[i];
                if (item.tagId == tag) {
                    if (item.isVIP) {
                        return true;
                    }
                    return false;
                }
            }
        }
        return false;
    },
    getDefaultTagColor: function (tag) {
        const defaultTagColor = "#d0021b"; //default red
        var autoTags = this.autoTagsMapToArray();
        if (autoTags && autoTags.length > 0) {
            for (var i = 0; i < autoTags.length; i++) {
                var item = autoTags[i];
                if (item.tagId == tag) {
                    if (item.isVIP && item.tagColor !== "") {
                        return item.tagColor;
                    }
                    return defaultTagColor;
                }
            }
        }
        return defaultTagColor;
    },
    handleUpdateAutoTags: function (v) {
        var isVIP = this.checkVIPTag(v);
        var defaultTagColor = this.getDefaultTagColor(v);
        this.setState({ autoTags: v, currentTagVIP: isVIP, selectedTagVIPColor: defaultTagColor });
    },
    toggleColorPicker: function () {
        this.setState({ showColorPicker: !this.state.showColorPicker })
    },
    handleChangeFlagColor: function (c) {
        this.setState({ selectedTagVIPColor: c });
    },
    addAutoTagRule: function () {
        let autoTag = JSON.parse(sessionStorage.getItem("areaAutoTags"));
        let autoTagId = "";
        if (this.state.contentAuto.length == 0) {
            return;
        }
        if (autoTag != null) {
            autoTag.forEach(function (item) {
                if (item.tagId == this.state.autoTags) {
                    autoTagId = item.tagId;
                }
            }.bind(this));
        }
        if (autoTagId.length > 0) {
            let typeAuto = this.state.typeAuto;
            if (typeAuto.length == 0) {
                typeAuto = "Subject or text";
            }
            AdminAction.saveAreaAutoTagRules(this.props.areaId, {
                tagLevel: autoTagId,
                type: this.state.typeAuto,
                content: this.state.contentAuto,
                tagVIPColor: this.state.selectedTagVIPColor
            })
                .then(function (data) {
                    this.setState({ contentAuto: "", autoTags: "" });
                    AdminAction.getAreaAutoTagRules(this.props.areaId);
                }.bind(this));
        }
    },
    parseAutoTags: function (tagNames, tags, parentStr, parentId) {
        for (var i = 0; i < tags.length; i++) {
            var tag = tags[i];
            var newTagId = '' + tag.id;
            if (parentId.length > 0) {
                newTagId = parentId + "," + tag.id;
            }
            if (tag.children != null) {
                this.parseAutoTags(tagNames, tag.children, parentStr + tag.value +
                    "/", newTagId)
            } else {
                tagNames[newTagId] = { "name": (parentStr + tag.value), isVIP: tag.isVIP, tagColor: tag.tagColor };
            }
        }
    },
    autoTagsMapToArray: function () {
        let arr = [];
        let autoTags = [];
        let tagNames = {};
        if (typeof this.state.autoTagMap["areaTags"] !== 'undefined' &&
            this.state.autoTagMap["areaTags"][this.props.areaId] != null) {
            autoTags = this.state.autoTagMap["areaTags"][this.props.areaId]["normal_tags"];
            this.parseAutoTags(tagNames, autoTags, "", "");
        }
        for (var key in tagNames) {
            if (tagNames.hasOwnProperty(key)) {
                arr.push({ id: tagNames[key].name, tagId: key, value: tagNames[key].name, isVIP: tagNames[key].isVIP, tagColor: tagNames[key].tagColor });
            }
        }
        return arr;
    },
    handleContentAutoTextChange: function (text) {
        this.setState({ contentAuto: text });
        $(".with-errors-content-auto").html("");
    },
    handleContentAdd: function (text) {
        this.setState({ content: text });
        $(".with-errors-content").html("");
    },
    render: function () {
        var typeItems = [
            { id: "Subject or text", type: "Subject or text" },
            { id: "Subject", type: "Subject" },
            { id: "From", type: "From" },
            { id: "To", type: "To" }
        ];
        var autoTagList = this.state.autoTagMap["list"];
        let autoTags = this.autoTagsMapToArray();
        sessionStorage.setItem('areaAutoTags', JSON.stringify(autoTags));
        var tagDropDownField = <DropDown
            items={typeItems}
            fields={{ id: "id", name: "type" }}
            multiSelect={false}
            wantSelectAll={false}
            selectedItems={this.state.type}
            onChange={this.handleUpdateType}
        />

        var tagTextField = <Textfield
            id="contentAdd"
            value={this.state.content}
            name={I("Content")}
            onChange={this.handleContentAdd}
        />;

        var autoTagDropDownType = <DropDown
            items={typeItems}
            fields={{ id: "id", name: "type" }}
            multiSelect={false}
            wantSelectAll={false}
            selectedItems={this.state.typeAuto}
            onChange={this.handleUpdateAutoType}
        />;

        var autoTagTextField = <Textfield
            id="contentAdd"
            value={this.state.contentAuto}
            name={I("ContentAuto")}
            onChange={this.handleContentAutoTextChange}
        />;

        var autoTagColorField = this.state.currentTagVIP ? <ColorPicker id="areaRouteColorPicker"
            onChangeColor={this.handleChangeFlagColor}
            selected={this.state.selectedTagVIPColor} /> : <span />;

        var autoTagDropDownFieldTag = <DropDown
            class="autotag-v5"
            items={autoTags}
            fields={{ id: "tagId", name: "value" }}
            multiSelect={false}
            wantSelectAll={false}
            selectedItems={this.state.autoTags}
            onChange={this.handleUpdateAutoTags}
        />


        var addButton = {
            type: "orgEdit"
        };

        var RemoveButton = {
            action: webRoot + "areas/" + this.props.areaId +
                "/keywords/{id}",
            name: I("Remove"),
            className: "fa fa-remove"
        };

        var RemoveAutoTagButton = {
            action: webRoot + "areas/" + this.props.areaId +
                "/autotagrules/{id}",
            name: I("Remove"),
            className: "fa fa-remove"
        };
        var column = [
            { header: I("Type"), key: "type", subHeaderAction: tagDropDownField },
            { header: I("Content"), key: "content", subHeaderAction: tagTextField },
            { header: I("Action"), type: "actionCombineLink", data: RemoveButton, subHeaderAction: addButton, orgType: 'edit' }
        ];
        var autotagColumn = [
            { header: I("Type"), key: "type", subHeaderAction: autoTagDropDownType },
            { header: I("Content"), key: "content", subHeaderAction: autoTagTextField },
            { header: I("Tag"), key: "tag", subHeaderAction: autoTagDropDownFieldTag },
            { header: I("Color"), key: "tagColor", type: "withColor", subHeaderAction: autoTagColorField },
            { header: "", type: "actionCombineLink", data: RemoveAutoTagButton, subHeaderAction: addButton, orgType: 'edit' }
        ];
        return (
            <div className="area-routing">
                <div className="row">
                    <TableComponent
                        dataSource={this.state.keywordsList}
                        columns={column}
                        id={'areakeywordstable'}
                        itemDeleteAction={this.handleItemDeleteAction}
                        paginate={true}
                        pageSize={defaultPaginationSize}
                        enablePageSizeChange={false}
                        enableSubHeaders={true}
                        organisationV5={true}
                        areaEditV5={true}
                        itemAddAction={this.addAreaRoutingRule}
                    />
                </div>
                {this.props.autotag && <div className="autoTag-table">
                    <div className="row">
                        <div className="col-lg-12">
                            <h6 className="page-header">
                                {I('Automatic Tagging Rules (tag errands routed to this area)')}
                            </h6>
                        </div>
                    </div>
                    <div className="row">
                        <TableComponent
                            dataSource={autoTagList}
                            columns={autotagColumn}
                            id={'areaautokeywordstable'}
                            itemDeleteAction={this.handleTagDeleteAction}
                            paginate={true}
                            pageSize={defaultPaginationSize}
                            enablePageSizeChange={false}
                            enableSubHeaders={true}
                            organisationV5={true}
                            areaEditV5={true}
                            itemAddAction={this.addAutoTagRule}
                        />
                    </div>
                </div>}
            </div>
        );
    }
});

var AreaReplacement = createReactClass({
    getInitialState: function () {
        return {
            selectedId: 0,
            areaName: "",
            action: "Remove",
            removeType: "0",
            search: "",
            perform: "",
            errorMsg: "",
            replacementList: (AdminStore.getState().areaReplacementList.list != undefined ? AdminStore.getState().areaReplacementList.list : [])
        }
    },
    componentDidMount: function () {
        AdminAction.getAreaReplacement(this.props.areaId);
        AdminStore.listen(this.onFluxStoreChange);
    },
    componentWillUnmount: function () {
        AdminStore.unlisten(this.onFluxStoreChange);
    },
    removeTypeToString(i) {
        let retString = "" + i;
        switch (i) {
            case -1:
                retString = I("Remove matching string");
                break;
            case 0:
                retString = I("Remove same line");
                break;
            case 1:
                retString = I("Remove same line and one line below");
                break;
            case 2:
                retString = I("Remove same line and two lines below");
                break;
            case 3:
                retString = I("Remove same line and three lines below");
                break;
            case 4:
                retString = I("Remove same line and four lines below");
                break;
            case 5:
                retString = I("Remove same line and five lines below");
                break;
        }
        return retString;
    },
    alterListActionString: function (theList) {
        let newList = [];
        if (theList) {
            for (let i = 0; i < theList.length; i++) {
                let newPerform = theList[i].perform;
                if (theList[i].action == "Replace") {
                    newPerform =
                        I("Replace with {0}").replace('{0}', theList[i].perform);
                } else {
                    newPerform = this.removeTypeToString(theList[i].removeType);
                }
                let arrayItem = {
                    action: theList[i].action, id: theList[i].id,
                    perform: newPerform, search: theList[i].search
                };
                newList.push(arrayItem);
            }
        }
        return newList;
    },
    onFluxStoreChange: function (state) {
        if (state.areaReplacementList != null) {
            let newList = this.alterListActionString(
                state.areaReplacementList.list);
            this.state.replacementList = newList;
            this.state.areaName = state.areaReplacementList.areaName;
            this.state.action = "Remove"
            this.state.removeType = "0"
            this.state.search = ""
            this.state.perform = ""
        }
        else
            this.state.replacementList = [];
        this.setState(this.state);
    },
    handleItemDeleteAction: function (action) {
        $.ajax(action, {
            type: "DELETE"
        }).done(function (data) {
            AdminAction.getAreaReplacement(this.props.areaId);
        }.bind(this));
    },
    cancelList: function () {
        location.href = window.location.protocol + "//" +
            window.location.hostname + pathPrefix + "admin#organisations";
    },
    addAreaReplacement: function () {
        if (this.state.search == "") {
            this.setState({
                errorMsg:
                    I("* You cannot leave the Search for field empty.")
            });
            return;
        }
        if (this.state.action == "Replace" && this.state.perform == "") {
            this.setState({
                errorMsg:
                    I("* You cannot leave the Replace with field empty.")
            });
            return;
        }
        AdminAction.saveAreaReplacement(this.props.areaId, {
            action: this.state.action,
            removeType: this.state.removeType,
            search: this.state.search,
            perform: this.state.perform
        })
            .then(function (data) {
                this.setState({ search: "", perform: "" });
                AdminAction.getAreaReplacement(this.props.areaId);
            }.bind(this));
    },
    handleDropDown: function (v) {
        this.state.action = v;
        this.setState(this.state);
    },
    handleRemoveDropDown: function (v) {
        this.state.removeType = v;
        this.setState(this.state);
    },
    handleSearchForChange: function (text) {
        this.setState({ search: text });
        $(".with-errors-content").html("");
    },
    handleReplaceWithChange: function (text) {
        this.setState({ perform: text });
        $(".with-errors-content").html("");
    },
    render: function () {
        var typeItems = [
            { id: "Replace", type: "Replace" },
            { id: "Remove", type: "Remove" }
        ];
        var removeTypes = [
            { id: "-1", type: I("Remove matching string") },
            { id: "0", type: I("Remove same line") },
            { id: "1", type: I("Remove same line and one line below") },
            { id: "2", type: I("Remove same line and two lines below") },
            { id: "3", type: I("Remove same line and three lines below") },
            { id: "4", type: I("Remove same line and four lines below") },
            { id: "5", type: I("Remove same line and five lines below") }
        ];
        let isReplace = (this.state.action == "Replace") ? true : false;
        var RemoveButton = {
            action: webRoot + "areas/" + this.props.areaId +
                "/replacement/{id}",
            name: I("Remove"),
            className: "fa fa-remove"
        };
        var ActionField =
            <DropDown
                items={typeItems}
                fields={{ id: "id", name: "type" }}
                multiSelect={false}
                wantSelectAll={false}
                selectedItems={this.state.action}
                onChange={this.handleDropDown}
            />,
            SearchField =
                <Textfield
                    id="search"
                    value={this.state.search}
                    name={I("search")}
                    onChange={this.handleSearchForChange}
                />,
            PerformField = isReplace ?
                <Textfield
                    id="perform"
                    value={this.state.perform}
                    name={I("perform")}
                    onChange={this.handleReplaceWithChange}
                /> :
                <DropDown
                    items={removeTypes}
                    fields={{ id: "id", name: "type" }}
                    multiSelect={false}
                    wantSelectAll={false}
                    selectedItems={this.state.removeType}
                    onChange={this.handleRemoveDropDown}
                />,
            RemoveField = {
                type: "orgEdit"
            };
        var column = [
            { header: I("Actions"), key: "action", subHeaderAction: ActionField },
            { header: I("Search"), key: "search", subHeaderAction: SearchField },
            { header: I("Perform"), key: "perform", subHeaderAction: PerformField },
            { header: I("Action"), type: "actionCombineLink", data: RemoveButton, subHeaderAction: RemoveField, orgType: 'edit' }
        ];

        return (
            <div className="area-replacement">
                <div className="row">
                    <TableComponent
                        dataSource={this.state.replacementList}
                        columns={column}
                        id={'AreaReplacement'}
                        itemDeleteAction={this.handleItemDeleteAction}
                        paginate={true}
                        pageSize={defaultPaginationSize}
                        enablePageSizeChange={false}
                        enableSubHeaders={true}
                        organisationV5={true}
                        areaEditV5={true}
                        itemAddAction={this.addAreaReplacement}
                    />
                </div>
            </div>
        );
    }

});

var SmppAccounts = createReactClass({
    getInitialState: function () {
        return {
            showEdit: false,
            name: "",
            pageLoaded: false,
            smppAccountId: 0,
            areaSmppAccountSrc: {},
            smppAreaAccountsList: (AdminStore.getState().smppAreaAccountsList.smppAccounts != undefined ? AdminStore.getState().smppAreaAccountsList.smppAccounts : []),
        }
    },
    componentDidMount: function () {
        AdminStore.listen(this.onFluxStoreChange);
        AdminAction.getAreaSmppAccounts(this.props.areaId);
    },
    componentWillUnmount: function () {
        AdminStore.unlisten(this.onFluxStoreChange);
    },
    onFluxStoreChange: function (state) {
        if (state.smppAreaAccountsList != null) {
            this.state.smppAreaAccountsList = state.smppAreaAccountsList.list;
            this.state.name = state.smppAreaAccountsList.areaName;
        }
        else
            this.state.smppAreaAccountsList = [];
        if (typeof state.smppAreaAccountSrc !== "undefined") {
            this.state.areaSmppAccountSrc = state.smppAreaAccountSrc;
        }
        else {
            this.state.areaSmppAccountSrc = {
                name: "", msisdn: "", enabled: true,
                phonePrefix: "", msisdnMask: ""
            };
        }
        this.setState(this.state);
    },
    createNewTemplate: function () {
        this.loadAreaAccountEditComponent(0);
    },
    loadAreaAccountEditComponent: function (id) {
        this.setState({
            showEdit: true, smppAccountId: id,
            areaSmppAccountSrc: {}
        });
        AdminAction.getAreaSmppAccountById(this.props.areaId, id);
    },
    renderAreaSmppAccountForm: function (id) {
        if (!this.state.showEdit) {
            return null;
        }
        var modal = document.getElementById("smpp-edit-modal");
        var innerModal = document.getElementById("areaSmppAccountEditForm");
        var modalWidth = document.querySelector('#area-edit-v5').clientWidth;

        modal.style.display = "block";
        innerModal.style.maxWidth = "" + modalWidth + "px";
        var pageTitle = I("Create smpp account");
        if (id != 0) { pageTitle = I("Edit smpp account"); }
        return <AreaSmppAccountForm id={id} areaId={this.props.areaId}
            src={this.state.areaSmppAccountSrc}
            onNameChange={this.onNameChange}
            onMsisdnChange={this.onMsisdnChange}
            onPhonePrefixChange={this.onPhonePrefixChange}
            onMsisdnMaskChange={this.onMsisdnMaskChange}
            onEnabledChange={this.onEnabledChange}
            onSave={this.handleEditSave}
            pageTitle={pageTitle} onHide={this.handleCloseEdit} />;

    },
    rowClickHandle: function (id) {
        this.loadAreaAccountEditComponent(id);
    },
    handleItemDeleteAction: function (action) {
        $.ajax(action, {
            type: "DELETE"
        }).done(function (data) {
            AdminAction.getAreaSmppAccounts(this.props.areaId);
        }.bind(this));
    },
    cancelList: function () {
        this.setState({ showEdit: false });
        location.href = window.location.protocol + "//" +
            window.location.hostname + pathPrefix + "admin#organisations";
    },
    handleCloseEdit: function () {
        var modal = document.getElementById("smpp-edit-modal");
        modal.style.display = "none";
        this.setState({ showEdit: false });
    },
    handleEditSave: function () {
        if ((typeof this.state.areaSmppAccountSrc.name === "undefined") ||
            (this.state.areaSmppAccountSrc.name == "")) {
            $(".with-errors-name").html(I("* You cannot leave the field empty."));
            return
        }
        if ((typeof this.state.areaSmppAccountSrc.msisdn === "undefined") ||
            (this.state.areaSmppAccountSrc.msisdn == "")) {
            $(".with-errors-msisdn").html(I("* You cannot leave the field empty."));
            return
        } else {
            //var phoneExp = /^[- +()]*[0-9][- +()0-9]*/;
            /*
            var phoneMatch = phoneExp.exec(this.state.areaSmppAccountSrc.msisdn);
                if((phoneMatch == null) || (phoneMatch.length == 0)){
                    $(".with-errors-msisdn").html(I("* Must be in valid phone number format."));
                return
                }
                */
        }
        if (typeof this.state.areaSmppAccountSrc.phonePrefix === "undefined") {
            this.state.areaSmppAccountSrc.phonePrefix = "";
        }
        if (typeof this.state.areaSmppAccountSrc.msisdnMask === "undefined") {
            this.state.areaSmppAccountSrc.msisdnMask = "";
        }
        AdminAction.saveAreaSmppAccount(this.props.areaId, {
            id: parseInt(this.state.smppAccountId),
            name: this.state.areaSmppAccountSrc.name.trim(),
            msisdn: this.state.areaSmppAccountSrc.msisdn.trim(),
            phonePrefix: this.state.areaSmppAccountSrc.phonePrefix.trim(),
            msisdnMask: this.state.areaSmppAccountSrc.msisdnMask.trim(),
            enabled: this.state.areaSmppAccountSrc.enabled
        })
            .then(function (data) {
                this.handleCloseEdit();
                AdminAction.getAreaSmppAccounts(this.props.areaId);
            }.bind(this));
    },
    onNameChange: function (v) {
        this.setState({
            areaSmppAccountSrc: update(this.state.areaSmppAccountSrc,
                { $merge: { name: v } })
        });
    },
    onMsisdnChange: function (v) {
        this.setState({
            areaSmppAccountSrc: update(this.state.areaSmppAccountSrc,
                { $merge: { msisdn: v } })
        });
    },
    onPhonePrefixChange: function (v) {
        this.setState({
            areaSmppAccountSrc: update(this.state.areaSmppAccountSrc,
                { $merge: { phonePrefix: v } })
        });
    },
    onMsisdnMaskChange: function (v) {
        this.setState({
            areaSmppAccountSrc: update(this.state.areaSmppAccountSrc,
                { $merge: { msisdnMask: v } })
        });
    },
    onEnabledChange: function (v) {
        this.setState({
            areaSmppAccountSrc: update(this.state.areaSmppAccountSrc,
                { $merge: { enabled: v } })
        });
    },
    render: function () {
        var RemoveButton = {
            action: webRoot + "areas/" + this.props.areaId +
                "/smppaccounts/{id}",
            name: I("Remove"),
            className: "fa fa-remove"
        };
        var column = [
            { header: I("Name"), key: "name" },
            { header: I("Action"), type: "actionCombineLink", data: RemoveButton, orgType: "area-smpp" }
        ];
        var activeButton = this.state.showEdit ? "active" : "";
        return (
            <div className="area-sms">
                <div className="row">
                    <div className="col-lg-12" style={{ textAlign: "right" }}>
                        <TableIconicButton
                            type='button'
                            className={'btn bg-transparent btn-round ' + activeButton}
                            iconClass='icon-add'
                            title={I('Create new')}
                            onClick={() => this.createNewTemplate()}
                        />
                    </div>
                </div>
                <div className="row">
                    <TableComponent
                        dataSource={this.state.smppAreaAccountsList}
                        columns={column}
                        id={'smppAreaAccountsTable'}
                        itemDeleteAction={this.handleItemDeleteAction}
                        paginate={true}
                        pageSize={defaultPaginationSize}
                        enablePageSizeChange={false}
                        highlightRow={this.rowClickHandle}
                        organisationV5={true}
                        onHandleEnableChange={this.onEnabledChange}
                        onEditActionRow={this.rowClickHandle}
                    />
                </div>
                <div id="smpp-edit-modal">
                    <div className="row">
                        <div className="col-lg-12" id="areaSmppAccountEditForm">
                            {this.renderAreaSmppAccountForm()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

var AreaSmppAccountForm = createReactClass({
    getInitialState: function () {
        return {
        }
    },
    handleNameChange: function (v) {
        this.props.onNameChange(v);
        $(".with-errors-name").html("");
    },
    handleMsisdnChange: function (v) {
        this.props.onMsisdnChange(v);
        $(".with-errors-msisdn").html("");
    },
    handlePhonePrefixChange: function (v) {
        this.props.onPhonePrefixChange(v);
    },
    handleMsisdnMaskChange: function (v) {
        this.props.onMsisdnMaskChange(v);
    },
    handleEnabledChange: function (v) {
        this.props.onEnabledChange(!this.props.src.enabled);
    },
    render: function () {
        var tempVar = this.props.src;
        if (Object.keys(tempVar) <= 0)
            return (<span>{I("Loading SMPP Account to add/edit")}</span>);
        return (
            <div className="panel panel-default">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-lg-6">
                            <label className="col-lg-12 control-label" htmlFor="basic"><span className="mandatory">* </span>{I('SMPP Account Name')}</label>
                            <div className="col-lg-12">
                                <Textfield
                                    id="name"
                                    setDefaultText={this.props.src.name}
                                    name={I("Name")}
                                    onChange={this.handleNameChange}
                                />
                                <div style={{ color: 'red' }} className="help-block with-errors-name"></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <label className="col-lg-12 control-label" htmlFor="basic">{I('Enabled')}</label>
                            <div className="col-lg-12">
                                {/* <input type="checkbox" id="enabledCheckbox" defaultChecked={this.props.src.enabled} onClick={this.handleEnabledChange} /> */}
                                <SwitchCheckbox
                                    active={this.props.src.enabled}
                                    buttonClassName={""}
                                    id={"orgActiveSwitch"}
                                    name={"orgActive"}
                                    type="checkbox"
                                    onClick={() => { this.handleEnabledChange() }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label className="col-lg-12 control-label" htmlFor="basic"><span className="mandatory">* </span>{I('MSISDN or Name')}</label>
                            <div className="col-lg-12">
                                <Textfield
                                    id="msisdn"
                                    setDefaultText={this.props.src.msisdn}
                                    name={I("MSISDN")}
                                    onChange={this.handleMsisdnChange}
                                />
                                <div style={{ color: 'red' }} className="help-block with-errors-msisdn"></div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <label className="col-lg-12 control-label" htmlFor="basic">{I('Phone Prefix')}</label>
                            <div className="col-lg-12">
                                <Textfield
                                    id="phonePrefix"
                                    setDefaultText={this.props.src.phonePrefix}
                                    name={I("Phone Prefix")}
                                    onChange={this.handlePhonePrefixChange}
                                />
                                <div style={{ color: 'red' }} className="help-block with-errors-system-type"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label className="col-lg-12 control-label" htmlFor="basic">{I('MSISDN Mask')}</label>
                            <div className="col-lg-12">
                                <Textfield
                                    id="msisdnMask"
                                    setDefaultText={this.props.src.msisdnMask}
                                    name={I("MSISDN Mask")}
                                    onChange={this.handleMsisdnMaskChange}
                                />
                                <div style={{ color: 'red' }} className="help-block with-errors-system-type"></div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group editPageActionButton">
                        <div className="row">
                            <div className="col-lg-12 textAlignRight">
                                <CancelButton onClick={this.props.onHide} />
                                <SaveButton
                                    key="btn-save-admin"
                                    onClick={this.props.onSave}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

});

export default class AreaTableEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            type: 1
        }
    }
    handleRenderSettings = (type, id) => {
        switch (type) {
            case AREAROUTING:
                return <AreaKeywords areaId={id} autotag={features["routing.autotag"]} />
            case AREAREPLACEMENT:
                return <AreaReplacement areaId={id} />
            case AREAAUTOFORWARD:
                return <AreaAutoFwdExtAddr areaId={id} />
            case AREASMS:
                return <SmppAccounts areaId={id} />
            case AREAFORWARDEXTERNAL:
                return <AreaExtFwdAddr areaId={id} />
            default:
                return null;
        }
    }
    render = () => {
        var type = this.props.type, id = this.props.id;
        var renderSetting = this.handleRenderSettings(type, id);
        return (<div className="col-lg-12 area-edit-settings">
            <div className="form-group-org">
                <div className="col-lg-12">
                    {renderSetting}
                </div>
            </div>
            <div className="form-group editPageActionButton">
                <div className="row">
                    <div className="col-lg-12 textAlignRight">
                        <CancelButton onClick={this.props.cancelToOrg} />
                        {/* <SaveButton
                            key="btn-save-admin"
                            onClick={this.props.saveOrg}
                        /> */}
                    </div>
                </div>
            </div>
        </div>);
    }
}
